import { ContractSelect } from "../contracts/ContractSelect";
import { CurrentDropDown } from "../../currency/CurrencyDropDown";
import { EditableDate } from "../../core/EditableDate";
import { EditableListItemText } from "../../core/EditableListItemText";
import { EditableSwitch } from "../../core/EditableSwitch";
import { EmptyDetailCard } from "../../core/EmptyDetailCard";
import { formatCurrency, useUserCurrencyFormatter } from "./currency-formatting";
import { RoyaltyTypeSelect } from "./RoyaltyTypeSelect";
import {
  useAllRoyalties,
  useDeleteRoyaltyEvent,
  useGame,
  useGameRoyalties,
  useUpdateRoyaltyEvent,
} from "@seabrookstudios/pitch2table";
import { useGameIdFromRoute, useRoyaltyIdFromRoute } from "../WithGameIdFromRoute";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import Stack from "@mui/material/Stack";
import { Path } from "../../Path";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { AlwaysOnAppLink } from "../../styleguide/AppLink";
import Skeleton from "@mui/material/Skeleton";
import { useYearFromParam } from "../../royalties/params";
import { PublisherSelect } from "./PublisherSelect";
import { useDocumentTitle } from "usehooks-ts";

export const RoyaltyFromRoute = () => {
  const gameId = useGameIdFromRoute();
  const royaltyId = useRoyaltyIdFromRoute();
  const { data: royalties = [] } = useGameRoyalties(gameId);
  const royalty = royalties.find((r) => r.id === royaltyId);
  const updateRoyaltyEvent = useUpdateRoyaltyEvent(gameId, royaltyId);
  const deleteRoyaltyEvent = useDeleteRoyaltyEvent(gameId, royaltyId);
  const formatUserCurrency = useUserCurrencyFormatter();
  const navigate = useNavigate();

  useDocumentTitle(royalty ? royalty.type : "Pitch2Table");

  if (!royalty) {
    return <EmptyDetailCard text="Select a royalty event from the list on the left." />;
  }

  /**
   * @param {number} date
   */
  const onUpdateDate = async (date) => {
    await updateRoyaltyEvent.mutateAsync({
      date,
      amount: undefined,
      type: undefined,
      paid: undefined,
      currency: undefined,
      contractId: undefined,
      publisherId: undefined,
    });
  };

  /**
   * @param {number} amount
   */
  const onUpdateAmount = async (amount) => {
    await updateRoyaltyEvent.mutateAsync({
      date: undefined,
      amount,
      type: undefined,
      paid: undefined,
      currency: undefined,
      contractId: undefined,
      publisherId: undefined,
    });
  };

  /**
   * @param {boolean} paid
   */
  const togglePaid = async (paid) => {
    await updateRoyaltyEvent.mutateAsync({
      date: undefined,
      amount: undefined,
      type: undefined,
      paid,
      currency: undefined,
      contractId: undefined,
      publisherId: undefined,
    });
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").Currency} currency
   */
  const onUpdateCurrency = async (currency) => {
    await updateRoyaltyEvent.mutateAsync({
      date: undefined,
      amount: undefined,
      type: undefined,
      paid: undefined,
      currency,
      contractId: undefined,
      publisherId: undefined,
    });
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").RoyaltyType} type
   */
  const onUpdateType = async (type) => {
    await updateRoyaltyEvent.mutateAsync({
      date: undefined,
      amount: undefined,
      type,
      paid: undefined,
      currency: undefined,
      contractId: undefined,
      publisherId: undefined,
    });
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").AttachmentId} contractId
   */
  const onUpdateContractId = async (contractId) => {
    await updateRoyaltyEvent.mutateAsync({
      date: undefined,
      amount: undefined,
      type: undefined,
      paid: undefined,
      currency: undefined,
      contractId,
      publisherId: undefined,
    });
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
   */
  const onUpdatePublisherId = async (publisherId) => {
    await updateRoyaltyEvent.mutateAsync({
      date: undefined,
      amount: undefined,
      type: undefined,
      paid: undefined,
      currency: undefined,
      contractId: undefined,
      publisherId,
    });
  };

  const deleteRoyalty = async () => {
    await deleteRoyaltyEvent.mutateAsync();
    navigate(Path.game(gameId).royalties());
  };

  return (
    <Card elevation={1} square={false}>
      <List>
        <Stack direction="row">
          <ListItem>
            <EditableListItemText
              primary={royalty.paid ? "Amount (payment currency)" : "Amount due"}
              secondary={royalty.amount}
              formatter={formatCurrency(royalty.currency)}
              onSave={onUpdateAmount}
              isBusy={updateRoyaltyEvent.isPending}
              helperText={royalty.paid ? "The amount of the payment." : "The expected amount of the payment."}
            />
          </ListItem>
          {royalty.paid && (
            <ListItem>
              <ListItemText primary="Amount (user currency)" secondary={formatUserCurrency(royalty.convertedAmount)} />
            </ListItem>
          )}
        </Stack>
        <ListItem>
          <EditableDate
            title={royalty.paid ? "Date paid" : "Due date"}
            timestamp={royalty.date}
            onSave={onUpdateDate}
            isBusy={updateRoyaltyEvent.isPending}
          />
        </ListItem>
        <ListItem>
          <CurrentDropDown
            value={royalty.currency}
            onChange={onUpdateCurrency}
            helperText="The currency of the payment was made in."
          />
        </ListItem>
        <ListItem>
          <RoyaltyTypeSelect
            value={royalty.type}
            onChange={onUpdateType}
            helperText="What kind of royalty payment is this e.g. Advance, Signing Bonus, Royalty"
          />
        </ListItem>
        <ListItem>
          <PublisherSelect
            gameId={royalty.gameId}
            value={royalty.publisherId}
            onChange={onUpdatePublisherId}
            helperText={<Typography variant="inherit">Which publisher did this payment come from?</Typography>}
          />
        </ListItem>
        <ListItem>
          <ContractSelect
            gameId={gameId}
            value={royalty.contractId}
            onChange={onUpdateContractId}
            helperText={
              <Typography variant="inherit">
                Which contract is this payment in relation to.{" "}
                <AlwaysOnAppLink to={Path.game(royalty.gameId).contracts()}>Upload contract</AlwaysOnAppLink>.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <EditableSwitch
            initialValue={royalty.paid}
            onLabel="Paid"
            offLabel="Not paid"
            title="Has you received this payment?"
            onUpdate={togglePaid}
          />
        </ListItem>
      </List>
      <CardActions>
        <Button onClick={deleteRoyalty}>DELETE</Button>
      </CardActions>
    </Card>
  );
};

export const RoyaltyFromRouteSansGame = () => {
  const year = useYearFromParam();
  const royaltyId = useRoyaltyIdFromRoute();
  const { data: royalties = [] } = useAllRoyalties();
  const royalty = royalties.find((r) => r.id === royaltyId);
  const updateRoyaltyEvent = useUpdateRoyaltyEvent(royalty ? royalty.gameId : undefined, royaltyId);
  const deleteRoyaltyEvent = useDeleteRoyaltyEvent(royalty ? royalty.gameId : undefined, royaltyId);
  const formatUserCurrency = useUserCurrencyFormatter();
  const { data: game } = useGame(royalty ? royalty.gameId : undefined);
  const navigate = useNavigate();

  useDocumentTitle(royalty ? royalty.type : "Pitch2Table");

  if (!royalty) {
    return <EmptyDetailCard text="Select a royalty event from the list on the left." />;
  }

  /**
   * @param {number} date
   */
  const onUpdateDate = async (date) => {
    await updateRoyaltyEvent.mutateAsync({
      date,
      amount: undefined,
      type: undefined,
      paid: undefined,
      currency: undefined,
      contractId: undefined,
      publisherId: undefined,
    });
  };

  /**
   * @param {number} amount
   */
  const onUpdateAmount = async (amount) => {
    await updateRoyaltyEvent.mutateAsync({
      date: undefined,
      amount,
      type: undefined,
      paid: undefined,
      currency: undefined,
      contractId: undefined,
      publisherId: undefined,
    });
  };

  /**
   * @param {boolean} paid
   */
  const togglePaid = async (paid) => {
    await updateRoyaltyEvent.mutateAsync({
      date: undefined,
      amount: undefined,
      type: undefined,
      paid,
      currency: undefined,
      contractId: undefined,
      publisherId: undefined,
    });
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").Currency} currency
   */
  const onUpdateCurrency = async (currency) => {
    await updateRoyaltyEvent.mutateAsync({
      date: undefined,
      amount: undefined,
      type: undefined,
      paid: undefined,
      currency,
      contractId: undefined,
      publisherId: undefined,
    });
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").RoyaltyType} type
   */
  const onUpdateType = async (type) => {
    await updateRoyaltyEvent.mutateAsync({
      date: undefined,
      amount: undefined,
      type,
      paid: undefined,
      currency: undefined,
      contractId: undefined,
      publisherId: undefined,
    });
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").AttachmentId} contractId
   */
  const onUpdateContractId = async (contractId) => {
    await updateRoyaltyEvent.mutateAsync({
      date: undefined,
      amount: undefined,
      type: undefined,
      paid: undefined,
      currency: undefined,
      contractId,
      publisherId: undefined,
    });
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
   */
  const onUpdatePublisherId = async (publisherId) => {
    await updateRoyaltyEvent.mutateAsync({
      date: undefined,
      amount: undefined,
      type: undefined,
      paid: undefined,
      currency: undefined,
      contractId: undefined,
      publisherId,
    });
  };

  const deleteRoyalty = async () => {
    await deleteRoyaltyEvent.mutateAsync();

    if (!year) {
      navigate(Path.royalties().category("by-year"));
      return;
    }

    navigate(Path.royalties().byYear(year).all());
  };

  return (
    <Card elevation={1} square={false}>
      <List>
        <ListItem>
          <ListItemText primary="Game" secondary={game ? game.name : <Skeleton />} />
        </ListItem>
        <Stack direction="row">
          <ListItem>
            <EditableListItemText
              primary={royalty.paid ? "Amount (payment currency)" : "Amount due"}
              secondary={royalty.amount}
              formatter={formatCurrency(royalty.currency)}
              onSave={onUpdateAmount}
              isBusy={updateRoyaltyEvent.isPending}
              helperText={royalty.paid ? "The amount of the payment." : "The expected amount of the payment."}
            />
          </ListItem>
          {royalty.paid && (
            <ListItem>
              <ListItemText primary="Amount (user currency)" secondary={formatUserCurrency(royalty.convertedAmount)} />
            </ListItem>
          )}
        </Stack>
        <ListItem>
          <EditableDate
            title={royalty.paid ? "Date paid" : "Due date"}
            timestamp={royalty.date}
            onSave={onUpdateDate}
            isBusy={updateRoyaltyEvent.isPending}
          />
        </ListItem>
        <ListItem>
          <CurrentDropDown
            value={royalty.currency}
            onChange={onUpdateCurrency}
            helperText="The currency of the payment was made in."
          />
        </ListItem>
        <ListItem>
          <RoyaltyTypeSelect
            value={royalty.type}
            onChange={onUpdateType}
            helperText="What kind of royalty payment is this e.g. Advance, Signing Bonus, Royalty"
          />
        </ListItem>
        <ListItem>
          <PublisherSelect
            gameId={royalty.gameId}
            value={royalty.publisherId}
            onChange={onUpdatePublisherId}
            helperText={<Typography variant="inherit">Which publisher did this payment come from?</Typography>}
          />
        </ListItem>
        <ListItem>
          <ContractSelect
            gameId={royalty.gameId}
            value={royalty.contractId}
            onChange={onUpdateContractId}
            helperText={
              <Typography variant="inherit">
                Which contract is this payment in relation to.{" "}
                <AlwaysOnAppLink to={Path.game(royalty.gameId).contracts()}>Upload contract</AlwaysOnAppLink>.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <EditableSwitch
            initialValue={royalty.paid}
            onLabel="Paid"
            offLabel="Not paid"
            title="Has you received this payment?"
            onUpdate={togglePaid}
          />
        </ListItem>
      </List>
      <CardActions>
        <Button onClick={deleteRoyalty}>DELETE</Button>
      </CardActions>
    </Card>
  );
};
