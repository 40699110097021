import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { EditTextFieldDialog } from "../core/EditTextFieldDialog";
import CardHeader from "@mui/material/CardHeader";

const EditableHeadingDisplay = ({ value }) => (
  <Typography pb={0.5} variant="h4" textAlign="left" color="text.secondary" noWrap>
    {value}
  </Typography>
);

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.text
 * @param {string} props.helperText
 * @param {boolean} [props.disabled]
 * @param {(text: string) => void} props.onSave
 */
export const EditableHeading = ({ title, text, helperText, onSave, disabled }) => {
  return (
    <Stack direction="row" display="flex" justifyContent="flex-start">
      {disabled ? (
        <EditableHeadingDisplay value={text} />
      ) : (
        <Tooltip title="Click to edit">
          <Box>
            <EditTextFieldDialog
              onSave={onSave}
              title={title}
              initialValue={text}
              isBusy={false}
              helperText={helperText}
            >
              <EditableHeadingDisplay value={text} />
            </EditTextFieldDialog>
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
};

const EditableCardHeaderDisplay = ({ value, subheader }) => <CardHeader title={value} subheader={subheader} />;

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.text
 * @param {string} props.subheader passed through
 * @param {string} props.helperText
 * @param {boolean} [props.disabled]
 * @param {(text: string) => void} props.onSave
 */
export const EditableCardHeader = ({ title, subheader, text, helperText, onSave, disabled }) => {
  return (
    <Stack direction="row" display="flex" justifyContent="flex-start">
      {disabled ? (
        <EditableCardHeaderDisplay value={text} subheader={subheader} />
      ) : (
        <Tooltip title="Click to edit">
          <Box>
            <EditTextFieldDialog
              onSave={onSave}
              title={title}
              initialValue={text}
              isBusy={false}
              helperText={helperText}
            >
              <EditableCardHeaderDisplay value={text} subheader={subheader} />
            </EditTextFieldDialog>
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
};
