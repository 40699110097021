import React from "react";
import { DateTime } from "luxon";
import { ListView } from "../core/ListView";
import Alert from "@mui/material/Alert";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { NoItemsFound } from "../publisher/NoItemsFound";
import AddRulesFullScreenDialog from "./AddRulesFullScreenDialog";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "../Path";
import { rulesVersion } from "../games/model";

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/pitch2table-core").Rules} props.rules
 * @param {string} props.version
 */
const RulesListEntry = ({ rules, version }) => {
  const params = useParams();
  const navigate = useNavigate();

  const selected = params.attachmentId === rules.id;

  const onClickHandler = () => {
    navigate(Path.game(rules.gameId).rule(rules.id));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemText
        primary={version}
        secondary={`Uploaded ${DateTime.fromMillis(rules.uploadedTimestamp).toFormat("h:mm a, DDD")}`}
      />
    </ListItemButton>
  );
};

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").Rules[]} props.rules
 * @param {import("react-router-dom").To} props.back
 * @param {() => React.JSX.Element} [props.Action]
 */
export const RulesList = ({ gameId, rules = [], back }) => {
  const Action = () => <AddRulesFullScreenDialog gameId={gameId} />;

  return (
    <ListView
      title="Rules"
      Filters={null}
      Action={Action}
      back={back}
      EmptyList={() => (
        <NoItemsFound>
          <Alert severity="info">
            Here you can upload your game's rules. As publishers review games at different speeds, your game and the
            rules may have evolved since then. In the publisher interest section, you can record what version of the
            rules you sent to each publisher.
          </Alert>
        </NoItemsFound>
      )}
      shown={rules.length}
      neverEmpty={false}
    >
      {rules.map((document, index) => (
        <RulesListEntry key={document.id.toString()} rules={document} version={rulesVersion(rules, index)} />
      ))}
    </ListView>
  );
};
