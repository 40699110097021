import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { fetchContact } from "../users/users-service";
import { DefaultStaleTime } from "../core/react-query";
import { DesignersByGameId } from "./query-keys";
import { EditableGameName } from "./EditableGameName";
import { EditableTag } from "./EditableTag";
import { PublisherLatestSizzle } from "../sizzle/LatestSizzle";
import { PublisherSellsheet } from "../sellsheets/PublicSellsheet";
import { PublisherPublicRules } from "../rules/PublicRules";
import { useGameIdFromRoute } from "./WithGameIdFromRoute";
import { usePublicGame } from "./queries";
import { AccountContext } from "../core/AccountProvider";
import { usePublisherGame } from "../publisher/queries";
import { formatAge, formatDuration, formatPlayerCount } from "./formatters";
import { PublisherStatus } from "./PublisherStatus";
import { CachedTokenContext } from "@seabrookstudios/auth";
import { AddToSubmissionsButton } from "./AddToSubmissionsButton";
import { getDaySubmitted } from "@seabrookstudios/pitch2table-core";

/**
 *
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").GameDesigners[]} props.designers
 */
const Designers = ({ gameId, designers }) => {
  const token = useContext(CachedTokenContext);

  const { data } = useQuery({
    queryKey: DesignersByGameId(gameId),
    queryFn: async () => {
      if (designers.length === 0) {
        return;
      }

      const all = Promise.all(
        designers.map(({ designerId }) => {
          return fetchContact(token, designerId);
        })
      );

      return await all;
    },
    staleTime: DefaultStaleTime,
  });

  if (!data) {
    return <p>no data</p>;
  }

  return (
    <ListItem>
      <ListItemText
        primary={data.length === 1 ? "Designer" : "Designers"}
        secondary={data.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(", ")}
      />
      <ListItemIcon>{/* <ArrowForwardOutlined /> */}</ListItemIcon>
    </ListItem>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PublicGame} props.publicGame
 */
export const PublisherViewOfPublicGameDetail = ({ publicGame }) => {
  return (
    <Box width={960} maxWidth="90vw" m="auto">
      <Box pb={1}>
        <Card elevation={1} square={false}>
          <Box p={1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <AddToSubmissionsButton gameId={publicGame.id} />
          </Box>
        </Card>
      </Box>
      <Card elevation={1} square={false}>
        <Stack direction="row">
          <Box p={2}>
            <Box alignItems="flex-end" display="flex" flex="1" pb={1}></Box>
            <EditableGameName text={publicGame.name} disabled />
            <EditableTag text={publicGame.tag} disabled />
          </Box>
        </Stack>
        <List>
          <Stack direction="row">
            <ListItem>
              <ListItemText primary="Players" secondary={formatPlayerCount(publicGame.playerCount)} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Duration" secondary={formatDuration(publicGame.duration)} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Age" secondary={formatAge(publicGame.age)} />
            </ListItem>
          </Stack>
          <Designers gameId={publicGame.id} designers={publicGame.designers} />
          <PublisherLatestSizzle url={publicGame.sizzle} />
          <PublisherSellsheet url={publicGame.sellsheet} />
          <PublisherPublicRules url={publicGame.rules} />
        </List>
      </Card>
      <Box pt={1}>
        <Card elevation={1} square={false}>
          <Box p={1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <AddToSubmissionsButton gameId={publicGame.id} />
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PublicGame|null} props.data
 * @param {ReactQueryStatus} props.status
 * @param {Error|null} props.publicGameError
 */
export const PublisherViewOfGameDetail = () => {
  const gameId = useGameIdFromRoute();
  const { publisherId } = useContext(AccountContext);
  const { data: publicGame, isFetching } = usePublicGame(gameId);
  const { data: publisherGame } = usePublisherGame(publisherId, gameId);

  if (isFetching) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (!publicGame || !gameId) {
    return <EmptyDetailCard text="Select a game from the list on the left." />;
  }

  if (!publisherGame) {
    return (
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <PublisherViewOfPublicGameDetail publicGame={publicGame} />
      </Backdrop>
    );
  }

  return (
    <Box>
      <Box pb={1}>
        <Card elevation={1} square={false}>
          <Box p={1} style={{ display: "flex", justifyContent: "space-between" }}>
            <Button color="primary" variant="contained" disabled>
              REQUEST MATERIALS
            </Button>
            <Stack direction="row" spacing={1}>
              <Button color="success" variant="outlined" disabled>
                SIGN
              </Button>
              <Button color="error" variant="outlined" disabled>
                DECLINE
              </Button>
            </Stack>
          </Box>
        </Card>
      </Box>
      <Box pb={1}>
        <Card elevation={1} square={false}>
          <Box p={1} style={{ flexDirection: "column", display: "flex", justifyContent: "space-between" }}>
            <TextareaAutosize minRows={5} style={{ width: "100%" }} />
            <Box pt={1} style={{ width: "100%", justifyContent: "space-between", display: "flex" }}>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="Share update with designer" />
              </FormGroup>
              <Button variant="contained">Add Comment</Button>
            </Box>
          </Box>
        </Card>
      </Box>
      <Card elevation={1} square={false}>
        <Stack direction="row">
          <Box p={2} display="flex" flexDirection="column" flex={1}>
            <Box justifyContent="space-between" display="flex" flex={1} pb={1}>
              <Chip label={getDaySubmitted(publisherGame?.dateSubmitted)} />
              <PublisherStatus status={publisherGame?.status} />
            </Box>
            <EditableGameName text={publicGame.name} disabled />
            <EditableTag text={publicGame.tag} disabled />
          </Box>
        </Stack>
        <List>
          <Stack direction="row">
            <ListItem>
              <ListItemText primary="Players" secondary={formatPlayerCount(publicGame.playerCount)} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Duration" secondary={formatDuration(publicGame.duration)} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Age" secondary={formatAge(publicGame.age)} />
            </ListItem>
          </Stack>
          <Designers gameId={publicGame.id} designers={publicGame.designers} />
          <PublisherLatestSizzle url={publicGame.sizzle} />
          <PublisherSellsheet url={publicGame.sellsheet} />
          <PublisherPublicRules url={publicGame.rules} />
        </List>
      </Card>
    </Box>
  );
};
