import React from "react";
import { DateTime } from "luxon";
import ListItemIcon from "@mui/material/ListItemIcon";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import Check from "@mui/icons-material/Check";
import { FollowUpDuePill } from "../games/FollowUpDuePill";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp} props.item
 */
export const RoyaltyStatusIcon = ({ item }) => {
  const isOverdue = !item.paid && DateTime.now().toMillis() > item.date;

  if (isOverdue) {
    return <FollowUpDuePill severity="error" size="small" />;
  }
  if (item.paid) {
    return (
      <ListItemIcon>
        <Check />
      </ListItemIcon>
    );
  }

  return (
    <ListItemIcon>
      <CalendarMonth />
    </ListItemIcon>
  );
};
