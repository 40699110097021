import React from "react";
import Link from "@mui/material/Link";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardOutlined from "@mui/icons-material/ArrowForwardOutlined";
import { Link as RouterLink } from "react-router-dom";
import { Path } from "../Path";
import { showAlarm } from "@seabrookstudios/pitch2table-core";
import { getSeverity } from "./publisher-interest/follow-up-severity";
import { FollowUpDuePill } from "./FollowUpDuePill";
import { useGamePublishers } from "@seabrookstudios/pitch2table";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const PublisherInterestForGameMenuItem = ({ gameId }) => {
  const { data: publishers = [] } = useGamePublishers(gameId);

  const due = publishers.map((p) => showAlarm(p.nextAction));
  const severity = publishers.reduce((s, p) => {
    if (s === "error") {
      return s;
    }

    const newSeverity = getSeverity(p.nextAction);

    if (newSeverity === "error") {
      return "error";
    }
    if (newSeverity === "warning") {
      return "warning";
    }

    return s;
  }, "info");

  return (
    <ListItem>
      <ListItemText
        primary="Publisher Interest"
        secondary={`${publishers ? publishers.length : "?"} ${publishers.length === 1 ? "publisher" : "publishers"}`}
      />
      {due.length > 0 && severity !== "info" && <FollowUpDuePill severity={severity} />}
      <Link to={Path.game(gameId).publishers()} component={RouterLink}>
        <ListItemIcon>
          <ListItemButton>
            <ArrowForwardOutlined />
          </ListItemButton>
        </ListItemIcon>
      </Link>
    </ListItem>
  );
};
