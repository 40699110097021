import { useUserProfile } from "../../user/queries";

/**
 * @type {Record<import("@seabrookstudios/pitch2table-core").Currency, string>}
 */
const format = {
  USD: "en-US",
  AUD: "en-AU",
  EUR: "en-DE",
  GBP: "en-UK",
  INR: "en-IN",
  BGN: "bg",
  BRL: "pt-BR",
  CAD: "en-CA",
  CHF: "gsw",
  CNY: "zh",
  CZK: "cs",
  DKK: "da",
  HKD: "en-cz",
  HUF: "hu-HU",
  IDR: "in",
  ILS: "he-IL",
  ISK: "is",
  JPY: "ja",
  KRW: "kr",
  MXN: "es-mx",
  MYR: "ms",
  NOK: "no",
  NZD: "en-NZ",
  PHP: "ph",
  PLN: "pl",
  RON: "ro",
  SEK: "sv",
  SGD: "en-SG",
  THB: "th",
  TRY: "tr",
  ZAR: "en-za",
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").Currency} currency
 * @returns {(amount: number) => string}
 */
export const formatCurrency = (currency) => (amount) => {
  return new Intl.NumberFormat(format[currency], {
    style: "currency",
    currencyDisplay: "symbol",
    currency,
  }).format(amount);
};

export const useUserCurrencyFormatter = () => {
  const { data: profile } = useUserProfile();

  if (!profile || !profile.currencySettings || !profile.currencySettings.preferredCurrency) {
    return (amount) => `${amount}`;
  }

  return formatCurrency(profile.currencySettings.preferredCurrency);
};

/**
 * @type {Record<import("@seabrookstudios/pitch2table-core").Currency, object>}
 */
export const CurrencyData = {
  USD: {
    symbol: "$",
    name: "US Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "USD",
    namePlural: "US dollars",
  },
  CAD: {
    symbol: "CA$",
    name: "Canadian Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "CAD",
    namePlural: "Canadian dollars",
  },
  EUR: {
    symbol: "€",
    name: "Euro",
    symbolNative: "€",
    decimalDigits: 2,
    rounding: 0,
    code: "EUR",
    namePlural: "euros",
  },
  AUD: {
    symbol: "AU$",
    name: "Australian Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "AUD",
    namePlural: "Australian dollars",
  },
  BGN: {
    symbol: "BGN",
    name: "Bulgarian Lev",
    symbolNative: "лв.",
    decimalDigits: 2,
    rounding: 0,
    code: "BGN",
    namePlural: "Bulgarian leva",
  },
  BRL: {
    symbol: "R$",
    name: "Brazilian Real",
    symbolNative: "R$",
    decimalDigits: 2,
    rounding: 0,
    code: "BRL",
    namePlural: "Brazilian reals",
  },
  CHF: {
    symbol: "CHF",
    name: "Swiss Franc",
    symbolNative: "CHF",
    decimalDigits: 2,
    rounding: 0.05,
    code: "CHF",
    namePlural: "Swiss francs",
  },
  CNY: {
    symbol: "CN¥",
    name: "Chinese Yuan",
    symbolNative: "CN¥",
    decimalDigits: 2,
    rounding: 0,
    code: "CNY",
    namePlural: "Chinese yuan",
  },
  CZK: {
    symbol: "Kč",
    name: "Czech Republic Koruna",
    symbolNative: "Kč",
    decimalDigits: 2,
    rounding: 0,
    code: "CZK",
    namePlural: "Czech Republic korunas",
  },
  DKK: {
    symbol: "Dkr",
    name: "Danish Krone",
    symbolNative: "kr",
    decimalDigits: 2,
    rounding: 0,
    code: "DKK",
    namePlural: "Danish kroner",
  },
  GBP: {
    symbol: "£",
    name: "British Pound Sterling",
    symbolNative: "£",
    decimalDigits: 2,
    rounding: 0,
    code: "GBP",
    namePlural: "British pounds sterling",
  },
  HKD: {
    symbol: "HK$",
    name: "Hong Kong Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "HKD",
    namePlural: "Hong Kong dollars",
  },
  HUF: {
    symbol: "Ft",
    name: "Hungarian Forint",
    symbolNative: "Ft",
    decimalDigits: 0,
    rounding: 0,
    code: "HUF",
    namePlural: "Hungarian forints",
  },
  IDR: {
    symbol: "Rp",
    name: "Indonesian Rupiah",
    symbolNative: "Rp",
    decimalDigits: 0,
    rounding: 0,
    code: "IDR",
    namePlural: "Indonesian rupiahs",
  },
  ILS: {
    symbol: "₪",
    name: "Israeli New Sheqel",
    symbolNative: "₪",
    decimalDigits: 2,
    rounding: 0,
    code: "ILS",
    namePlural: "Israeli new sheqels",
  },
  INR: {
    symbol: "Rs",
    name: "Indian Rupee",
    symbolNative: "টকা",
    decimalDigits: 2,
    rounding: 0,
    code: "INR",
    namePlural: "Indian rupees",
  },
  ISK: {
    symbol: "Ikr",
    name: "Icelandic Króna",
    symbolNative: "kr",
    decimalDigits: 0,
    rounding: 0,
    code: "ISK",
    namePlural: "Icelandic krónur",
  },
  JPY: {
    symbol: "¥",
    name: "Japanese Yen",
    symbolNative: "￥",
    decimalDigits: 0,
    rounding: 0,
    code: "JPY",
    namePlural: "Japanese yen",
  },
  KRW: {
    symbol: "₩",
    name: "South Korean Won",
    symbolNative: "₩",
    decimalDigits: 0,
    rounding: 0,
    code: "KRW",
    namePlural: "South Korean won",
  },
  MXN: {
    symbol: "MX$",
    name: "Mexican Peso",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "MXN",
    namePlural: "Mexican pesos",
  },
  MYR: {
    symbol: "RM",
    name: "Malaysian Ringgit",
    symbolNative: "RM",
    decimalDigits: 2,
    rounding: 0,
    code: "MYR",
    namePlural: "Malaysian ringgits",
  },
  NOK: {
    symbol: "Nkr",
    name: "Norwegian Krone",
    symbolNative: "kr",
    decimalDigits: 2,
    rounding: 0,
    code: "NOK",
    namePlural: "Norwegian kroner",
  },
  NZD: {
    symbol: "NZ$",
    name: "New Zealand Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "NZD",
    namePlural: "New Zealand dollars",
  },
  PHP: {
    symbol: "₱",
    name: "Philippine Peso",
    symbolNative: "₱",
    decimalDigits: 2,
    rounding: 0,
    code: "PHP",
    namePlural: "Philippine pesos",
  },
  PLN: {
    symbol: "zł",
    name: "Polish Zloty",
    symbolNative: "zł",
    decimalDigits: 2,
    rounding: 0,
    code: "PLN",
    namePlural: "Polish zlotys",
  },
  RON: {
    symbol: "RON",
    name: "Romanian Leu",
    symbolNative: "RON",
    decimalDigits: 2,
    rounding: 0,
    code: "RON",
    namePlural: "Romanian lei",
  },
  SEK: {
    symbol: "Skr",
    name: "Swedish Krona",
    symbolNative: "kr",
    decimalDigits: 2,
    rounding: 0,
    code: "SEK",
    namePlural: "Swedish kronor",
  },
  SGD: {
    symbol: "S$",
    name: "Singapore Dollar",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "SGD",
    namePlural: "Singapore dollars",
  },
  THB: {
    symbol: "฿",
    name: "Thai Baht",
    symbolNative: "฿",
    decimalDigits: 2,
    rounding: 0,
    code: "THB",
    namePlural: "Thai baht",
  },
  TRY: {
    symbol: "TL",
    name: "Turkish Lira",
    symbolNative: "TL",
    decimalDigits: 2,
    rounding: 0,
    code: "TRY",
    namePlural: "Turkish Lira",
  },
  ZAR: {
    symbol: "R",
    name: "South African Rand",
    symbolNative: "R",
    decimalDigits: 2,
    rounding: 0,
    code: "ZAR",
    namePlural: "South African rand",
  },
};
