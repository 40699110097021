import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import { enqueueSnackbar } from "notistack";
import { useDebounceCallback, useResizeObserver } from "usehooks-ts";
import ReactPlayer from "react-player";
import { isInvalidUrl } from "../core/validation";
import { useUpdateGameV2 } from "@seabrookstudios/pitch2table";
import { EditableListItemUrl } from "../core/EditableListItemUrl";

export const UploadedSizzle = ({ gameId, url }) => {
  const onUpdateGameSuccess = () => {
    enqueueSnackbar("Sizzle updated", { variant: "success" });
  };
  const onUpdateGameFailure = () => {
    enqueueSnackbar("Sizzle update failed", { variant: "error" });
  };

  const updateGame = useUpdateGameV2(gameId, onUpdateGameSuccess, onUpdateGameFailure);

  /**
   * @param {import("@seabrookstudios/types").Url} sizzle
   */
  const updateSizzleLink = (sizzle) => {
    if (isInvalidUrl(sizzle)) {
      enqueueSnackbar("Please supply valid url", { variant: "info" });
      return;
    }

    updateGame.mutate({ sizzle, bgg: undefined });
  };

  const ref = useRef(null);
  const [{ width }, setSize] = useState(
    // @ts-ignore
    /** @type {Size} */ ({
      width: 0,
      height: 0,
    })
  );

  const onResize = useDebounceCallback(setSize, 200);

  useResizeObserver({
    ref,
    onResize,
  });

  return (
    <>
      <EditableListItemUrl
        onSave={updateSizzleLink}
        text={url}
        title="Sizzle Reel"
        helperText="A link to your sizzle reel hosted on another platform like YouTube."
      />
      {url && (
        <Box flex={1} justifyContent="center" alignItems="center" display="flex" ref={ref}>
          <ReactPlayer light url={url} width={width - 32} height={(width - 32) * 0.5625} />
        </Box>
      )}
    </>
  );
};
