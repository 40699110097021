import React from "react";
import { DateTime } from "luxon";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp|undefined} props.item
 */
export const RoyaltyDate = ({ item }) => {
  if (!item) {
    return (
      <Stack direction="column" style={{ flexShrink: 0 }}>
        <Typography variant="inherit">
          <Skeleton />
        </Typography>
        <Typography variant="inherit" textAlign="right">
          <Skeleton />
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack direction="column" style={{ flexShrink: 0 }}>
      <Typography variant="inherit">{DateTime.fromMillis(item.date).toFormat("MMM d ")}</Typography>
      <Typography variant="inherit" textAlign="right">
        {DateTime.fromMillis(item.date).toFormat("yyyy")}
      </Typography>
    </Stack>
  );
};
