import React from "react";
import numeral from "numeral";
import { idExtractor, useMakeListAndDetail, useMakeListBuilder } from "../../core/useMakeLayout";
import { NoItemsFound } from "../../publisher/NoItemsFound";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "../../Path";
import { useGameIdFromRoute, useLaneIdFromRoute } from "../WithGameIdFromRoute";
import { useGameLanes, useLane, useLaneQuotes } from "../components/Concept";
import { EmptyDetailCard } from "../../core/EmptyDetailCard";
import { ArrowListItem } from "../../core/ArrowListItem";

const NoPublishersFound = () => (
  <NoItemsFound>
    <Alert severity="info">
      Each lane is a different destination for your printed games. Each lane will have their own freight, customs and
      warehousing costs associated with it.
    </Alert>
  </NoItemsFound>
);

const GameLaneFromRoute = () => {
  const gameId = useGameIdFromRoute();
  const { laneId } = useParams();

  const { data: lane } = useLane(laneId);
  const { data: quotes = [] } = useLaneQuotes(laneId);

  if (!gameId || !laneId) {
    return <EmptyDetailCard text="Select a shipping lane from the list on the left." />;
  }

  return (
    <Box>
      <Card elevation={1} square={false}>
        <List>
          <ListItem>
            {lane ? <ListItemText primary="Destination" secondary={lane.to} /> : <Skeleton width="69%" />}
          </ListItem>
          <ListItem>
            {lane ? <ListItemText primary="Units" secondary={lane.units} /> : <Skeleton width="69%" />}
          </ListItem>
          <ListItem>
            {lane ? <ListItemText primary="Pallet" secondary={lane.pallet} /> : <Skeleton width="69%" />}
          </ListItem>
          <ListItem>
            {lane ? (
              <ListItemText
                primary="Less than container load (LSL)?"
                secondary={lane.lessThanContainerLoad ? "Yes" : "No"}
              />
            ) : (
              <Skeleton width="69%" />
            )}
          </ListItem>
        </List>
      </Card>
      <Box p={1} />
      <Card elevation={1} square={false}>
        <List>
          <ArrowListItem
            primary="Quotes"
            secondary={`${quotes.length} ${quotes.length === 1 ? "quote" : "quotes"} found.`}
            to={Path.game(gameId).lane(laneId).quotes()}
          />
        </List>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head" align="left">
                  Source
                </TableCell>
                <TableCell variant="head" align="center">
                  Method
                </TableCell>
                <TableCell variant="head" align="right">
                  Duration
                </TableCell>
                <TableCell variant="head" align="right">
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quotes.map((quote) => (
                <TableRow key={quote.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="left">{quote.source}</TableCell>
                  <TableCell align="center">{quote.method}</TableCell>
                  <TableCell align="right">{`${quote.minDays} - ${quote.maxDays} days`}</TableCell>
                  <TableCell align="right">
                    {numeral(quote.amount).format("0,0.00")} {quote.currency}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
};

/**
 * @param {Object} props
 * @param {import("../components/fake-data").Lane} props.item
 */
export const MyFreightListItem = ({ item }) => {
  const gameId = useGameIdFromRoute();
  const publisherId = useLaneIdFromRoute();
  const navigate = useNavigate();

  const selected = publisherId === item.id;

  const onClickHandler = () => {
    navigate(Path.game(gameId).lane(item.id).link());
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemText primary={item.to} secondary={`${item.units} units - LSL - ${item.pallet}`} />
    </ListItemButton>
  );
};

const LaneSummary = () => {
  const gameId = useGameIdFromRoute();
  const { data: lanes = [] } = useGameLanes(gameId);

  const total = lanes.map((lane) => lane.units).reduce((t, v) => t + v, 0);

  return (
    <Box>
      <ListItem>
        <ListItemText primary="Total Units" secondary={total} />
      </ListItem>
      <Divider />
    </Box>
  );
};

export const FreightListAndDetail = () => {
  const gameId = useGameIdFromRoute();
  const useMakeList = useMakeListBuilder(
    MyFreightListItem,
    idExtractor,
    "Shipping Lanes",
    NoPublishersFound,
    undefined,
    LaneSummary
  );

  const useWrappedHook = () => useGameLanes(gameId);

  const ListAndDetail = useMakeListAndDetail(useWrappedHook, [], "freight-lanes", useMakeList, GameLaneFromRoute);

  return <ListAndDetail />;
};
