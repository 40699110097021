import React from "react";
import Typography from "@mui/material/Typography";
import { formatCurrency, useUserCurrencyFormatter } from "../games/royalties/currency-formatting";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp} props.item
 */
export const RoyaltyAmount = ({ item }) => {
  const formatUserCurrency = useUserCurrencyFormatter();

  return (
    <Typography variant="inherit">
      {formatUserCurrency(item.convertedAmount)}{" "}
      {item.currency !== item.convertedCurrency && (
        <Typography as="span" color="text.secondary">
          (converted from {formatCurrency(item.currency)(item.amount)})
        </Typography>
      )}
    </Typography>
  );
};
