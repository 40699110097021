import React from "react";
import { useGame, usePublisherAndContacts } from "@seabrookstudios/pitch2table";
import { useRoyaltyIdFromRoute } from "../games/WithGameIdFromRoute";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { Path } from "../Path";
import { useNavigate } from "react-router-dom";
import { RoyaltyTypeLabels } from "@seabrookstudios/pitch2table-core";
import { RoyaltyStatusIcon } from "./RoyaltyStatusIcon";
import { RoyaltyAmount } from "./RoyaltyAmount";
import { RoyaltyDate } from "./RoyaltyDate";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Skeleton from "@mui/material/Skeleton";
import { CoverSquare } from "../games/CoverImages";
import { useUserCurrencyFormatter } from "../games/royalties/currency-formatting";
import { royaltyToYear, RoyaltyTypeIcons } from "./model";
import { PublisherLogoSquare2 } from "../publisher/PublisherLogo";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp} props.item
 */
export const ListEntry = ({ item }) => {
  const { data: game } = useGame(item.gameId);

  const royaltyId = useRoyaltyIdFromRoute();
  const navigate = useNavigate();

  const selected = royaltyId === item.id;

  const onClickHandler = () => {
    navigate(Path.game(item.gameId).royalty(item.id));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <RoyaltyStatusIcon item={item} />
      <ListItemText
        primary={<RoyaltyAmount item={item} />}
        secondary={`${game ? `${game.name} - ` : ""} ${RoyaltyTypeLabels[item.type]}`}
      />
      <RoyaltyDate item={item} />
    </ListItemButton>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp} props.item
 */
export const ListEntryWithGameInfo = ({ item }) => {
  const { data: game } = useGame(item.gameId);

  const royaltyId = useRoyaltyIdFromRoute();
  const navigate = useNavigate();

  const selected = royaltyId === item.id;

  const onClickHandler = () => {
    const year = royaltyToYear(item);
    navigate(Path.royalties().byYear(year).royalty(item.id));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemAvatar>
        {game ? <CoverSquare game={game} width={48} height={48} /> : <Skeleton width={48} height={48} />}
      </ListItemAvatar>
      <ListItemText
        primary={<RoyaltyAmount item={item} />}
        secondary={`${game ? `${game.name} - ` : ""} ${RoyaltyTypeLabels[item.type]}`}
      />
      <RoyaltyDate item={item} />
    </ListItemButton>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp} props.item
 */
export const ListEntryByType = ({ item }) => {
  const { data: game } = useGame(item.gameId);

  const royaltyId = useRoyaltyIdFromRoute();
  const navigate = useNavigate();

  const selected = royaltyId === item.id;

  const onClickHandler = () => {
    navigate(Path.royalties().byType(item.type).royalty(item.id));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemAvatar>
        {game ? <CoverSquare game={game} width={48} height={48} /> : <Skeleton width={48} height={48} />}
      </ListItemAvatar>
      <ListItemText
        primary={<RoyaltyAmount item={item} />}
        secondary={`${game ? `${game.name} - ` : ""} ${RoyaltyTypeLabels[item.type]}`}
      />
      <RoyaltyDate item={item} />
    </ListItemButton>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp} props.item
 */
export const ListEntryByPublisher = ({ item }) => {
  const { data: game } = useGame(item.gameId);

  const royaltyId = useRoyaltyIdFromRoute();
  const navigate = useNavigate();

  const selected = royaltyId === item.id;

  const onClickHandler = () => {
    const publisherId = item.publisherId === undefined ? "unlinked" : item.publisherId;
    navigate(Path.royalties().byPublisher(publisherId).royalty(item.id));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemAvatar>
        {game ? <CoverSquare game={game} width={48} height={48} /> : <Skeleton width={48} height={48} />}
      </ListItemAvatar>
      <ListItemText
        primary={<RoyaltyAmount item={item} />}
        secondary={`${game ? `${game.name} - ` : ""} ${RoyaltyTypeLabels[item.type]}`}
      />
      <RoyaltyDate item={item} />
    </ListItemButton>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp[]} props.item
 */
export const RoyaltiesByGameListEntry = ({ item }) => {
  const gameId = item[0].gameId;
  const { data: game } = useGame(gameId);
  const formatUserCurrency = useUserCurrencyFormatter();

  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(Path.game(gameId).royalties());
  };

  const total = item.reduce((t, royalty) => t + royalty.convertedAmount, 0);
  const paid = item.filter((r) => r.paid).reduce((t, royalty) => t + royalty.convertedAmount, 0);

  return (
    <ListItemButton alignItems="flex-start" selected={false} onClick={onClickHandler}>
      <ListItemAvatar>
        {game ? <CoverSquare game={game} width={48} height={48} /> : <Skeleton width={48} height={48} />}
      </ListItemAvatar>
      <ListItemText
        primary={game ? game.name : <Skeleton />}
        secondary={`${formatUserCurrency(paid)} ${
          total !== paid ? `(${formatUserCurrency(total)} including unpaid)` : ""
        }`}
      />
    </ListItemButton>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp[]} props.item
 */
export const RoyaltiesByPublisherListEntry = ({ item }) => {
  const publisherId = item[0].publisherId;
  const { data: pubAndContact = [] } = usePublisherAndContacts(publisherId);
  const [publisher] = pubAndContact;
  const formatUserCurrency = useUserCurrencyFormatter();

  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(
      Path.royalties()
        .byPublisher(publisherId || "unlinked")
        .all()
    );
  };

  const total = item.reduce((t, royalty) => t + royalty.convertedAmount, 0);
  const paid = item.filter((r) => r.paid).reduce((t, royalty) => t + royalty.convertedAmount, 0);

  if (!publisherId) {
    return (
      <ListItemButton alignItems="flex-start" selected={false} onClick={onClickHandler}>
        <ListItemAvatar></ListItemAvatar>
        <ListItemText
          primary="No Publisher Linked"
          secondary={`${formatUserCurrency(paid)} ${
            total !== paid ? `(${formatUserCurrency(total)} including unpaid)` : ""
          }`}
        />
      </ListItemButton>
    );
  }

  return (
    <ListItemButton alignItems="flex-start" selected={false} onClick={onClickHandler}>
      <ListItemAvatar>
        {publisher ? <PublisherLogoSquare2 publisher={publisher} /> : <Skeleton width={32} height={32} />}
      </ListItemAvatar>
      <ListItemText
        primary={publisher ? publisher.name : <Skeleton />}
        secondary={`${formatUserCurrency(paid)} ${
          total !== paid ? `(${formatUserCurrency(total)} including unpaid)` : ""
        }`}
      />
    </ListItemButton>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp[]} props.item
 */
export const RoyaltiesByYearListEntry = ({ item }) => {
  const formatUserCurrency = useUserCurrencyFormatter();

  const navigate = useNavigate();

  const year = royaltyToYear(item[0]);

  const onClickHandler = () => {
    navigate(Path.royalties().byYear(year).all());
  };

  const total = item.reduce((t, royalty) => t + royalty.convertedAmount, 0);
  const paid = item.filter((r) => r.paid).reduce((t, royalty) => t + royalty.convertedAmount, 0);

  return (
    <ListItemButton alignItems="flex-start" selected={false} onClick={onClickHandler}>
      <ListItemAvatar>{year}</ListItemAvatar>
      <ListItemText
        primary={`${formatUserCurrency(paid)} ${
          total !== paid ? `(${formatUserCurrency(total)} including unpaid)` : ""
        }`}
        secondary={item.length === 1 ? "1 payment" : `${item.length} payments`}
      />
    </ListItemButton>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp[]} props.item
 */
export const RoyaltiesByTypeListEntry = ({ item }) => {
  const formatUserCurrency = useUserCurrencyFormatter();

  const navigate = useNavigate();

  const { type } = item[0];
  const onClickHandler = () => {
    navigate(Path.royalties().byType(type).all());
  };

  const total = item.reduce((t, royalty) => t + royalty.convertedAmount, 0);
  const paid = item.filter((r) => r.paid).reduce((t, royalty) => t + royalty.convertedAmount, 0);

  const Icon = RoyaltyTypeIcons[type];

  return (
    <ListItemButton alignItems="flex-start" selected={false} onClick={onClickHandler}>
      <ListItemAvatar>
        <Icon />
      </ListItemAvatar>
      <ListItemText
        primary={RoyaltyTypeLabels[type]}
        secondary={`${formatUserCurrency(paid)} ${
          total !== paid ? `(${formatUserCurrency(total)} including unpaid)` : ""
        }`}
      />
    </ListItemButton>
  );
};
