import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useGameContracts } from "@seabrookstudios/pitch2table";
import { formatContractDescription } from "./model";

const Label = "Contract";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").AttachmentId|undefined} props.value
 * @param {(value: import("@seabrookstudios/pitch2table-core").AttachmentId) => void} props.onChange
 * @param {string|Element} props.helperText
 * @param {boolean} [props.disabled]
 */
export const ContractSelect = ({ gameId, value, onChange, helperText, disabled = false }) => {
  const { data: contracts = [], isLoading } = useGameContracts(gameId);

  /**
   * @param {*} ev
   */
  const onChangeHandler = (ev) => {
    onChange(ev.target.value);
  };

  return (
    <FormControl sx={{ width: "100%" }} margin="normal" disabled={disabled}>
      <InputLabel id="contract">{Label}</InputLabel>
      <Select
        labelId="contract"
        value={value}
        onChange={onChangeHandler}
        placeholder={Label}
        label={Label}
        input={<OutlinedInput label={Label} />}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          contracts.map((contract) => (
            <MenuItem key={contract.id} value={contract.id}>
              {formatContractDescription(contract)}
            </MenuItem>
          ))
        )}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
