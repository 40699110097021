import React, { useState } from "react";
import { FullScreenDialog } from "./FullScreenDialog";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { DateTime } from "luxon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers";
import { formatFullDate } from "./date-formatting";

/**
 * @param {object} props
 * @param {(newDate: number) => void} props.onSave
 * @param {string} props.title
 * @param {boolean} props.isBusy
 * @param {import("react").ReactNode} props.children
 * @param {number} props.initialValue
 * @param {boolean} [props.disablePast]
 * @param {boolean} [props.disableFuture]
 */
export const EditDateDialog = ({
  onSave,
  title,
  isBusy,
  children,
  initialValue = 0,
  disableFuture = false,
  disablePast = false,
}) => {
  // the one we use for the date controls
  const [date, setDate] = useState(DateTime.fromMillis(initialValue));
  // the one we send to the server
  const [newDate, setNewDate] = useState(initialValue);

  const onSaveHandler = () => {
    onSave(newDate);
  };

  /**
   * @param {DateTime} componentDate
   */
  const onUpdateDate = (componentDate) => {
    setNewDate(componentDate.toMillis());
    setDate(componentDate);
  };

  return (
    <FullScreenDialog
      fullscreen={false}
      title={`Edit ${title.toLowerCase()}`}
      actionText="SAVE"
      onSaveHandler={onSaveHandler}
      isBusy={isBusy}
      CustomControl={children}
    >
      <Card elevation={2}>
        <Box p={1}>
          <Stack direction="row">
            <DatePicker
              defaultValue={date}
              label="Date"
              onChange={onUpdateDate}
              disablePast={disablePast}
              disableFuture={disableFuture}
            />
            <Box justifyContent="center" alignItems="center" display="flex" flex={1}>
              <Typography>{formatFullDate(newDate)}</Typography>
            </Box>
          </Stack>
        </Box>
      </Card>
    </FullScreenDialog>
  );
};
