import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { EditTextFieldDialog } from "../core/EditTextFieldDialog";

export const EditableInternalIdDisplay = ({ value }) => (
  <Typography variant="body2" textAlign="right" color="text.disabled">
    {value}
  </Typography>
);

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.text
 * @param {string} props.helperText
 * @param {(text: string) => void} props.onSave
 */
export const EditableInternalId = ({ title, text, helperText, onSave }) => {
  return (
    <Stack direction="row" display="flex" justifyContent="flex-end">
      <Tooltip title="Click to edit">
        <Box>
          <EditTextFieldDialog onSave={onSave} title={title} initialValue={text} isBusy={false} helperText={helperText}>
            <EditableInternalIdDisplay value={text} />
          </EditTextFieldDialog>
        </Box>
      </Tooltip>
    </Stack>
  );
};
