import React from "react";
import { Path } from "../Path";
import { AppWithSideMenu } from "../core/AppWithSideMenu";
import { GamesBreadcrumb, makeBreadcrumbs3, MyGames } from "../core/Breadcrumbs";
import { AppLink } from "../styleguide/AppLink";
import { TopicListAndDetail } from "./TopicListAndDetail";
import Skeleton from "@mui/material/Skeleton";
import { useGameTopic } from "@seabrookstudios/pitch2table";
import { GameQuickLinks } from "../games/GameQuickLinks";

export const routes = [
  {
    path: Path.game(":gameId").topics().pathname,
    element: (
      <AppWithSideMenu>
        <TopicListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        MyGames,
        ({ gameId }) => <GamesBreadcrumb gameId={gameId} />,
        ({ gameId }) => (
          <AppLink key="royalties" to={Path.game(gameId).topics()}>
            Message Board
          </AppLink>
        ),
      ]),
      prefer: "left",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(":gameId").topic(":topicId").pathname,
    element: (
      <AppWithSideMenu>
        <TopicListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        MyGames,
        ({ gameId }) => <GamesBreadcrumb gameId={gameId} />,
        ({ gameId }) => (
          <AppLink key="message-board" to={Path.game(gameId).topics()}>
            Message Board
          </AppLink>
        ),
        ({ gameId, topicId }) => {
          const { data: topic } = useGameTopic(gameId, topicId);

          return (
            <AppLink key="message" to={Path.game(gameId).topic(topicId)}>
              {topic ? topic.name : <Skeleton />}
            </AppLink>
          );
        },
      ]),
      prefer: "right",
      quickLinks: GameQuickLinks,
    },
  },
];
