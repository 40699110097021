import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCity, updateCountry, updateTimezone, updatePreferredCurrency } from "../users/users-service";
import { UpdatableNameTextField } from "./UpdatableNameTextField";
import { ThisUser } from "../games/query-keys";
import { DateTime } from "luxon";
import tzData from "tzdata";
import { CachedTokenContext } from "@seabrookstudios/auth";
import { useUserProfile } from "../user/queries";
import { CurrentDropDown } from "../currency/CurrencyDropDown";
import { enqueueSnackbar } from "notistack";

const CityTextField = ({ initialValue }) => {
  return (
    <UpdatableNameTextField
      label="City"
      helperText="The city where you reside."
      initialValue={initialValue}
      updateFunction={updateCity}
      queryKey={ThisUser()}
    />
  );
};

const CountryTextField = ({ initialValue }) => {
  return (
    <UpdatableNameTextField
      label="Country"
      helperText="The country you live in. This is relevant to some publishers."
      initialValue={initialValue}
      updateFunction={updateCountry}
      queryKey={ThisUser()}
    />
  );
};

const useUpdatePreferredCurrency = () => {
  const token = useContext(CachedTokenContext);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    /**
     * @param {import("@seabrookstudios/pitch2table-core").Currency} newValue
     */
    mutationFn: async (newValue) => {
      const response = await updatePreferredCurrency(token, newValue);
      return response;
    },
    onSuccess: () => {
      enqueueSnackbar(`Preferred currency updated.`, { variant: "success" });
      queryClient.invalidateQueries({ queryKey: ThisUser() });
    },
  });

  return mutation;
};

const PreferredCurrency = ({ initialValue }) => {
  const updatePreferredCurrency = useUpdatePreferredCurrency();

  const saveCurrency = async (newValue) => {
    await updatePreferredCurrency.mutate(newValue);
  };

  return (
    <CurrentDropDown
      label="Preferred Currency"
      value={initialValue}
      onChange={saveCurrency}
      helperText="All amounts will be converted to this for display. The original currency of any royalty payment will not be lost."
    />
  );
};

const luxonValidTimezones = [
  ...new Set(Object.keys(tzData.zones).filter((tz) => tz.includes("/") && DateTime.local().setZone(tz).isValid)),
].sort((a, b) => (a < b ? -1 : 1));

const TimezoneSelect = ({ value, onChange, queryKey }) => {
  const token = useContext(CachedTokenContext);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (newValue) => {
      return await onChange(token, newValue);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const onChangeHandler = async (event) => {
    mutation.mutate(event.target.value);
  };

  return (
    <FormControl sx={{ width: "100%" }} margin="normal">
      <InputLabel id="game-status">Timezone</InputLabel>
      <Select
        labelId="game-status"
        value={value}
        onChange={onChangeHandler}
        placeholder="status"
        label="Time Zone"
        input={<OutlinedInput label="Time Zone" />}
      >
        {luxonValidTimezones.map((x) => (
          <MenuItem key={x} value={x}>
            {x.replace("_", " ")}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        The timezone you live in. This is relevant to most publishers. We also use this when scheduling emails for you.
      </FormHelperText>
    </FormControl>
  );
};

const TimeZoneDropDown = ({ initialValue }) => {
  return <TimezoneSelect value={initialValue} onChange={updateTimezone} queryKey={ThisUser()} />;
};

export const YourLocation = () => {
  const { data: profile, isLoading } = useUserProfile();

  if (isLoading) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (!profile) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <span>An error occurred, please refresh the page.</span>
        </Box>
      </Card>
    );
  }

  return (
    <Card elevation={1} square={false}>
      <List>
        <ListItem>
          <CityTextField initialValue={profile.location.city} />
        </ListItem>
        <ListItem>
          <CountryTextField initialValue={profile.location.country} />
        </ListItem>
        <ListItem>
          <TimeZoneDropDown initialValue={profile.location.timezone} />
        </ListItem>
        <ListItem>
          <PreferredCurrency initialValue={profile.currencySettings.preferredCurrency} />
        </ListItem>
      </List>
    </Card>
  );
};
