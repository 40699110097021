import React, { useState } from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";

/**
 * @param {object} props
 * @param {import("react").ReactNode} props.children
 */
export const QuickSearch = ({ children }) => {
  const [showIssueCreatorModal, setShowIssueCreatorModal] = useState(false);

  // useHotkeys("mod+k", () => setShowIssueCreatorModal(true));
  // useHotkeys("escape", () => setShowIssueCreatorModal(false));

  if (!showIssueCreatorModal) {
    return children;
  }

  return (
    <>
      {children}
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={showIssueCreatorModal}
        onClick={() => setShowIssueCreatorModal(false)}
      >
        <Box width="66%">
          <Card>
            <TextField fullWidth label="Search" id="search" />
          </Card>
        </Box>
      </Backdrop>
    </>
  );
};
