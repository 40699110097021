import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMatches } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import { Features } from "../games/features";

export const LayoutContext = createContext({ singleView: false, toggleView: () => {} });

export const UseLayoutProvider = ({ children }) => {
  const { width, height } = useWindowSize();

  const [singleView, setSingleView] = useState(width < height);

  const toggleView = useCallback(() => setSingleView((c) => !c), [setSingleView]);

  const value = useMemo(() => ({ singleView, toggleView }), [singleView, toggleView]);

  useEffect(() => {
    setSingleView(width < height);
  }, [width, height]);

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export const useLayoutMode = () => {
  const { singleView } = useContext(LayoutContext);

  const matches = useMatches();
  const config = matches[0];
  const showLeft = !(singleView && config.handle.prefer === "right");
  const showRight = !(singleView && config.handle.prefer === "left");

  return {
    showLeft,
    showRight,
    singleView: singleView,
    preferLeft: config.handle.prefer === "left",
    preferRight: config.handle.prefer === "right",
  };
};
