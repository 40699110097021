import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useUserProfile } from "../user/queries";
import { useUpdateProfile } from "@seabrookstudios/pitch2table";
import { EditableSwitch } from "../core/EditableSwitch";
import { AlwaysOnAppLink } from "../styleguide/AppLink";
import { Path } from "../Path";
import { onSaveFailed, onSaveSuccess } from "../core/toast";

/**
 * @param {object} props
 * @param {boolean} props.initialValue
 */
const MarketingConsent = ({ initialValue }) => {
  const useUpdateUserProfile = useUpdateProfile(onSaveSuccess, onSaveFailed);

  /**
   * @param {boolean} newValue
   */
  const onChange = async (newValue) => {
    await useUpdateUserProfile.mutateAsync({ toUpdate: { marketingConsent: newValue } });
  };

  return (
    <EditableSwitch
      initialValue={initialValue}
      onUpdate={onChange}
      title="Marketing Consent"
      onLabel="We may send you updates about pitch2table.com (approximately 1 per month)"
      offLabel="We won't send you updates about pitch2table.com"
    />
  );
};

/**
 * @param {object} props
 * @param {boolean} props.initialValue
 * @param {boolean} props.hasTimezone
 */
const SendDailyEmails = ({ initialValue, hasTimezone }) => {
  const useUpdateUserProfile = useUpdateProfile(onSaveSuccess, onSaveFailed);

  /**
   * @param {boolean} newValue
   */
  const onChange = async (newValue) => {
    await useUpdateUserProfile.mutateAsync({ toUpdate: { sendDailyEmails: newValue } });
  };

  if (!hasTimezone) {
    return (
      <EditableSwitch
        initialValue={false}
        onUpdate={onChange}
        disabled={true}
        title="Follow Up Reminders"
        onLabel=""
        offLabel=""
        disabledLabel={
          <Typography variant="body2">
            No timezone set. Please set your timezone in your{" "}
            <AlwaysOnAppLink to={Path.settings().category("your-location")}>location settings</AlwaysOnAppLink> to use
            this feature.
          </Typography>
        }
      />
    );
  }

  return (
    <EditableSwitch
      initialValue={initialValue}
      onUpdate={onChange}
      title="Follow Up Reminder"
      onLabel="Receive an email when you are due to follow up with a publisher (at most one per day)."
      offLabel="No email will be sent when publisher follow ups are due."
    />
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").HoursOftheDay} props.initialValue
 */
const TimeOfDayDropDown = ({ initialValue }) => {
  const [local, setLocal] = useState(initialValue);
  const useUpdateUserProfile = useUpdateProfile(onSaveSuccess, onSaveFailed);

  /**
   * @param {import("react").ChangeEvent<HTMLInputElement>} e
   */
  const onChange = async (e) => {
    const newValue = /** @type {import("@seabrookstudios/pitch2table-core").HoursOftheDay} */ (e.target.value);

    await useUpdateUserProfile.mutateAsync({ toUpdate: { whenToSendDailyEmails: newValue } });
    setLocal(newValue);
  };

  return (
    <FormControl sx={{ width: "100%" }} margin="normal">
      <InputLabel id="hour-status">Preferred Email Time</InputLabel>
      <Select
        labelId="hour-of-day"
        value={local}
        onChange={onChange}
        placeholder="Preferred Email Time"
        label="Preferred Email Time"
        input={<OutlinedInput label="Preferred Email Time" />}
      >
        <MenuItem value="00">Midnight</MenuItem>
        <MenuItem value="01">1am</MenuItem>
        <MenuItem value="02">2am</MenuItem>
        <MenuItem value="03">3am</MenuItem>
        <MenuItem value="04">4am</MenuItem>
        <MenuItem value="05">5am</MenuItem>
        <MenuItem value="06">6am</MenuItem>
        <MenuItem value="07">7am</MenuItem>
        <MenuItem value="08">8am</MenuItem>
        <MenuItem value="09">9am</MenuItem>
        <MenuItem value="10">10am</MenuItem>
        <MenuItem value="11">11am</MenuItem>
        <MenuItem value="12">noon</MenuItem>
        <MenuItem value="13">1pm</MenuItem>
        <MenuItem value="14">2pm</MenuItem>
        <MenuItem value="15">3pm</MenuItem>
        <MenuItem value="16">4pm</MenuItem>
        <MenuItem value="17">5pm</MenuItem>
        <MenuItem value="18">6pm</MenuItem>
        <MenuItem value="19">7pm</MenuItem>
        <MenuItem value="20">8pm</MenuItem>
        <MenuItem value="21">9pm</MenuItem>
        <MenuItem value="22">10pm</MenuItem>
        <MenuItem value="23">11pm</MenuItem>
      </Select>
      <FormHelperText>When we send you emails, at what hour do you prefer to receive them.</FormHelperText>
    </FormControl>
  );
};

export const NotificationsList = () => {
  const { data: profile, isLoading } = useUserProfile();

  if (isLoading) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (!profile) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <span>An error occurred, please refresh the page.</span>
        </Box>
      </Card>
    );
  }

  return (
    <Card elevation={1} square={false}>
      <List>
        <ListItem>
          <MarketingConsent initialValue={profile.notificationSettings.marketingConsent} />
        </ListItem>
        <ListItem>
          <SendDailyEmails
            initialValue={profile.notificationSettings.sendDailyEmails}
            hasTimezone={!!profile.location.timezone && profile.location.timezone.length > 0}
          />
        </ListItem>
        <ListItem>
          <TimeOfDayDropDown initialValue={profile.notificationSettings.whenToSendDailyEmails} />
        </ListItem>
      </List>
    </Card>
  );
};
