import { DateTime } from "luxon";

/**
 * @param {number} date
 */
export const formatTopicDate = (date = DateTime.now().toMillis()) => {
  return DateTime.fromMillis(date).toFormat("DD");
};

/**
 * @param {number} date
 */
export const formatMessageDate = (date = DateTime.now().toMillis()) => {
  return DateTime.fromMillis(date).toFormat("DD @ HH:mm:ss");
};

/**
 * @param {number} date
 */
export const isEdited = (date = DateTime.now().toMillis()) => {
  return DateTime.fromMillis(date).toFormat("DD @ HH:mm:ss");
};
