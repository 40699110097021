import React from "react";
import ListItemText from "@mui/material/ListItemText";
import { EditTextFieldDialog } from "./EditTextFieldDialog";

/**
 * @param {object} props
 * @param {string} props.primary
 * @param {string|number} props.secondary
 * @param {string} props.helperText
 * @param {(text: (string|number)) => Promise<void>} props.onSave
 * @param {boolean} props.isBusy
 * @param {(v: (string|number)) => string} [props.formatter]
 */
export const EditableListItemText = ({ primary, secondary, onSave, isBusy, helperText, formatter = (x) => x }) => {
  return (
    <EditTextFieldDialog
      onSave={onSave}
      title={primary}
      initialValue={secondary}
      isBusy={isBusy}
      helperText={helperText}
    >
      <ListItemText primary={primary} secondary={formatter(secondary) || "Click to edit."} />
    </EditTextFieldDialog>
  );
};
