import React from "react";
import { Settings } from "./Settings";
import { Path } from "../Path";
import { PublisherSettings } from "../publisher/PublisherSettings";
import { AppWithSideMenu } from "../core/AppWithSideMenu";
import { SettingsBreadcrumbs, SettingsCategoryBreadcrumbs } from "../core/Breadcrumbs";

export const routes = [
  {
    path: Path.settings().none(),
    element: <AppWithSideMenu DesignerView={Settings} PublisherView={PublisherSettings} />,
    handle: {
      crumb: SettingsBreadcrumbs,
      prefer: "left",
    },
  },
  {
    path: Path.settings().category(":category").pathname,
    element: <AppWithSideMenu DesignerView={Settings} PublisherView={PublisherSettings} />,
    handle: {
      crumb: SettingsCategoryBreadcrumbs,
      prefer: "right",
    },
  },
];
