import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useGamePublishers } from "@seabrookstudios/pitch2table";
import { PublisherName } from "../../users/UserName";

const Label = "Publisher";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId|undefined} props.value
 * @param {(value: import("@seabrookstudios/pitch2table-core").PublisherId) => void} props.onChange
 * @param {string} props.helperText
 * @param {boolean} [props.disabled]
 */
export const PublisherSelect = ({ gameId, value, onChange, helperText, disabled = false }) => {
  const { data: interest = [], isLoading } = useGamePublishers(gameId);

  /**
   * @param {*} ev
   */
  const onChangeHandler = (ev) => {
    onChange(ev.target.value);
  };

  return (
    <FormControl sx={{ width: "100%" }} margin="normal" disabled={disabled}>
      <InputLabel id="publisher">{Label}</InputLabel>
      <Select
        labelId="publisher"
        value={value}
        onChange={onChangeHandler}
        placeholder={Label}
        label={Label}
        input={<OutlinedInput label={Label} />}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          interest.map((publisherGame) => (
            <MenuItem key={publisherGame.publisherId} value={publisherGame.publisherId}>
              <PublisherName publisherId={publisherGame.publisherId} />
            </MenuItem>
          ))
        )}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
