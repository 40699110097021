import React from "react";
import { useGameTopics } from "@seabrookstudios/pitch2table";
import { EmptyMessageBoard } from "./EmptyMessageBoard";
import { useGameIdFromRoute } from "../games/WithGameIdFromRoute";
import { idExtractor, useMakeListAndDetail, useMakeListBuilder } from "../core/useMakeLayout";
import { TopicListEntry } from "./TopicListEntry";
import { AddTopicToGame } from "./AddTopic";
import { TopicFromRoute } from "./TopicFromRoute";
import { Path } from "../Path";

export const TopicListAndDetail = () => {
  const gameId = useGameIdFromRoute();

  const Action = () => <AddTopicToGame gameId={gameId} />;

  const useMakeList = useMakeListBuilder(
    TopicListEntry,
    idExtractor,
    "Message Board",
    EmptyMessageBoard,
    Action,
    undefined,
    Path.game(gameId).link()
  );

  const useWrappedQuery = () => useGameTopics(gameId);

  const ListAndDetail = useMakeListAndDetail(useWrappedQuery, [], "message-board", useMakeList, TopicFromRoute);

  return <ListAndDetail />;
};
