import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * @template T
 * @param {T[]} allItems
 * @param {Filter<T>[]} allFilters
 * @param {string} param when loading filter from query string, what key
 * @returns {Filters<T>}
 */
export const useFilters = (allItems, allFilters, param) => {
  /**
   * @type {[T[],React.Dispatch<React.SetStateAction<T[]>>]}
   */
  const [filtered, setFiltered] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [done, setDone] = useState(false);
  const [filter, setFilter] = useState(`${searchParams.get(param)}`.toUpperCase());
  const [visibleFilters, setVisibleFilters] = useState(allFilters);

  /**
   * @param {string} label
   */
  const onFilterClicked = (label) => {
    searchParams.set(param, label);
    setSearchParams(searchParams);
    setFilter(label);
  };

  useEffect(() => {
    if (allItems.length === 0) {
      return;
    }

    const appliedFilter = allFilters.find(({ label }) => label === filter);
    if (!appliedFilter) {
      setFiltered(allItems);
      return;
    }

    setFiltered(allItems.filter((t) => appliedFilter.filter(t)));
  }, [allItems, filter, allFilters]);

  useEffect(() => {
    if (allFilters.length === 0) {
      return;
    }

    const withCount = allFilters
      .map((filter) => ({
        ...filter,
        count: allItems.filter((t) => filter.filter(t)).length,
      }))
      .filter(({ count, label }) => count > 0 || label === "ALL");

    setVisibleFilters(withCount);

    if (done && !!searchParams.get(param)) {
      return;
    }
    if (done && !searchParams.get(param)) {
      const sorted = [...withCount];
      sorted.sort((a, b) => b.weight - a.weight);

      setFilter(sorted[0].label);
      searchParams.set(param, sorted[0].label);
      setSearchParams(searchParams);
    }

    if (!done && allItems.length > 0) {
      const sorted = [...withCount];
      sorted.sort((a, b) => b.weight - a.weight);

      if (!searchParams.get(param)) {
        setFilter(sorted[0].label);
        searchParams.set(param, sorted[0].label);
        setSearchParams(searchParams);
      }
      setDone(true);
    }
  }, [allItems, done, searchParams, param, allFilters, setSearchParams]);

  return {
    filtered,
    filter,
    visibleFilters: visibleFilters.length > 1 ? visibleFilters : [],
    onFilterClicked,
  };
};
