import React from "react";
import { NoItemsFound } from "../../publisher/NoItemsFound";
import Alert from "@mui/material/Alert";
import { useGameIdFromRoute } from "../WithGameIdFromRoute";
import { useWarnings, WarningBanner } from "./warnings";

export const NoContracts = () => {
  const gameId = useGameIdFromRoute();
  const warnings = useWarnings(gameId);

  if (warnings.length === 0) {
    return (
      <NoItemsFound>
        <Alert severity="info">
          After uploading your contracts, they will appear here. Always on hand when you need them.
        </Alert>
      </NoItemsFound>
    );
  }

  return <WarningBanner />;
};
