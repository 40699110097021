import React from "react";
import ReactQuill from "react-quill";
import "./editor.css";
import Typography from "@mui/material/Typography";
import { domToReact } from "html-react-parser";

export const Editor = ({ value, onChange }) => {
  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={onChange}
      modules={{
        toolbar: [
          [{ header: 1 }, { header: 2 }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          ["clean"],
        ],
        clipboard: {
          matchVisual: false,
        },
      }}
      formats={["header", "bold", "italic", "strike", "blockquote", "underline", "list", "bullet"]}
    />
  );
};

/**
 * @type {import("html-react-parser").HTMLReactParserOptions}
 */
export const options = {
  replace: ({ attribs, name, children }) => {
    if (!attribs) {
      return;
    }

    if (name === "h1") {
      return (
        <Typography variant="h5" {...attribs}>
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === "h2") {
      return (
        <Typography variant="h6" {...attribs}>
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === "p") {
      return <Typography {...attribs}>{domToReact(children, options)}</Typography>;
    }
  },
};
