import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { SideMenuOption } from "../core/SideMenuOption";
import CampaignIcon from "@mui/icons-material/Campaign";
import BugReport from "@mui/icons-material/BugReport";
import { Path } from "../Path";
import updates from "./updates.json";

export const UpdatesButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickHandler = () => {
    navigate(Path.updates);
  };

  return (
    <SideMenuOption
      label="Updates"
      tooltip="Keep up to date with the latest features"
      onClick={onClickHandler}
      selected={location.pathname.startsWith(Path.updates)}
    >
      <CampaignIcon />
    </SideMenuOption>
  );
};

export const UpdatesPage = () => {
  return (
    <Box p={2} width="100%" flex={1}>
      <Card>
        <Box p={2}>
          <Box pb={2}>
            <Typography textAlign="center" variant="h4">
              Release Notes
            </Typography>
          </Box>
          <Box pt={2} pb={2}>
            <List>
              {updates.map((month) => (
                <Box key={month.date}>
                  <ListItem>
                    <ListItemText primary={<Typography variant="h6">{month.date}</Typography>} />
                  </ListItem>
                  {month.updates.length > 0 && (
                    <>
                      <ListItem>
                        <ListItemIcon>
                          <CampaignIcon />
                        </ListItemIcon>
                        <ListItemText primary="Updates" />
                      </ListItem>
                      <ListItem>
                        <Stack direction="column">
                          {month.updates.map((update, i) => (
                            <ListItemText key={i} secondary={update} />
                          ))}
                        </Stack>
                      </ListItem>
                    </>
                  )}
                  {month.fixes.length > 0 && (
                    <>
                      <ListItem>
                        <ListItemIcon>
                          <BugReport />
                        </ListItemIcon>
                        <ListItemText primary="Bug Fixes" />
                      </ListItem>
                      <ListItem>
                        <Stack direction="column">
                          {month.fixes.map((update, i) => (
                            <ListItemText key={i} secondary={update} />
                          ))}
                        </Stack>
                      </ListItem>
                    </>
                  )}
                </Box>
              ))}
            </List>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};
