import React from "react";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardOutlined from "@mui/icons-material/ArrowForwardOutlined";
import { Link as RouterLink } from "react-router-dom";
import { Path } from "../../Path";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").Game} props.game
 */
export const RoyaltyCardForGame = ({ game }) => {
  return (
    <Card elevation={1} square={false}>
      <List>
        <ListItem>
          <ListItemText primary="Royalties" />
          <Link to={Path.game(game.id).royalties()} component={RouterLink}>
            <ListItemIcon>
              <ListItemButton>
                <ArrowForwardOutlined />
              </ListItemButton>
            </ListItemIcon>
          </Link>
        </ListItem>
      </List>
    </Card>
  );
};
