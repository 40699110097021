import React from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { RoyaltyTypeDescriptions, RoyaltyTypeLabels, RoyaltyTypes } from "@seabrookstudios/pitch2table-core";

const Label = "Royalty Type";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").RoyaltyType} props.value
 * @param {(value: import("@seabrookstudios/pitch2table-core").RoyaltyType) => void} props.onChange
 * @param {string} props.helperText
 * @param {boolean} [props.disabled]
 */
export const RoyaltyTypeSelect = ({ value, onChange, helperText, disabled = false }) => {
  /**
   * @param {*} ev
   */
  const onChangeHandler = (ev) => {
    onChange(ev.target.value);
  };

  return (
    <FormControl sx={{ width: "100%" }} margin="normal" disabled={disabled}>
      <InputLabel id="contract">{Label}</InputLabel>
      <Select
        labelId="contract"
        value={value}
        onChange={onChangeHandler}
        placeholder={Label}
        label={Label}
        input={<OutlinedInput label={Label} />}
      >
        {RoyaltyTypes.map((type) => (
          <MenuItem key={type} value={type}>
            {RoyaltyTypeLabels[type]} - {RoyaltyTypeDescriptions[type]}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
