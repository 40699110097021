import React from "react";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { useUserProfile } from "../user/queries";
import { useUserCurrencyFormatter } from "../games/royalties/currency-formatting";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { preferredCurrencySet } from "./preferredCurrencySet";
import { CurrencyNotice } from "../games/royalties/CurrencyNotice";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp[]} props.royalties
 */
export const RoyaltyTotals = ({ royalties }) => {
  const { data: profile } = useUserProfile();
  const formatUserCurrency = useUserCurrencyFormatter();

  const total = royalties.reduce((t, royalty) => t + royalty.convertedAmount, 0);
  const paid = royalties.filter((r) => r.paid).reduce((t, royalty) => t + royalty.convertedAmount, 0);

  const hasCurrencySet = preferredCurrencySet(profile);

  return (
    <Box pl={1} pr={1}>
      <List>
        <CurrencyNotice />
        {hasCurrencySet && (
          <Stack direction="row">
            <ListItem>
              <ListItemText primary="Total Royalties" secondary={formatUserCurrency(paid)} />
            </ListItem>
            {total !== paid && (
              <ListItem>
                <ListItemText primary="Total Royalties (including unpaid)" secondary={formatUserCurrency(total)} />
              </ListItem>
            )}
          </Stack>
        )}
      </List>
      <Divider />
    </Box>
  );
};
