import React, { useMemo } from "react";
import { useAllRoyaltiesByType, useAllRoyaltiesByYear } from "./queries";
import { RoyaltyTypeLabels } from "@seabrookstudios/pitch2table-core";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { royaltyToYear } from "./model";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

export const RoyaltyByTypeChart = () => {
  const { data: byType = [], isLoading } = useAllRoyaltiesByType();

  const data = useMemo(() => {
    return byType.map((royalties) => ({
      value: royalties.reduce((t, r) => t + r.convertedAmount, 0),
      label: RoyaltyTypeLabels[royalties[0].type],
    }));
  }, [byType]);

  return (
    <Box>
      <PieChart
        loading={isLoading}
        skipAnimation
        series={[
          {
            data,
          },
        ]}
        margin={{ bottom: 50, right: 0, left: 0, top: 0 }}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "bottom", horizontal: "middle" },
          },
        }}
        height={200}
      />
      <Divider />
    </Box>
  );
};

export const RoyaltyByYearChart = () => {
  const { data: byYear = [], isLoading } = useAllRoyaltiesByYear();

  const data = useMemo(() => byYear.map((royalties) => royalties.reduce((t, r) => t + r.convertedAmount, 0)), [byYear]);

  return (
    <Box>
      <BarChart
        loading={isLoading}
        skipAnimation
        xAxis={[
          {
            scaleType: "band",
            data: byYear.map((royalties) => royaltyToYear(royalties[0])),
          },
        ]}
        series={[
          {
            data,
          },
        ]}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "bottom", horizontal: "middle" },
          },
        }}
        height={200}
      />
      <Divider />
    </Box>
  );
};
