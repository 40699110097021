import React, { useContext } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { Path } from "../Path";
import { AccountContext } from "../core/AccountProvider";
import { addGameToPublisher } from "../publisher/service";
import { useQueryClient } from "@tanstack/react-query";
import { AddIsInQueue } from "./AddIsInQueue";
import { PublisherSubmissionsByPublisherId } from "./query-keys";
import { CachedTokenContext } from "@seabrookstudios/auth";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {boolean} props.isInQueue
 */
const AddToSubmissionsButtonBase = ({ gameId, isInQueue }) => {
  const token = useContext(CachedTokenContext);
  const isAuthenticated = token !== "";
  const navigate = useNavigate();
  const { mode, publisherId } = useContext(AccountContext);

  const queryClient = useQueryClient();

  const onClickHandler = async () => {
    if (!isAuthenticated) {
      navigate(Path.Landing.AddToSubmissions(gameId));
      return;
    }
    if (mode !== "Publisher" || !publisherId) {
      navigate(Path.Landing.AddToSubmissions(gameId));
      return;
    }
    if (isInQueue) {
      return;
    }

    await addGameToPublisher(token, publisherId, gameId);
    queryClient.invalidateQueries({ queryKey: PublisherSubmissionsByPublisherId(publisherId) });
  };

  return (
    <Button variant="contained" onClick={onClickHandler}>
      {isInQueue ? "View Game In Queue" : "Add to Submissions"}
    </Button>
  );
};

export const AddToSubmissionsButton = ({ gameId }) => (
  <AddIsInQueue gameId={gameId}>
    <AddToSubmissionsButtonBase gameId={gameId} isInQueue={false} />
  </AddIsInQueue>
);
