import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { UserName } from "../../users/UserName";
import { UserAvatar } from "../../users/UserAvatar";
import { PublisherLogoSquare } from "../../publisher/PublisherLogo";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").UserId} props.userId
 * @param {import("@seabrookstudios/pitch2table-core").DesignerPublisherGameView} props.publisherGame
 * @param {import("@seabrookstudios/types").UserId[]} props.publisherContactUserIds
 * @param {import('@seabrookstudios/types').UserId|'publisher'} props.value
 * @param {Function} props.onChange
 * @param {boolean} [props.disabled]
 */
export const PublisherUpdateFromSelect = ({
  userId,
  disabled = false,
  publisherGame,
  publisherContactUserIds = [],
  value,
  onChange,
}) => {
  /**
   * @param {*} ev
   */
  const onChangeHandler = (ev) => {
    if (onChange) {
      onChange(ev.target.value);
    }
  };

  return (
    <FormControl sx={{ width: "100%" }} margin="normal" disabled={disabled}>
      <InputLabel id="publisher-update-from">From</InputLabel>
      <Select
        labelId="publisher-update-from"
        value={value}
        onChange={onChangeHandler}
        placeholder="from"
        label="Frmo"
        input={<OutlinedInput label="From" />}
      >
        <MenuItem value="publisher">
          <ListItem>
            <ListItemIcon>
              <PublisherLogoSquare publisher={publisherGame} />
            </ListItemIcon>
            <ListItemText primary="Publisher" />
          </ListItem>
        </MenuItem>
        {publisherContactUserIds.map((pUserId) => (
          <ListItem>
            <ListItemIcon>
              <UserAvatar userId={pUserId} />
            </ListItemIcon>
            <ListItemText primary={<UserName userId={pUserId} />} />
          </ListItem>
        ))}
        <MenuItem value={userId}>
          <ListItem>
            <ListItemIcon>
              <UserAvatar userId={userId} />
            </ListItemIcon>
            <ListItemText primary={<UserName userId={userId} />} />
          </ListItem>
        </MenuItem>
      </Select>
    </FormControl>
  );
};
