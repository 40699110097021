import { formatFullDate } from "@seabrookstudios/pitch2table-core";
import { isCoDesign } from "../model";

/**
 * @type {import("@seabrookstudios/pitch2table-core").DesignerGameStatus[]}
 */
export const NeedContracts = ["Signed", "Published"];

/**
 * @type {import("@seabrookstudios/pitch2table-core").DesignerGameStatus[]}
 */
export const ShowContracts = ["PitchingNow", "Signed", "Published"];

/**
 * @param {import("@seabrookstudios/pitch2table-core").Game} game
 */
export const showContracts = (game) => {
  return ShowContracts.includes(game.status) || isCoDesign(game);
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").Game} game
 * @param {import("@seabrookstudios/pitch2table-core").Contract[]} contracts
 */
const isMissingSignedContract = (game, contracts) => {
  if (!NeedContracts.includes(game.status)) {
    return false;
  }

  const hasPublisherAgreement = contracts.filter((c) => c.type === "designer-publisher");

  return hasPublisherAgreement.length === 0;
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").Game} game
 * @param {import("@seabrookstudios/pitch2table-core").Contract[]} contracts
 */
const isMissingCoDesignContract = (game, contracts) => {
  const hasCoDesignerAgreement = contracts.filter((c) => c.type === "co-designer");

  return isCoDesign(game) && hasCoDesignerAgreement.length === 0;
};

/**
 * @typedef {object} Warning
 * @property {string} text
 * @property {(game: import("@seabrookstudios/pitch2table-core").Game, contracts: import("@seabrookstudios/pitch2table-core").Contract[]) => boolean} rule
 */

export const Warnings = [
  {
    text: "Missing co-designer agreement. This agreement should outline who owns the created IP, how any revenue is split, how costs are paid, etc.",
    rule: isMissingCoDesignContract,
  },
  {
    text: "No contract uploaded. It's not signed unless there is a contract.",
    rule: isMissingSignedContract,
  },
];

/**
 * @param {import("@seabrookstudios/pitch2table-core").Contract} contract
 */
export const formatContractDescription = (contract) => {
  const name = contract.name.length === 0 ? "No name" : contract.name;
  const signed = !contract.signedDate ? "No signed date" : `Signed: ${formatFullDate(contract.signedDate)}`;

  return `${name} - ${signed}`;
};
