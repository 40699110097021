import React from "react";
import numeral from "numeral";
import { useGameIdFromRoute } from "../WithGameIdFromRoute";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  useComponentConfiguration,
  useComponentQuotes,
  useGameComponents,
  useGameLanes,
  useLane,
  useLaneQuotes,
} from "../components/Concept";
import usd from "./rates.json";

/**
 * @type {Record<import("../components/fake-data").Currency, string>}
 */
const format = {
  USD: "en-US",
  AUD: "en-AU",
  EUR: "en-DE",
  GBP: "en-UK",
  INR: "en-IN",
};

/**
 * @param {number} amount
 * @param {import("../components/fake-data").Currency} currency
 * @returns
 */
const formatCurrency = (amount, currency) => {
  return new Intl.NumberFormat(format[currency], {
    style: "currency",
    currencyDisplay: "code",
    currency,
    roundingMode: currency === "INR" ? "ceil" : "halfExpand",
    trailingZeroDisplay: currency === "INR" ? "stripIfInteger" : "auto",
  }).format(amount);
};

/**
 * @param {number} amount
 * @param {import("../components/fake-data").Currency} from
 * @param {import("../components/fake-data").Currency} to
 * @returns {number}
 */
const convertToCurrency = (amount, from, to) => {
  if (from === to) {
    return amount;
  }
  if (from !== "USD") {
    return -10000;
  }

  return amount * usd.rates[to];
};

const LaneFreightQuote = ({ laneId }) => {
  const { data: lane } = useLane(laneId);
  const { data: quotes = [] } = useLaneQuotes(laneId);
  const cheapest = quotes[0];

  if (!lane || !cheapest) {
    return <TableCell variant="head" align="right"></TableCell>;
  }

  const amount = cheapest.amount / lane.units;

  return (
    <TableCell variant="head" align="right">
      {formatCurrency(convertToCurrency(amount, cheapest.currency, lane.currency), lane.currency)}
    </TableCell>
  );
};

const Royalty = ({ laneId }) => {
  const { data: lane } = useLane(laneId);

  if (!lane) {
    return <TableCell variant="head" align="right"></TableCell>;
  }

  const amount = lane.msrp * 0.1;

  return (
    <TableCell variant="head" align="right">
      {formatCurrency(convertToCurrency(amount, lane.currency, lane.currency), lane.currency)}
    </TableCell>
  );
};

const LaneRoyalties = ({ laneId }) => {
  const { data: lane } = useLane(laneId);

  if (!lane) {
    return <TableCell variant="head" align="right"></TableCell>;
  }

  const amount = lane.msrp * 0.1 * lane.units;

  return (
    <TableCell variant="head" align="right">
      {formatCurrency(convertToCurrency(amount, lane.currency, lane.currency), lane.currency)}
    </TableCell>
  );
};

const KickStarterFees = ({ laneId }) => {
  const { data: lane } = useLane(laneId);

  if (!lane) {
    return <TableCell variant="head" align="right"></TableCell>;
  }

  const amount = lane.msrp * 0.11 * lane.units;

  return (
    <TableCell variant="head" align="right">
      {formatCurrency(convertToCurrency(amount, lane.currency, lane.currency), lane.currency)}
    </TableCell>
  );
};

const LandedPrice = ({ configurationId, quoteId, laneId }) => {
  const { data: configuration } = useComponentConfiguration(configurationId);
  const { data: printQuotes = [] } = useComponentQuotes(configurationId);
  const printQuote = printQuotes.find((q) => q.id === quoteId);
  const { data: lane } = useLane(laneId);
  const { data: freightQuotes = [] } = useLaneQuotes(laneId);
  const cheapest = freightQuotes[0];

  if (!configuration || !printQuote || !lane || !cheapest) {
    return <TableCell variant="head" align="right"></TableCell>;
  }

  const cpu = printQuote.costPerUnit;
  const freight = cheapest.amount / lane.units;
  const landed = cpu + freight;

  return (
    <TableCell variant="head" align="right">
      {formatCurrency(convertToCurrency(landed, cheapest.currency, lane.currency), lane.currency)}
    </TableCell>
  );
};

const LandedPriceProportion = ({ configurationId, quoteId, laneId }) => {
  const { data: configuration } = useComponentConfiguration(configurationId);
  const { data: printQuotes = [] } = useComponentQuotes(configurationId);
  const printQuote = printQuotes.find((q) => q.id === quoteId);
  const { data: lane } = useLane(laneId);
  const { data: freightQuotes = [] } = useLaneQuotes(laneId);
  const cheapest = freightQuotes[0];

  if (!configuration || !printQuote || !lane || !cheapest) {
    return <TableCell variant="head" align="right"></TableCell>;
  }

  const cpu = printQuote.costPerUnit;
  const freight = cheapest.amount / lane.units;
  const landed = cpu + freight;
  const converted = convertToCurrency(landed, cheapest.currency, lane.currency);

  return (
    <TableCell variant="head" align="right">
      {numeral(converted / lane.msrp).format("%")}
    </TableCell>
  );
};

/**
 *
 * @param {number} cpu
 * @param {number} units
 * @param {number} freight
 */
const calcOutlay = (cpu, units, freight) => {
  const printCost = cpu * units;
  const outlay = printCost + freight;
  return outlay;
};

const QuotedOutlay = ({ configurationId, quoteId, laneId }) => {
  const { data: configuration } = useComponentConfiguration(configurationId);
  const { data: printQuotes = [] } = useComponentQuotes(configurationId);
  const printQuote = printQuotes.find((q) => q.id === quoteId);
  const { data: lane } = useLane(laneId);
  const { data: freightQuotes = [] } = useLaneQuotes(laneId);
  const cheapest = freightQuotes[0];

  if (!configuration || !printQuote || !lane || !cheapest) {
    return <TableCell variant="head" align="right"></TableCell>;
  }

  const outlay = calcOutlay(printQuote.costPerUnit, lane.units, cheapest.amount);

  return (
    <TableCell variant="head" align="right">
      {formatCurrency(convertToCurrency(outlay, cheapest.currency, lane.currency), lane.currency)}
    </TableCell>
  );
};

const MaximumRevenue = ({ laneId }) => {
  const { data: lane } = useLane(laneId);

  if (!lane) {
    return <TableCell variant="head" align="right"></TableCell>;
  }

  const revenue = lane.units * lane.msrp;

  return (
    <TableCell variant="head" align="right">
      {formatCurrency(convertToCurrency(revenue, lane.currency, lane.currency), lane.currency)}
    </TableCell>
  );
};

const FinancialPosition = ({ configurationId, quoteId, laneId }) => {
  const { data: configuration } = useComponentConfiguration(configurationId);
  const { data: printQuotes = [] } = useComponentQuotes(configurationId);
  const printQuote = printQuotes.find((q) => q.id === quoteId);
  const { data: lane } = useLane(laneId);
  const { data: freightQuotes = [] } = useLaneQuotes(laneId);
  const cheapest = freightQuotes[0];

  if (!configuration || !printQuote || !lane || !cheapest) {
    return <TableCell variant="head" align="right"></TableCell>;
  }

  const outlay = calcOutlay(printQuote.costPerUnit, lane.units, cheapest.amount);
  const convertedOutlay = convertToCurrency(outlay, cheapest.currency, lane.currency);
  const royalties = convertToCurrency(lane.msrp * 0.1 * lane.units, lane.currency, lane.currency);
  const ksFees = convertToCurrency(lane.msrp * 0.11 * lane.units, lane.currency, lane.currency);
  const expenses = convertedOutlay + royalties + ksFees;

  const revenue = lane.units * lane.msrp;
  const convertedRevenue = convertToCurrency(revenue, lane.currency, lane.currency);

  const position = convertedRevenue - expenses;

  return (
    <TableCell variant="head" align="right">
      {formatCurrency(position, lane.currency)}
    </TableCell>
  );
};

export const FinancialModelling = () => {
  const gameId = useGameIdFromRoute();

  const { data: configurations = [] } = useGameComponents(gameId);

  const configurationId = "1af1ef69-a452-4c61-b3c8-08c69c11ce75";

  const { data: configuration } = useComponentConfiguration(configurationId);
  const { data: lanes = [] } = useGameLanes(gameId);
  const { data: quotes = [] } = useComponentQuotes(configurationId);

  const quoteId = quotes.find((q) => q.componentConfigurationId === configurationId && q.qty === 1000)?.id;

  const quote = quotes.find((q) => q.id === quoteId);

  const combos = configurations
    .filter((configuration) => configuration.id === configurationId)
    .map((configuration) => {
      return lanes.map((lane) => ({
        configurationId: configuration.id,
        configuration,
        laneId: lane.id,
        lane,
      }));
    })
    .reduce((acc, v) => [...acc, ...v], []);

  return (
    <Box p={2} width="100%" flex={1}>
      <Card elevation={1} square={false}>
        <List>
          <ListItem>
            <ListItemText primary="Component Configuration" secondary={configuration ? configuration.name : ""} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Printing Quote" secondary={quote ? quote.printerId : ""} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Print Run Size" secondary={quote ? quote.qty : ""} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Freight Quote" secondary="Cheapest" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Designer Royalty" secondary="10% (of MSRP)" />
          </ListItem>
        </List>
      </Card>
      <Box p={1} />
      <Card elevation={1} square={false}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell variant="head" align="right">
                  Lane
                </TableCell>
                {combos.map((combo) => (
                  <TableCell variant="head" align="right">
                    {combo.lane.to}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell variant="head" align="right">
                  Units
                </TableCell>
                {combos.map((combo) => (
                  <TableCell variant="head" align="right">
                    {combo.lane.units}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell variant="head" align="right">
                  MSRP
                </TableCell>
                {combos.map((combo) => (
                  <TableCell variant="head" align="right">
                    {formatCurrency(combo.lane.msrp, combo.lane.currency)}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell variant="head" align="right">
                  CPU
                </TableCell>
                {combos.map((combo) => (
                  <TableCell align="right">
                    {quote
                      ? formatCurrency(
                          convertToCurrency(quote.costPerUnit, quote.currency, combo.lane.currency),
                          combo.lane.currency
                        )
                      : ""}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell variant="head" align="right">
                  Freight
                </TableCell>
                {combos.map((combo) => (
                  <LaneFreightQuote laneId={combo.laneId} />
                ))}
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell variant="head" align="right">
                  Landed Price
                </TableCell>
                {combos.map((combo) => (
                  <LandedPrice configurationId={configurationId} quoteId={quoteId} laneId={combo.laneId} />
                ))}
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell variant="head" align="right">
                  Royalty
                </TableCell>
                {combos.map((combo) => (
                  <Royalty laneId={combo.laneId} />
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Box p={1} />
      <Card elevation={1} square={false}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell variant="head" align="right">
                  Lane
                </TableCell>
                {combos.map((combo) => (
                  <TableCell variant="head" align="right">
                    {combo.lane.to}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell variant="head" align="right">
                  Outlay
                </TableCell>
                {combos.map((combo) => (
                  <QuotedOutlay configurationId={configurationId} quoteId={quoteId} laneId={combo.laneId} />
                ))}
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell variant="head" align="right">
                  Royalties
                </TableCell>
                {combos.map((combo) => (
                  <LaneRoyalties laneId={combo.laneId} />
                ))}
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell variant="head" align="right">
                  KickStarter Fees
                </TableCell>
                {combos.map((combo) => (
                  <KickStarterFees laneId={combo.laneId} />
                ))}
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell variant="head" align="right">
                  Maximum Revenue
                </TableCell>
                {combos.map((combo) => (
                  <MaximumRevenue laneId={combo.laneId} />
                ))}
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell variant="head" align="right">
                  Financial Position
                </TableCell>
                {combos.map((combo) => (
                  <FinancialPosition configurationId={configurationId} quoteId={quoteId} laneId={combo.laneId} />
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Box p={1} />
      <Card elevation={1} square={false}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell variant="head" align="right">
                  Lane
                </TableCell>
                {combos.map((combo) => (
                  <TableCell variant="head" align="right">
                    {combo.lane.to}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell variant="head" align="right">
                  MSRP
                </TableCell>
                {combos.map((combo) => (
                  <TableCell variant="head" align="right">
                    {formatCurrency(combo.lane.msrp, combo.lane.currency)}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell variant="head" align="right">
                  Designer Royalty
                </TableCell>
                <TableCell variant="head" align="right">
                  10%
                </TableCell>
                <TableCell variant="head" align="right">
                  10%
                </TableCell>
                <TableCell variant="head" align="right">
                  10%
                </TableCell>
                <TableCell variant="head" align="right">
                  10%
                </TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell variant="head" align="right">
                  Channel Fees
                </TableCell>
                <TableCell variant="head" align="right">
                  11%
                </TableCell>
                <TableCell variant="head" align="right">
                  11%
                </TableCell>
                <TableCell variant="head" align="right">
                  11%
                </TableCell>
                <TableCell variant="head" align="right">
                  11%
                </TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell variant="head" align="right">
                  Landed Price
                </TableCell>
                {combos.map((combo) => (
                  <LandedPriceProportion configurationId={configurationId} quoteId={quoteId} laneId={combo.laneId} />
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
};
