import React from "react";
import numeral from "numeral";
import { Path } from "../../Path";
import { useGameIdFromRoute } from "../WithGameIdFromRoute";
import { useQuery } from "@tanstack/react-query";
import { NoItemsFound } from "../../publisher/NoItemsFound";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ListView } from "../../core/ListView";
import { useNavigate, useParams } from "react-router-dom";
import { ListDetail } from "../../web/ListDetail";
import { EmptyDetailCard } from "../../core/EmptyDetailCard";
import { ArrowListItem } from "../../core/ArrowListItem";
import { componentConfigurations, freightQuotes, lanes, quotes } from "./fake-data";

/**
 * @param {number} qty
 * @param {import("./fake-data").QtyType} qtyType
 */
const formatQuantity = (qty, qtyType) => {
  if (qty === 1) {
    return `${qty} ${qtyType}`;
  }

  return `${qty} ${qtyType}s`;
};

export const useGameComponents = (gameId) => {
  return useQuery({
    queryKey: ["game-components", gameId],
    /**
     * @returns {import("./fake-data").ComponentConfiguration[]}
     */
    queryFn: () => {
      return componentConfigurations;
    },
  });
};

export const useGameLanes = (gameId) => {
  return useQuery({
    queryKey: ["game-lanes", gameId],
    /**
     * @returns {import("./fake-data").Lane[]}
     */
    queryFn: () => {
      return lanes;
    },
  });
};

export const useComponentConfiguration = (id) => {
  return useQuery({
    queryKey: ["component-configuration", id],
    /**
     * @returns {import("./fake-data").ComponentConfiguration|undefined}
     */
    queryFn: () => {
      return componentConfigurations.find((c) => c.id === id);
    },
  });
};

export const useLane = (id) => {
  return useQuery({
    queryKey: ["lane", id],
    /**
     * @returns {import("./fake-data").Lane|undefined}
     */
    queryFn: () => {
      return lanes.find((c) => c.id === id);
    },
  });
};

export const useComponentQuotes = (componentId) => {
  return useQuery({
    queryKey: ["component-configuration-quotes", componentId],
    /**
     * @returns {import("./fake-data").Quote[]}
     */
    queryFn: () => {
      const data = quotes.filter((quote) => quote.componentConfigurationId === componentId);

      data.sort((a, b) => a.costPerUnit - b.costPerUnit);

      return data;
    },
  });
};

export const useLaneQuotes = (laneId) => {
  return useQuery({
    queryKey: ["lane-quotes", laneId],
    /**
     * @returns {import("./fake-data").FreightQuote[]}
     */
    queryFn: () => {
      const data = freightQuotes.filter((quote) => quote.lane === laneId);

      data.sort((a, b) => a.amount - b.amount);

      return data;
    },
  });
};

const ComponentConfigurationFromRoute = () => {
  const { gameId, configurationId } = useParams();

  const { data: configuration } = useComponentConfiguration(configurationId);
  const { data: quotes = [] } = useComponentQuotes(configurationId);

  if (!configurationId || !configuration) {
    return <EmptyDetailCard text="Select a component configuration from the list on the left." />;
  }

  return (
    <Box>
      <Card elevation={1} square={false}>
        <List>
          <ListItem>
            <ListItemText primary="Name" secondary={configuration.name} />
          </ListItem>
        </List>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head" align="right">
                  Name
                </TableCell>
                <TableCell variant="head" align="right">
                  Qty
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {configuration.components.map((component) => (
                <TableRow key={component.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="right">{component.name}</TableCell>
                  <TableCell align="right">{formatQuantity(component.qty, component.qtyType)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Box p={1} />
      <Card elevation={1} square={false}>
        <List>
          <ArrowListItem
            primary="Quotes"
            secondary={`${quotes.length} ${quotes.length === 1 ? "quote" : "quotes"} found.`}
            to={Path.game(gameId).component(configurationId).quotes()}
          />
        </List>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head" align="left">
                  Printer
                </TableCell>
                <TableCell variant="head" align="center">
                  Terms
                </TableCell>
                <TableCell variant="head" align="right">
                  Qty
                </TableCell>
                <TableCell variant="head" align="right">
                  CPU
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quotes.map((quote) => (
                <TableRow key={quote.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="left">{quote.printerId}</TableCell>
                  <TableCell align="center">{quote.terms}</TableCell>
                  <TableCell align="right">{numeral(quote.qty).format("0,000")}</TableCell>
                  <TableCell align="right">
                    {numeral(quote.costPerUnit).format("0,0.00")} {quote.currency}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
};

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("./fake-data").ComponentConfiguration} props.componentConfiguration
 */
const ListEntry = ({ gameId, componentConfiguration }) => {
  const { configurationId } = useParams();
  const navigate = useNavigate();

  const selected = configurationId === componentConfiguration.id;

  const onClickHandler = () => {
    navigate(Path.game(gameId).component(componentConfiguration.id).link());
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemText
        primary={componentConfiguration.name}
        secondary={`${componentConfiguration.components.length} parts`}
      />
    </ListItemButton>
  );
};

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("./fake-data").ComponentConfiguration[]} props.components
 * @param {import("react-router-dom").To} props.back
 * @param {Function} [props.refetch]
 * @param {boolean} [props.isLoading]
 * @param {boolean} [props.isRefetching]
 */
const ListPage = ({ gameId, components = [], back, refetch, isLoading, isRefetching }) => {
  return (
    <ListView
      title="Component Configuration"
      Filters={null}
      Action={null}
      back={back}
      EmptyList={() => (
        <NoItemsFound>
          <Alert severity="info">
            Use component configurations to explore how different pieces affect your overall profitability. Create a
            configuration of components and then request manufacturing quotes at different volume runs. Combine these
            and shipping quotes in the financial modelling module.
          </Alert>
        </NoItemsFound>
      )}
      shown={components.length}
      refetch={refetch}
      isLoading={isLoading}
      isRefetching={isRefetching}
    >
      {components.map((contract) => (
        <ListEntry gameId={gameId} key={contract.id.toString()} componentConfiguration={contract} />
      ))}
    </ListView>
  );
};

export const ListAndDetail = () => {
  const gameId = useGameIdFromRoute();
  const { data: components = [], refetch } = useGameComponents(gameId);

  const SelectedSellsheet = () => <ComponentConfigurationFromRoute />;

  const ListView = () => (
    <ListPage gameId={gameId} components={components} back={Path.game(gameId).link()} refetch={refetch} />
  );

  return <ListDetail ListView={ListView} DetailView={SelectedSellsheet} />;
};
