import React from "react";
import { useGameTopic, useTopicMessages, useUpdateGameTopic } from "@seabrookstudios/pitch2table";
import Card from "@mui/material/Card";
import { useGameIdFromRoute, useTopicIdFromRoute } from "../games/WithGameIdFromRoute";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import Skeleton from "@mui/material/Skeleton";
import { formatMessageDate, formatTopicDate } from "./model";
import parse from "html-react-parser";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import { EditableCardHeader } from "../games/EditableHeading";
import { AddMessageToTopic, EditMessage } from "./AddTopic";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { UserAvatar } from "../users/UserAvatar";
import ListItemText from "@mui/material/ListItemText";
import { options } from "../core/Editor";
import { useUserProfile } from "../user/queries";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Refresh from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import { TopicTypeSelect } from "./TopicTypeSelect";
import { useDocumentTitle } from "usehooks-ts";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").Message} props.message
 * @param {boolean} props.isFirst
 */
const Message = ({ message, isFirst }) => {
  const { data: profile } = useUserProfile();
  const canEditMessage = profile ? profile.id === message.lastModifiedByUserId : false;

  return (
    <Box>
      {!isFirst && (
        <Box p={4}>
          <Divider />
        </Box>
      )}
      <List>
        <ListItem
          secondaryAction={
            <EditMessage
              disabled={canEditMessage}
              initialBody={message.body}
              messageId={message.id}
              topicId={message.topicId}
              deleted={message.deleted}
            />
          }
        >
          <ListItemAvatar>
            <UserAvatar userId={message.lastModifiedByUserId} />
          </ListItemAvatar>
          <ListItemText
            primary={formatMessageDate(message.created)}
            secondary={message.created !== message.updated ? "Edited" : ""}
          />
        </ListItem>
      </List>
      {message.deleted ? (
        <Typography color="text.secondary" style={{ fontStyle: "italic" }}>
          {parse(message.body, options)}
        </Typography>
      ) : (
        parse(message.body, options)
      )}
    </Box>
  );
};

export const TopicFromRoute = () => {
  const gameId = useGameIdFromRoute();
  const topicId = useTopicIdFromRoute();
  const { data: topic } = useGameTopic(gameId, topicId);
  const { data: messages = [], refetch, isRefetching } = useTopicMessages(topicId);
  const updateGameTopic = useUpdateGameTopic(gameId, topicId);
  const { data: profile } = useUserProfile();

  useDocumentTitle(topic ? topic.name : "Pitch2Table");

  if (!topicId) {
    return <EmptyDetailCard text="Select a topic from the list on the left." />;
  }

  /**
   * @param {string} name
   */
  const updateTitle = async (name) => {
    await updateGameTopic.mutateAsync({
      name,
      type: undefined,
      archived: undefined,
    });
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").TopicType} type
   */
  const updateType = async (type) => {
    await updateGameTopic.mutateAsync({
      name: undefined,
      type,
      archived: undefined,
    });
  };

  const canEditTopic = profile && topic && profile.id === topic.lastModifiedByUserId;

  return (
    <Box>
      <Card elevation={1} square={false}>
        <Box display="flex">
          <Box flex={1}>
            {topic ? (
              <EditableCardHeader
                text={topic.name}
                subheader={topic ? formatTopicDate(topic.created) : <Skeleton />}
                helperText=""
                title=""
                onSave={updateTitle}
                disabled={!canEditTopic}
              />
            ) : (
              <Skeleton />
            )}
          </Box>
          <Box flex={0}>
            <IconButton onClick={refetch} style={{ justifyContent: "center", alignItems: "center" }}>
              {isRefetching ? <CircularProgress size={16} /> : <Refresh />}
            </IconButton>
          </Box>
        </Box>
        <Box pl={2} pr={2}>
          <TopicTypeSelect
            disabled={!topic}
            value={topic ? topic.type : "General"}
            onChange={updateType}
            helperText="A broad category for this topic"
            isPending={updateGameTopic.isPending}
          />
        </Box>
        <CardContent>
          {messages.map((message, i) => (
            <Message key={message.id} message={message} isFirst={i === 0} />
          ))}
        </CardContent>
      </Card>
      <Box p={1} />
      <AddMessageToTopic name={topic ? topic.name : ""} topicId={topicId} />
    </Box>
  );
};
