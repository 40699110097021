const { DateTime } = require("luxon");

/**
 * @typedef {'USD'|'AUD'|'EUR'|'GBP'|'INR'} Currency
 */

/**
 * @typedef {'piece'|'page'|'sheet'|'card'|'unit'} QtyType
 */

/**
 * @typedef {object} Components
 * @property {string} id
 * @property {number} qty
 * @property {QtyType} qtyType
 * @property {string} name
 */

/**
 * @typedef {object} Dimensions
 * @property {number} length
 * @property {number} width
 * @property {number} height
 * @property {"mm"|"in"} units
 */

/**
 * @typedef {object} ComponentConfiguration
 * @property {string} id
 * @property {string} name
 * @property {Components[]} components
 * @property {number|undefined} actualWeightInGrams
 * @property {number|undefined} estimatedWeightInGrams
 * @property {number} cartonCount how many units per carton
 * @property {Dimensions|undefined} cartonDimensions how big is each carton
 */

/**
 * @typedef {object} Quote
 * @property {string} id unique id for quote.
 * @property {string} componentConfigurationId id of component configuration the quote was for
 * @property {string} printerId id of printer
 * @property {number} qty how many units
 * @property {"EXW"|"FOB"} terms EX-Works, or Freight On Board
 * @property {number} costPerUnit total cost per unit for the quote
 * @property {Currency} currency what currency is this quote in
 * @property {number} timestamp when was the quote created
 * @property {number} expiry when does this quote expire?
 */

/**
 * @typedef {object} Lane
 * @property {string} id unique id for shipping lane.
 * @property {string} to where is this lane going to?
 * @property {number} units how many units
 * @property {string} pallet
 * @property {boolean} lessThanContainerLoad
 * @property {Currency} currency what current should the lane be calculated in
 * @property {number} msrp What is the MSRP for this lane
 */

/**
 * @typedef {object} FreightQuote
 * @property {string} id unique id for quote.
 * @property {string} lane id of lane that this quote is for
 * @property {string} source where did the quote come from e.g. FreightOS
 * @property {number} amount
 * @property {Currency} currency what currency is this quote in
 * @property {"Ocean"|"Air"|"Express"} method
 * @property {number} minDays
 * @property {number} maxDays
 * @property {number} timestamp when was the quote created
 * @property {number} expiry when does this quote expire?
 */

/**
 * @type {ComponentConfiguration[]}
 */
export const componentConfigurations = [
  {
    id: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
    name: "50 sheets, double-sided",
    components: [
      {
        id: "W01",
        qty: 1,
        name: "Retail Box",
        qtyType: "piece",
      },
      {
        id: "W02",
        qty: 1,
        name: "Tray",
        qtyType: "piece",
      },
      {
        id: "W03",
        qty: 1,
        name: "Rules",
        qtyType: "page",
      },
      {
        id: "W04",
        qty: 50,
        name: "Note Pad",
        qtyType: "sheet",
      },
      {
        id: "W05",
        qty: 55,
        name: "Cards",
        qtyType: "card",
      },
      {
        id: "W06",
        qty: 29,
        name: "Cards",
        qtyType: "card",
      },
      {
        id: "W07",
        qty: 6,
        name: "Poker Chips",
        qtyType: "piece",
      },
      {
        id: "W08",
        qty: 1,
        name: "Assembly & Carton",
        qtyType: "piece",
      },
    ],
    actualWeightInGrams: 463,
    estimatedWeightInGrams: 500,
    cartonCount: 12,
    cartonDimensions: {
      length: 290,
      width: 285,
      height: 200,
      units: "mm",
    },
  },
  {
    id: "ltt-base",
    name: "All LTT",
    components: [
      {
        id: "LTT01",
        qty: 1,
        name: "LTT Medium Box",
        qtyType: "page",
      },
      {
        id: "LTT02",
        qty: 1,
        name: "Rules",
        qtyType: "page",
      },
      {
        id: "LTT03",
        qty: 50,
        name: "Note Pad",
        qtyType: "sheet",
      },
      {
        id: "LTT04",
        qty: 54,
        name: "Euro Cards",
        qtyType: "card",
      },
      {
        id: "LTT05",
        qty: 29,
        name: "Tarot Cards",
        qtyType: "card",
      },
      {
        id: "LTT06",
        qty: 6,
        name: "Poker Chips",
        qtyType: "piece",
      },
    ],
    actualWeightInGrams: 0,
    estimatedWeightInGrams: 500,
    cartonCount: 0,
    cartonDimensions: undefined,
  },
  {
    id: "4524c3c3-eee3-451f-a92d-045ca2d35349",
    name: "100 sheets, single-sided",
    components: [
      { id: "W01", qty: 1, name: "Retail Box", qtyType: "piece" },
      { id: "W02", qty: 1, name: "Tray", qtyType: "piece" },
      { id: "W03", qty: 1, name: "Rules", qtyType: "page" },
      { id: "W04", qty: 100, name: "Note Pad", qtyType: "sheet" },
      { id: "W05", qty: 55, name: "Cards", qtyType: "card" },
      { id: "W06", qty: 29, name: "Cards", qtyType: "card" },
      { id: "W07", qty: 6, name: "Poker Chips", qtyType: "piece" },
      { id: "W08", qty: 1, name: "Assembly & Carton", qtyType: "piece" },
    ],
    actualWeightInGrams: undefined,
    estimatedWeightInGrams: 500,
    cartonCount: 12,
    cartonDimensions: undefined,
  },
  {
    id: "ab18b65e-8968-473e-8e29-a479817515c0",
    name: "no tarot, no tray",
    components: [
      { id: "W01", qty: 1, name: "Retail Box", qtyType: "piece" },
      { id: "W03", qty: 1, name: "Rules", qtyType: "piece" },
      { id: "W04", qty: 50, name: "Note Pad", qtyType: "sheet" },
      { id: "W05", qty: 84, name: "Cards", qtyType: "card" },
      { id: "W07", qty: 6, name: "Poker Chips", qtyType: "piece" },
      { id: "W08", qty: 1, name: "Assembly & Carton", qtyType: "piece" },
    ],
    actualWeightInGrams: undefined,
    estimatedWeightInGrams: 500,
    cartonCount: 12,
    cartonDimensions: undefined,
  },
  {
    id: "all-LTT-ex-pad-ex-rules-office-box",
    name: "LTT, ex-pad, rules. Officeworks Box",
    components: [
      { id: "W01", qty: 1, name: "Retail Box", qtyType: "piece" },
      { id: "W03", qty: 1, name: "Rules", qtyType: "piece" },
      { id: "W04", qty: 50, name: "Note Pad", qtyType: "sheet" },
      { id: "W05", qty: 84, name: "Cards", qtyType: "card" },
      { id: "W07", qty: 6, name: "Poker Chips", qtyType: "piece" },
      { id: "W08", qty: 1, name: "Assembly & Carton", qtyType: "piece" },
    ],
    actualWeightInGrams: undefined,
    estimatedWeightInGrams: 500,
    cartonCount: 1,
    cartonDimensions: undefined,
  },
];

/**
 * @type {Quote[]}
 */
export const quotes = [
  {
    id: "2023-07-20-Whatz-1000-original-100-sheets",
    qty: 1000,
    terms: "EXW",
    costPerUnit: 3.41,
    currency: "USD",
    timestamp: 1689811200000,
    expiry: 1705708800000,
    componentConfigurationId: "4524c3c3-eee3-451f-a92d-045ca2d35349",
    printerId: "WhatzGames",
  },
  {
    id: "2023-07-20-Whatz-1000-no-tarot-no-tray",
    qty: 1000,
    terms: "EXW",
    costPerUnit: 2.82,
    currency: "USD",
    timestamp: 1689811200000,
    expiry: 1705708800000,
    componentConfigurationId: "ab18b65e-8968-473e-8e29-a479817515c0",
    printerId: "WhatzGames",
  },
  {
    id: "2023-07-20-Whatz-1000",
    qty: 1000,
    terms: "EXW",
    costPerUnit: 3.05,
    currency: "USD",
    timestamp: 1689811200000,
    expiry: 1705708800000,
    componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
    printerId: "WhatzGames",
  },
  {
    id: "2023-07-20-Whatz-1000-after-discount",
    qty: 1000,
    terms: "EXW",
    costPerUnit: 2.75,
    currency: "USD",
    timestamp: 1689811200000,
    expiry: 1705708800000,
    componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
    printerId: "WhatzGames",
  },
  {
    id: "2023-07-20-Whatz-2000",
    qty: 2000,
    terms: "EXW",
    costPerUnit: 2.47,
    currency: "USD",
    timestamp: 1689811200000,
    expiry: 1705708800000,
    componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
    printerId: "WhatzGames",
  },
  {
    id: "2023-07-20-Whatz-3000",
    qty: 3000,
    terms: "EXW",
    costPerUnit: 2.26,
    currency: "USD",
    timestamp: 1689811200000,
    expiry: 1705708800000,
    componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
    printerId: "WhatzGames",
  },
  {
    id: "2023-07-20-Whatz-5000",
    qty: 5000,
    terms: "EXW",
    costPerUnit: 2.09,
    currency: "USD",
    timestamp: 1689811200000,
    expiry: 1705708800000,
    componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
    printerId: "WhatzGames",
  },
  {
    id: "LTT(51-100)",
    qty: 100,
    terms: "EXW",
    costPerUnit: 17.33,
    currency: "USD",
    timestamp: 1689811200000,
    expiry: 1689811200000,
    componentConfigurationId: "ltt-base",
    printerId: "LTT",
  },
  {
    id: "LTT (101-300)",
    qty: 300,
    terms: "EXW",
    costPerUnit: 14.83,
    currency: "USD",
    timestamp: 1689811200000,
    expiry: 1689811200000,
    componentConfigurationId: "ltt-base",
    printerId: "LTT",
  },
  {
    id: "LTT (301-500)",
    qty: 500,
    terms: "EXW",
    costPerUnit: 12.49,
    currency: "USD",
    timestamp: 1689811200000,
    expiry: 1689811200000,
    componentConfigurationId: "ltt-base",
    printerId: "LTT",
  },
  {
    id: "LTT (501-999)",
    qty: 999,
    terms: "EXW",
    costPerUnit: 9.14,
    currency: "USD",
    timestamp: 1689811200000,
    expiry: 1689811200000,
    componentConfigurationId: "ltt-base",
    printerId: "LTT",
  },
  {
    id: "Custom - 50",
    qty: 50,
    terms: "EXW",
    costPerUnit: 22.59,
    currency: "USD",
    timestamp: 1689811200000,
    expiry: 1689811200000,
    componentConfigurationId: "all-LTT-ex-pad-ex-rules-office-box",
    printerId: "LTT+Custom",
  },
  {
    id: "Custom - 100",
    qty: 100,
    terms: "EXW",
    costPerUnit: 21.33,
    currency: "USD",
    timestamp: 1689811200000,
    expiry: 1689811200000,
    componentConfigurationId: "all-LTT-ex-pad-ex-rules-office-box",
    printerId: "LTT+Custom",
  },
];

/**
 * @type {Lane[]}
 */
export const lanes = [
  {
    id: "AUS",
    to: "AUS",
    units: 352,
    pallet: "Euro2 Quarter Pallet",
    lessThanContainerLoad: true,
    currency: "AUD",
    msrp: 30,
  },
  { id: "US", to: "US", units: 120, pallet: `48x40"`, lessThanContainerLoad: true, currency: "USD", msrp: 19.99 },
  {
    id: "UK",
    to: "UK",
    units: 336,
    pallet: "Euro2 Quarter Pallet",
    lessThanContainerLoad: true,
    currency: "GBP",
    msrp: 14.99,
  },
  {
    id: "India",
    to: "India",
    units: 192,
    pallet: "Euro2 Quarter Pallet",
    lessThanContainerLoad: true,
    currency: "INR",
    msrp: 999,
  },
];

/**
 * @type {FreightQuote[]}
 */
export const freightQuotes = [
  {
    id: "loose-units",
    lane: "AUS",
    amount: 803.4,
    currency: "USD",
    method: "Ocean",
    expiry: DateTime.fromISO("2023-10-31").endOf("day").toMillis(),
    minDays: 32,
    maxDays: 58,
    timestamp: DateTime.fromISO("2023-10-24").toMillis(),
    source: "FreightOS",
  },
  {
    amount: 617,
    currency: "USD",
    lane: "AUS",
    method: "Ocean",
    expiry: DateTime.fromISO("2023-09-30").endOf("day").toMillis(),
    id: "freight-os-ch-robinson",
    minDays: 27,
    maxDays: 32,
    timestamp: DateTime.fromISO("2023-09-25").toMillis(),
    source: "FreightOS",
  },
  {
    amount: 780,
    currency: "USD",
    lane: "AUS",
    method: "Ocean",
    expiry: DateTime.fromISO("2023-09-22").endOf("day").toMillis(),
    id: "freight-os-loose-cartons",
    minDays: 32,
    maxDays: 58,
    timestamp: DateTime.fromISO("2023-09-11").toMillis(),
    source: "FreightOS",
  },
  {
    amount: 906.8,
    currency: "USD",
    lane: "AUS",
    method: "Ocean",
    expiry: DateTime.fromISO("2023-09-22").endOf("day").toMillis(),
    id: "freight-os-best-value",
    minDays: 34,
    maxDays: 50,
    timestamp: DateTime.fromISO("2023-09-11").toMillis(),
    source: "FreightOS",
  },
  {
    amount: 3689,
    currency: "USD",
    lane: "AUS",
    method: "Express",
    expiry: DateTime.fromISO("2023-10-10").endOf("day").toMillis(),
    id: "freight-os-quickest",
    minDays: 2,
    maxDays: 5,
    timestamp: DateTime.fromISO("2023-09-11").toMillis(),
    source: "FreightOS",
  },
  {
    amount: 1111.52,
    currency: "USD",
    lane: "AUS",
    method: "Ocean",
    expiry: DateTime.fromISO("2023-10-10").endOf("day").toMillis(),
    id: "freight-os-ocean-2",
    minDays: 27,
    maxDays: 38,
    timestamp: DateTime.fromISO("2023-09-11").toMillis(),
    source: "FreightOS",
  },
  {
    amount: 1826,
    currency: "USD",
    lane: "AUS",
    method: "Air",
    expiry: DateTime.fromISO("2023-10-10").endOf("day").toMillis(),
    id: "freight-os-air",
    minDays: 7,
    maxDays: 10,
    timestamp: DateTime.fromISO("2023-09-11").toMillis(),
    source: "FreightOS",
  },
  {
    amount: 362.05,
    currency: "USD",
    method: "Express",
    expiry: DateTime.fromISO("2023-10-31").endOf("day").toMillis(),
    id: "freight-os-cheapest",
    minDays: 15,
    maxDays: 25,
    timestamp: DateTime.fromISO("2023-10-24").toMillis(),
    source: "FreightOS",
    lane: "US",
  },
  {
    amount: 432.65,
    currency: "USD",
    method: "Air",
    expiry: DateTime.fromISO("2023-10-30").endOf("day").toMillis(),
    id: "freight-air-cheapest",
    minDays: 4,
    maxDays: 8,
    timestamp: DateTime.fromISO("2023-10-17").toMillis(),
    source: "FreightOS",
    lane: "US",
  },
  {
    amount: 309.9,
    currency: "USD",
    method: "Ocean",
    expiry: DateTime.fromISO("2023-10-10").endOf("day").toMillis(),
    id: "freight-os-cheapest",
    minDays: 15,
    maxDays: 25,
    timestamp: DateTime.fromISO("2023-09-11").toMillis(),
    source: "FreightOS",
    lane: "US",
  },
  {
    amount: 600.93,
    currency: "USD",
    method: "Ocean",
    expiry: DateTime.fromISO("2023-11-15").endOf("day").toMillis(),
    id: "freight-os-cheapest-2",
    minDays: 42,
    maxDays: 51,
    timestamp: DateTime.fromISO("2023-10-24").toMillis(),
    source: "FreightOS",
    lane: "UK",
  },
  {
    amount: 565.96,
    currency: "USD",
    method: "Ocean",
    expiry: DateTime.fromISO("2023-10-11").endOf("day").toMillis(),
    id: "freight-os-cheapest",
    minDays: 42,
    maxDays: 51,
    timestamp: DateTime.fromISO("2023-09-11").toMillis(),
    source: "FreightOS",
    lane: "UK",
  },
  {
    amount: 526.14,
    currency: "USD",
    method: "Ocean",
    expiry: DateTime.fromISO("2023-09-22").endOf("day").toMillis(),
    id: "freight-os-direct",
    minDays: 42,
    maxDays: 51,
    timestamp: DateTime.fromISO("2023-10-11").toMillis(),
    source: "FreightOS",
    lane: "UK",
  },
  {
    amount: 617,
    currency: "USD",
    method: "Ocean",
    expiry: DateTime.fromISO("2023-09-30").endOf("day").toMillis(),
    id: "to-validate",
    minDays: 27,
    maxDays: 32,
    timestamp: DateTime.fromISO("2023-09-25").toMillis(),
    source: "FreightOS",
    lane: "India",
  },
];

// const old = {
//   id: "2398473289472",
//   name: "Ten Wickets",
//   printRuns: [
//     {
//       name: "First",
//       cartonMarks: ["Ten Wickets", "12 PCS/CTN", "Carton Size: 30x28.5x20CM", "Carton Weight : KG", "Made in China"],
//       shipments: [
//         {
//           units: 352,
//           pallet: "EUR2-Quarter",
//           to: "AUS",
//           lessThanContainerLoad: true,
//           quotes: [
//             {
//               amount: 803.4,
//               currency: "USD",
//               price: {
//                 amount: 803.4,
//                 currency: "USD",
//               },
//               accepted: true,
//               method: "Ocean",
//               expiry: DateTime.fromISO("2023-10-31").endOf("day").toMillis(),
//               id: "loose-units",
//               minDays: 32,
//               maxDays: 58,
//               timestamp: DateTime.fromISO("2023-10-24").toMillis(),
//               company: "FreightOS",
//             },
//             {
//               amount: 617,
//               currency: "USD",
//               price: {
//                 amount: 617,
//                 currency: "USD",
//               },
//               accepted: false,
//               method: "Ocean",
//               expiry: DateTime.fromISO("2023-09-30").endOf("day").toMillis(),
//               id: "freight-os-ch-robinson",
//               minDays: 27,
//               maxDays: 32,
//               timestamp: DateTime.fromISO("2023-09-25").toMillis(),
//               company: "FreightOS",
//             },
//             {
//               amount: 780,
//               currency: "USD",
//               accepted: false,
//               method: "Ocean",
//               expiry: DateTime.fromISO("2023-09-22").endOf("day").toMillis(),
//               id: "freight-os-loose-cartons",
//               minDays: 32,
//               maxDays: 58,
//               timestamp: DateTime.fromISO("2023-09-11").toMillis(),
//               company: "FreightOS",
//             },
//             {
//               amount: 906.8,
//               currency: "USD",
//               accepted: false,
//               method: "Ocean",
//               expiry: DateTime.fromISO("2023-09-22").endOf("day").toMillis(),
//               id: "freight-os-best-value",
//               minDays: 34,
//               maxDays: 50,
//               timestamp: DateTime.fromISO("2023-09-11").toMillis(),
//               company: "FreightOS",
//             },
//             {
//               amount: 3689,
//               currency: "USD",
//               accepted: false,
//               method: "Express",
//               expiry: DateTime.fromISO("2023-10-10").endOf("day").toMillis(),
//               id: "freight-os-quickest",
//               minDays: 2,
//               maxDays: 5,
//               timestamp: DateTime.fromISO("2023-09-11").toMillis(),
//               company: "FreightOS",
//             },
//             {
//               amount: 1111.52,
//               currency: "USD",
//               accepted: false,
//               method: "Ocean",
//               expiry: DateTime.fromISO("2023-10-10").endOf("day").toMillis(),
//               id: "freight-os-ocean-2",
//               minDays: 27,
//               maxDays: 38,
//               timestamp: DateTime.fromISO("2023-09-11").toMillis(),
//               company: "FreightOS",
//             },
//             {
//               amount: 1826,
//               currency: "USD",
//               accepted: false,
//               method: "Air",
//               expiry: DateTime.fromISO("2023-10-10").endOf("day").toMillis(),
//               id: "freight-os-air",
//               minDays: 7,
//               maxDays: 10,
//               timestamp: DateTime.fromISO("2023-09-11").toMillis(),
//               company: "FreightOS",
//             },
//           ],
//         },
//         {
//           to: "US",
//           units: 120,
//           pallet: "48x40",
//           lessThanContainerLoad: true,
//           quotes: [
//             {
//               amount: 362.05,
//               currency: "USD",
//               accepted: true,
//               method: "Express",
//               expiry: DateTime.fromISO("2023-10-31").endOf("day").toMillis(),
//               id: "freight-os-cheapest",
//               minDays: 15,
//               maxDays: 25,
//               timestamp: DateTime.fromISO("2023-10-24").toMillis(),
//               company: "FreightOS",
//             },
//             {
//               amount: 432.65,
//               currency: "USD",
//               accepted: false,
//               method: "Air",
//               expiry: DateTime.fromISO("2023-10-30").endOf("day").toMillis(),
//               id: "freight-air-cheapest",
//               minDays: 4,
//               maxDays: 8,
//               timestamp: DateTime.fromISO("2023-10-17").toMillis(),
//               company: "FreightOS",
//             },
//             {
//               amount: 309.9,
//               currency: "USD",
//               accepted: false,
//               method: "Ocean",
//               expiry: DateTime.fromISO("2023-10-10").endOf("day").toMillis(),
//               id: "freight-os-cheapest",
//               minDays: 15,
//               maxDays: 25,
//               timestamp: DateTime.fromISO("2023-09-11").toMillis(),
//               company: "FreightOS",
//             },
//           ],
//         },
//         {
//           to: "UK",
//           units: 336,
//           pallet: "EUR2-Quarter",
//           lessThanContainerLoad: true,
//           quotes: [
//             {
//               amount: 600.93,
//               currency: "USD",
//               accepted: true,
//               method: "Ocean",
//               expiry: DateTime.fromISO("2023-11-15").endOf("day").toMillis(),
//               id: "freight-os-cheapest-2",
//               minDays: 42,
//               maxDays: 51,
//               timestamp: DateTime.fromISO("2023-10-24").toMillis(),
//               company: "FreightOS",
//             },
//             {
//               amount: 565.96,
//               currency: "USD",
//               accepted: false,
//               method: "Ocean",
//               expiry: DateTime.fromISO("2023-10-11").endOf("day").toMillis(),
//               id: "freight-os-cheapest",
//               minDays: 42,
//               maxDays: 51,
//               timestamp: DateTime.fromISO("2023-09-11").toMillis(),
//               company: "FreightOS",
//             },
//             {
//               amount: 526.14,
//               currency: "USD",
//               accepted: false,
//               method: "Ocean",
//               expiry: DateTime.fromISO("2023-09-22").endOf("day").toMillis(),
//               id: "freight-os-direct",
//               minDays: 42,
//               maxDays: 51,
//               timestamp: DateTime.fromISO("2023-10-11").toMillis(),
//               company: "FreightOS",
//             },
//           ],
//         },
//         {
//           to: "AmznIndia",
//           units: 192,
//           pallet: "EUR2-Quarter",
//           lessThanContainerLoad: true,
//           quotes: [
//             {
//               amount: 617,
//               currency: "USD",
//               price: {
//                 amount: 617,
//                 currency: "USD",
//               },
//               accepted: true,
//               method: "Ocean",
//               expiry: DateTime.fromISO("2023-09-30").endOf("day").toMillis(),
//               id: "to-validate",
//               minDays: 27,
//               maxDays: 32,
//               timestamp: DateTime.fromISO("2023-09-25").toMillis(),
//               company: "FreightOS",
//             },
//           ],
//         },
//         {
//           to: "LTT-AMZN",
//           units: 500,
//           pallet: "EUR2-Quarter",
//           lessThanContainerLoad: true,
//           quotes: [
//             {
//               amount: 363,
//               currency: "USD",
//               price: {
//                 amount: 363,
//                 currency: "USD",
//               },
//               accepted: true,
//               method: "Air",
//               expiry: DateTime.fromISO("2023-09-30").endOf("day").toMillis(),
//               id: "to-validate",
//               minDays: 15,
//               maxDays: 20,
//               timestamp: DateTime.fromISO("2023-09-25").toMillis(),
//               company: "LLT",
//             },
//           ],
//         },
//       ],
//     },
//   ],
//   designers: [{ designerId: "distributedlife", royalty: 0.1 }],
//   budgets: [
//     {
//       id: "abcd",
//       name: "Small Box Budget",
//       items: [
//         { amount: 5000, currency: "AUD", name: "Art" },
//         { amount: 3000, currency: "AUD", name: "Marketing" },
//       ],
//     },
//   ],
//   editions: [
//     {
//       name: "First Edition",
//       status: "InProduction",
//       sku: "SBSCR01",
//       budgetId: "abcd",
//       componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
//       msrp: [
//         { amount: 29.99, currency: "AUD" },
//         { amount: 14.99, currency: "GBP" },
//         { amount: 19.99, currency: "USD" },
//         { amount: 19.99, currency: "EUR" },
//         { amount: 999, currency: "INR" },
//       ],
//       quoteId: "2023-07-20-Whatz-1000",
//       timeline: [
//         { name: "FinalsToManufacturer", timestamp: DateTime.fromISO("2023-08-21").toMillis(), customName: null },
//         { name: "MassProductionApproval", timestamp: DateTime.fromISO("2023-09-05").toMillis(), customName: null },
//         { name: "MassProductionCompleted", timestamp: null, customName: null },
//         { name: "ReadyForShipment", timestamp: null, customName: null },
//         { name: "ShipDeparted", timestamp: null, customName: null },
//         { name: "ShipArrived", timestamp: null, customName: null },
//         { name: "CustomsCompleted", timestamp: null, customName: null },
//         { name: "ArrivedAtWarehouse", timestamp: null, customName: null },
//         { name: "ReadyForDispatch", timestamp: null, customName: null },
//       ],
//     },
//   ],
//   quotes: [
//     {
//       id: "2023-07-20-Whatz-1000-original-100-sheets",
//       qty: 1000,
//       terms: "EXW",
//       accepted: false,
//       costPerUnit: 3.41,
//       currency: "USD",
//       timestamp: 1689811200000,
//       expiry: 1705708800000,
//       componentConfigurationId: "4524c3c3-eee3-451f-a92d-045ca2d35349",
//       printerId: "WhatzGames",
//     },
//     {
//       id: "2023-07-20-Whatz-1000-no-tarot-no-tray",
//       qty: 1000,
//       terms: "EXW",
//       accepted: false,
//       costPerUnit: 2.82,
//       currency: "USD",
//       timestamp: 1689811200000,
//       expiry: 1705708800000,
//       componentConfigurationId: "ab18b65e-8968-473e-8e29-a479817515c0",
//       printerId: "WhatzGames",
//     },
//     {
//       id: "2023-07-20-Whatz-1000",
//       qty: 1000,
//       terms: "EXW",
//       accepted: false,
//       costPerUnit: 3.05,
//       currency: "USD",
//       costPerUnit2: {
//         currency: "USD",
//         amount: 3.05,
//       },
//       timestamp: 1689811200000,
//       expiry: 1705708800000,
//       componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
//       printerId: "WhatzGames",
//     },
//     {
//       id: "2023-07-20-Whatz-1000-after-discount",
//       qty: 1000,
//       terms: "EXW",
//       accepted: true,
//       costPerUnit: 3.05 - 0.3,
//       currency: "USD",
//       costPerUnit2: {
//         currency: "USD",
//         amount: 3.05 - 0.3,
//       },
//       timestamp: 1689811200000,
//       expiry: 1705708800000,
//       componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
//       printerId: "WhatzGames",
//     },
//     {
//       id: "2023-07-20-Whatz-2000",
//       qty: 2000,
//       terms: "EXW",
//       costPerUnit: 2.47,
//       currency: "USD",
//       accepted: false,
//       timestamp: 1689811200000,
//       expiry: 1705708800000,
//       componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
//       printerId: "WhatzGames",
//     },
//     {
//       id: "2023-07-20-Whatz-3000",
//       qty: 3000,
//       terms: "EXW",
//       costPerUnit: 2.26,
//       currency: "USD",
//       accepted: false,
//       timestamp: 1689811200000,
//       expiry: 1705708800000,
//       componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
//       printerId: "WhatzGames",
//     },
//     {
//       id: "2023-07-20-Whatz-5000",
//       qty: 5000,
//       terms: "EXW",
//       costPerUnit: 2.09,
//       currency: "USD",
//       accepted: false,
//       timestamp: 1689811200000,
//       expiry: 1705708800000,
//       componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
//       printerId: "WhatzGames",
//     },
//     {
//       id: "LTT(51-100)",
//       qty: 100,
//       terms: "EXW",
//       costPerUnit: 17.33,
//       currency: "USD",
//       accepted: false,
//       timestamp: 1689811200000,
//       expiry: 1689811200000,
//       componentConfigurationId: "ltt-base",
//       printerId: "LTT",
//     },
//     {
//       id: "LTT (101-300)",
//       qty: 300,
//       terms: "EXW",
//       costPerUnit: 14.83,
//       currency: "USD",
//       accepted: false,
//       timestamp: 1689811200000,
//       expiry: 1689811200000,
//       componentConfigurationId: "ltt-base",
//       printerId: "LTT",
//     },
//     {
//       id: "LTT (301-500)",
//       qty: 500,
//       terms: "EXW",
//       costPerUnit: 12.49,
//       currency: "USD",
//       accepted: false,
//       timestamp: 1689811200000,
//       expiry: 1689811200000,
//       componentConfigurationId: "ltt-base",
//       printerId: "LTT",
//     },
//     {
//       id: "LTT (501-999)",
//       qty: 999,
//       terms: "EXW",
//       costPerUnit: 9.14,
//       currency: "USD",
//       accepted: false,
//       timestamp: 1689811200000,
//       expiry: 1689811200000,
//       componentConfigurationId: "ltt-base",
//       printerId: "LTT",
//     },
//     {
//       id: "Custom - 50",
//       qty: 50,
//       terms: "EXW",
//       costPerUnit: 22.59,
//       currency: "USD",
//       accepted: false,
//       timestamp: 1689811200000,
//       expiry: 1689811200000,
//       componentConfigurationId: "all-LTT-ex-pad-ex-rules-office-box",
//       printerId: "LTT+Custom",
//     },
//     {
//       id: "Custom - 100",
//       qty: 100,
//       terms: "EXW",
//       costPerUnit: 21.33,
//       currency: "USD",
//       accepted: false,
//       timestamp: 1689811200000,
//       expiry: 1689811200000,
//       componentConfigurationId: "all-LTT-ex-pad-ex-rules-office-box",
//       printerId: "LTT+Custom",
//     },
//   ],
//   components: [
//     {
//       id: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
//       name: "50 sheets, double-sided",
//       components: [
//         {
//           id: "W01",
//           qty: 1,
//           name: "Retail Box",
//           art: {
//             artComplete: 1,
//             finalsSubmitted: 1,
//             eProofApproved: 1,
//             whiteBoxApproved: 1693825490000,
//             digitalPrintApproved: 1693825490000,
//             finalSampleApproved: 0,
//           },
//         },
//         {
//           id: "W02",
//           qty: 1,
//           name: "Tray",
//           art: {
//             artComplete: 1,
//             finalsSubmitted: 1,
//             eProofApproved: 1,
//             whiteBoxApproved: 1693825490000,
//             digitalPrintApproved: 1693825490000,
//             finalSampleApproved: 0,
//           },
//         },
//         {
//           id: "W03",
//           quantity: 1,
//           name: "Rules",
//           art: {
//             artComplete: 1,
//             finalsSubmitted: 1,
//             eProofApproved: DateTime.fromISO("2023-09-05").toMillis(),
//             whiteBoxApproved: 1693825490000,
//             digitalPrintApproved: DateTime.fromISO("2023-09-05").toMillis(),
//             finalSampleApproved: 0,
//           },
//         },
//         {
//           id: "W04",
//           quantity: 50,
//           name: "Note Pad",
//           art: {
//             artComplete: 1,
//             finalsSubmitted: 1,
//             eProofApproved: 1,
//             whiteBoxApproved: 1693825490000,
//             digitalPrintApproved: 1693825490000,
//             finalSampleApproved: 0,
//           },
//         },
//         {
//           id: "W05",
//           quantity: 55,
//           name: "Cards",
//           art: {
//             artComplete: 1,
//             finalsSubmitted: 1,
//             eProofApproved: 1,
//             whiteBoxApproved: 1693825490000,
//             digitalPrintApproved: 1693825490000,
//             finalSampleApproved: 0,
//           },
//         },
//         {
//           id: "W06",
//           quantity: 29,
//           name: "Cards",
//           art: {
//             artComplete: 1,
//             finalsSubmitted: 1,
//             eProofApproved: 1,
//             whiteBoxApproved: 1693825490000,
//             digitalPrintApproved: 1693825490000,
//             finalSampleApproved: 0,
//           },
//         },
//         {
//           id: "W07",
//           quantity: 6,
//           name: "Poker Chips",
//           art: {
//             artComplete: 1,
//             finalsSubmitted: 1,
//             eProofApproved: 1,
//             whiteBoxApproved: 1693825490000,
//             digitalPrintApproved: 1693825490000,
//             finalSampleApproved: 1693825490000,
//           },
//         },
//         {
//           id: "W08",
//           quantity: 1,
//           name: "Assembly & Carton",
//         },
//       ],
//       actualWeightInGrams: 463,
//       estimatedWeightInGrams: 500,
//       cartonCount: 12,
//       cartonDimensions: {
//         length: 290,
//         width: 285,
//         height: 200,
//         units: "mm",
//       },
//       status: "Active",
//     },
//     {
//       id: "ltt-base",
//       name: "All LTT",
//       components: [
//         {
//           id: "LTT01",
//           quantity: 1,
//           name: "LTT Medium Box",
//         },
//         {
//           id: "LTT02",
//           quantity: 1,
//           name: "Rules",
//         },
//         {
//           id: "LTT03",
//           quantity: 50,
//           name: "Note Pad",
//         },
//         {
//           id: "LTT04",
//           quantity: 54,
//           name: "Euro Cards",
//         },
//         {
//           id: "LTT05",
//           quantity: 29,
//           name: "Tarot Cards",
//         },
//         {
//           id: "LTT06",
//           quantity: 6,
//           name: "Poker Chips",
//         },
//       ],
//       actualWeightInGrams: 0,
//       estimatedWeightInGrams: 500,
//       cartonCount: 0,
//       cartonDimensions: undefined,
//       status: "Archived",
//     },
//     {
//       id: "4524c3c3-eee3-451f-a92d-045ca2d35349",
//       name: "100 sheets, single-sided",
//       components: [
//         { id: "W01", quantity: 1, name: "Retail Box", qtyType: "" },
//         { id: "W02", quantity: 1, name: "Tray", qtyType: "" },
//         { id: "W03", quantity: 1, name: "Rules", qtyType: "" },
//         { id: "W04", quantity: 100, name: "Note Pad", qtyType: "" },
//         { id: "W05", quantity: 55, name: "Cards", qtyType: "" },
//         { id: "W06", quantity: 29, name: "Cards", qtyType: "" },
//         { id: "W07", quantity: 6, name: "Poker Chips", qtyType: "" },
//         { id: "W08", quantity: 1, name: "Assembly & Carton", qtyType: "" },
//       ],
//       actualWeightInGrams: undefined,
//       estimatedWeightInGrams: 500,
//       cartonCount: 12,
//       cartonDimensions: undefined,
//       status: "Archived",
//     },
//     {
//       id: "ab18b65e-8968-473e-8e29-a479817515c0",
//       name: "no tarot, no tray",
//       components: [
//         { id: "W01", quantity: 1, name: "Retail Box", qtyType: "" },
//         { id: "W03", quantity: 1, name: "Rules", qtyType: "" },
//         { id: "W04", quantity: 50, name: "Note Pad", qtyType: "" },
//         { id: "W05", quantity: 84, name: "Cards", qtyType: "" },
//         { id: "W07", quantity: 6, name: "Poker Chips", qtyType: "" },
//         { id: "W08", quantity: 1, name: "Assembly & Carton", qtyType: "" },
//       ],
//       actualWeightInGrams: undefined,
//       estimatedWeightInGrams: 500,
//       cartonCount: 12,
//       cartonDimensions: undefined,
//       status: "Archived",
//     },
//     {
//       id: "all-LTT-ex-pad-ex-rules-office-box",
//       name: "LTT, ex-pad, rules. Officeworks Box",
//       components: [
//         { id: "W01", quantity: 1, name: "Retail Box", qtyType: "" },
//         { id: "W03", quantity: 1, name: "Rules", qtyType: "" },
//         { id: "W04", quantity: 50, name: "Note Pad", qtyType: "" },
//         { id: "W05", quantity: 84, name: "Cards", qtyType: "" },
//         { id: "W07", quantity: 6, name: "Poker Chips", qtyType: "" },
//         { id: "W08", quantity: 1, name: "Assembly & Carton", qtyType: "" },
//       ],
//       actualWeightInGrams: undefined,
//       estimatedWeightInGrams: 500,
//       cartonCount: 1,
//       cartonDimensions: undefined,
//       status: "Archived",
//     },
//   ],
//   awards: [],
// };
