import React from "react";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { SupportedCurrencies } from "@seabrookstudios/pitch2table-core";
import { CurrencyData } from "../games/royalties/currency-formatting";

const Label = "Currency";

/**
 * @type {import("@seabrookstudios/pitch2table-core").Currency[]}
 */
const PriorityCurrencies = ["AUD", "EUR", "GBP", "USD"];

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").Currency} props.value
 * @param {(value: import("@seabrookstudios/pitch2table-core").Currency) => void} props.onChange
 * @param {string} props.helperText
 * @param {string} [props.label]
 * @param {boolean} [props.disabled]
 */
export const CurrentDropDown = ({ label = Label, value, onChange, helperText, disabled = false }) => {
  /**
   * @param {*} ev
   */
  const onChangeHandler = (ev) => {
    onChange(ev.target.value);
  };

  return (
    <FormControl sx={{ width: "100%" }} margin="normal" disabled={disabled}>
      <InputLabel id="currency">{label}</InputLabel>
      <Select
        labelId="currency"
        value={value}
        onChange={onChangeHandler}
        placeholder={label}
        label={label}
        input={<OutlinedInput label={label} />}
      >
        {PriorityCurrencies.map((currency) => (
          <MenuItem value={currency} key={currency}>
            {currency} - {CurrencyData[currency].name}
          </MenuItem>
        ))}
        <Divider />
        {SupportedCurrencies.filter((currency) => !PriorityCurrencies.includes(currency)).map((currency) => (
          <MenuItem value={currency} key={currency}>
            {currency} - {CurrencyData[currency].name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
