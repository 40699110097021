import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import InstallMobile from "@mui/icons-material/InstallMobile";
import { SideMenuOption } from "../core/SideMenuOption";
import { DiscordLink, SupportEmail } from "../core/CommonLinks";
import { Path } from "../Path";

export const GoMobileButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickHandler = () => {
    navigate(Path.mobile);
  };

  return (
    <SideMenuOption
      tooltip="How to get the iOS or Android app"
      onClick={onClickHandler}
      selected={location.pathname.startsWith(Path.mobile)}
      label="Mobile"
    >
      <InstallMobile />
    </SideMenuOption>
  );
};

export const GetMobilePage = () => {
  return (
    <Box p={2} width="100%" flex={1}>
      <Card>
        <Box style={{ width: "60vw", height: "60vh" }} p={2} m="auto">
          <Box pb={2}>
            <Typography textAlign="center" variant="h4">
              Get Pitch2Table on Mobile
            </Typography>
          </Box>
          <Box pt={2}>
            <Typography>
              iOS and Android versions of Pitch2Table are in closed beta. Please contact us at:{" "}
              <SupportEmail subject="Pitch2Table App Beta" /> to get access. When you do, us let us know whether you
              want iOS or Android.
            </Typography>
          </Box>
          <Box pt={2}>
            <Typography variant="h6">Android</Typography>
            <Typography>
              For android please supply your Google Play email address as google requires this for us to add your to the
              test group.
            </Typography>
          </Box>
          <Box pt={2}>
            <Typography pt={1} variant="h6">
              iOS
            </Typography>
            <Typography>
              You can use this link:{" "}
              <a href="https://testflight.apple.com/join/DQVZRTbU" target="_blank" rel="noreferrer">
                https://testflight.apple.com/join/DQVZRTbU
              </a>{" "}
              to accept the beta. If there are no spots left, please email{" "}
              <SupportEmail subject="Pitch2Table App Beta" /> or follow the instructions on our <DiscordLink />. You
              will need to install the app via Apple's TestFlight app
            </Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};
