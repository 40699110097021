import Forward from "@mui/icons-material/Forward";
import Money from "@mui/icons-material/Money";
import Pause from "@mui/icons-material/Pause";
import Payments from "@mui/icons-material/Payments";
import PointOfSale from "@mui/icons-material/PointOfSale";
import SupportAgent from "@mui/icons-material/SupportAgent";
import { DateTime } from "luxon";

/**
 * @type {Record<RoyaltyCategories,string>}
 */
export const RoyaltyCategoryLabels = {
  all: "All",
  "by-game": "By Game",
  "by-year": "By Year",
  "by-publisher": "By Publisher",
  "by-type": "By Type",
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp} r
 */
export const royaltyToYear = (r) => DateTime.fromMillis(r.date).toFormat("yyyy");

/**
 * @type {Record<import("@seabrookstudios/pitch2table-core").RoyaltyType, import("@mui/material/OverridableComponent").OverridableComponent<import("@mui/material").SvgIconTypeMap<{}, "svg">> & { muiName: string; }>}
 */
export const RoyaltyTypeIcons = {
  Royalty: Money,
  Advance: Forward,
  HoldingFee: Pause,
  SigningBonus: PointOfSale,
  Consulting: SupportAgent,
  Other: Payments,
};
