import React from "react";
import { NoItemsFound } from "../../publisher/NoItemsFound";
import { Warnings } from "./model";
import { useGame, useGameContracts } from "@seabrookstudios/pitch2table";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { useGameIdFromRoute } from "../WithGameIdFromRoute";

/**
 * @param {import("@seabrookstudios/types").GameId} gameId
 */
export const useWarnings = (gameId) => {
  const { data: game } = useGame(gameId);
  const { data: contracts = [], isLoading } = useGameContracts(gameId);

  const warnings = Warnings.filter((warning) => {
    if (!game) {
      return false;
    }

    return warning.rule(game, contracts);
  });

  if (isLoading) {
    return [];
  }

  return warnings;
};

export const WarningBanner = () => {
  const gameId = useGameIdFromRoute();
  const warnings = useWarnings(gameId);

  if (warnings.length === 0) {
    return <Box />;
  }

  return (
    <NoItemsFound>
      {warnings.map((warning) => (
        <Box key={warning.text}>
          <Alert severity="warning">{warning.text}</Alert>
          <Box p={1} />
        </Box>
      ))}
    </NoItemsFound>
  );
};
