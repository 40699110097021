import React from "react";
import Card from "@mui/material/Card";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { useAttachmentIdFromRoute, useGameIdFromRoute } from "../games/WithGameIdFromRoute";
import { useGameRules } from "@seabrookstudios/pitch2table";
import { ImageOrPdfViewer } from "../core/ImageOrPdfViewer";

export const RulesFromRoute = () => {
  const gameId = useGameIdFromRoute();
  const attachmentId = useAttachmentIdFromRoute();
  const { data: rules = [] } = useGameRules(gameId);

  if (!attachmentId) {
    return <EmptyDetailCard text="Select a rules version from the list on the left." />;
  }

  const doc = rules.find((doc) => doc.id === attachmentId);
  return (
    <Card elevation={1} square={false}>
      <ImageOrPdfViewer document={doc} />
    </Card>
  );
};
