import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { AppLink } from "../styleguide/AppLink";
import ArrowForwardOutlined from "@mui/icons-material/ArrowForwardOutlined";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.secondary
 * @param {import("react-router-dom").To} [props.to]
 */
export const ListHeaderWithExpand = ({ title, secondary, to }) => {
  return (
    <ListItem>
      <ListItemText primary={title} secondary={secondary} />
      {to && (
        <AppLink to={to}>
          <ListItemIcon>
            <ListItemButton>
              <ArrowForwardOutlined />
            </ListItemButton>
          </ListItemIcon>
        </AppLink>
      )}
    </ListItem>
  );
};
