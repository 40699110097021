import React from "react";
import { NoItemsFound } from "../publisher/NoItemsFound";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { useUserProfile } from "../user/queries";
import { AlwaysOnAppLink } from "../styleguide/AppLink";
import { Path } from "../Path";

export const NoRoyalties = () => {
  const { data: profile } = useUserProfile();

  return (
    <NoItemsFound>
      <Alert severity="info">
        Each time you receive a payment (e.g. holding payment, advance, royalty, etc) from a publisher, record it here
        and we'll be able to help you visualise your earnings in different ways.
      </Alert>
      <Box p={1} />
      {profile && !profile.currencySettings.preferredCurrency && (
        <Alert severity="error">
          Set your{" "}
          <AlwaysOnAppLink to={Path.settings().category("your-location")}>currency preferences</AlwaysOnAppLink> to use
          this feature.
        </Alert>
      )}
    </NoItemsFound>
  );
};
