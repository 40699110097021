import React from "react";
import Box from "@mui/material/Box";

/**
 * @param {object} props
 * @param {import("react").ReactNode} [props.children]
 */
export const NoItemsFound = ({ children }) => (
  <Box display="flex" justifyContent="center" alignItems="center" p={2}>
    {children}
  </Box>
);
