import React, { useState } from "react";
import { DateTime } from "luxon";
import { FullScreenDialog } from "../../core/FullScreenDialog";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import {
  usePublisherAndContacts,
  useUpdatePublisherGameUpdate,
  useDeletePublisherGameUpdate,
} from "@seabrookstudios/pitch2table";
import { SmartTextField } from "../../core/SmartTextField";
import { PublisherUpdateFromSelect } from "./PublisherUpdateFromSelect";
import Edit from "@mui/icons-material/Edit";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { formatFullDate } from "../../core/date-formatting";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").UserId} props.userId
 * @param {import("@seabrookstudios/pitch2table-core").DesignerPublisherGameView} props.publisherGame
 * @param {import("@seabrookstudios/pitch2table-core").PublisherGameUpdate} props.update
 */
export const EditPublisherUpdate = ({ userId, publisherGame, update }) => {
  const { data: publisherAndContacts } = usePublisherAndContacts(publisherGame.publisherId);

  const updatePublisherGameUpdate = useUpdatePublisherGameUpdate(publisherGame.id, update.id);
  const deletePublisherGameUpdate = useDeletePublisherGameUpdate(publisherGame.id, update.id);

  const contacts = publisherAndContacts ? publisherAndContacts[1] : [];

  // const [newStatus, setStatus] = useState(update.newStatus);
  // the one we use for the date controls
  const [date, setDate] = useState(DateTime.fromMillis(update.timestamp));
  // the one we send to the server
  const [newDate, setNewDate] = useState(update.timestamp);
  const [updateText, setUpdateText] = useState(update.update);
  const [from, setFrom] = useState(
    /** @type {(import("@seabrookstudios/types").UserId|"publisher")} */ (update.createdByUserId)
  );

  /**
   * @param {import('@seabrookstudios/types').UserId|'publisher'} from
   */
  const onFromSelect = (from) => {
    setFrom(from);
  };

  const saveUpdate = () => {
    /**
     * @type {import("@seabrookstudios/pitch2table-core").EditPublisherGameUpdateRequest}
     */
    const request = {
      update: updateText,
      timestamp: newDate,
      from,
    };

    updatePublisherGameUpdate.mutate(request);
  };

  const onUpdateDate = (componentDate) => {
    setNewDate(componentDate.toMillis());
    setDate(componentDate);
  };

  const onDeleteUpdate = () => {
    deletePublisherGameUpdate.mutate();
  };

  return (
    <FullScreenDialog
      fullscreen={false}
      title="Edit Update"
      actionText="SAVE"
      canSave={false}
      onSaveHandler={saveUpdate}
      isBusy={updatePublisherGameUpdate.isPending}
      Icon={() => <Edit fontSize="small" />}
      size="small"
      iconAlign="flex-start"
      textButton
      onDeleteHandler={onDeleteUpdate}
    >
      <Card elevation={2}>
        <Box p={1}>
          <Stack direction="row">
            <DatePicker defaultValue={date} label="Date" onChange={onUpdateDate} />
            <Box justifyContent="center" alignItems="center" display="flex" flex={1} p={1}>
              <Typography color="text.secondary">{formatFullDate(newDate)}</Typography>
            </Box>
          </Stack>
          <PublisherUpdateFromSelect
            value={from}
            userId={userId}
            publisherContactUserIds={contacts.map((contact) => contact.id)}
            publisherGame={publisherGame}
            onChange={onFromSelect}
          />
          <SmartTextField
            initialValue={update.update}
            value={updateText}
            onChange={setUpdateText}
            onEnterDisabled
            multiline
            minRows={5}
            autoFocus
            label="Message"
          />
        </Box>
      </Card>
    </FullScreenDialog>
  );
};
