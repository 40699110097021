import React, { useState } from "react";
import { useCreateGameTopic, useCreateTopicMessage, useUpdateTopicMessage } from "@seabrookstudios/pitch2table";
import { SmartTextField } from "../core/SmartTextField";
import { FullScreenDialog } from "../core/FullScreenDialog";
import Edit from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import { ThisUserAvatar } from "../users/UserAvatar";
import CardHeader from "@mui/material/CardHeader";
import { formatTopicDate } from "./model";
import { Editor } from "../core/Editor";
import DeleteForever from "@mui/icons-material/DeleteForever";
import RestoreFromTrash from "@mui/icons-material/RestoreFromTrash";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const AddTopicToGame = ({ gameId }) => {
  const createGameTopic = useCreateGameTopic(gameId);

  const [name, setName] = useState("");
  const [body, setBody] = useState("");

  const onSaveHandler = async () => {
    await createGameTopic.mutateAsync({
      name,
      body,
      type: "General",
      parent: undefined,
      parentId: undefined,
    });
  };

  return (
    <FullScreenDialog
      title="Add Message"
      actionText="SAVE"
      onSaveHandler={onSaveHandler}
      canSave={false}
      isBusy={createGameTopic.isPending}
    >
      <SmartTextField
        label="Title"
        initialValue={name}
        onChange={setName}
        multiline={false}
        helperText="What is the topic you wish to discuss?"
      />
      <Editor value={body} onChange={setBody} />
    </FullScreenDialog>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").TopicId} props.topicId
 * @param {import("@seabrookstudios/pitch2table-core").MessageId} props.messageId
 * @param {string} props.initialBody
 * @param {boolean} props.disabled
 * @param {boolean} props.deleted
 */
export const EditMessage = ({ topicId, messageId, deleted, initialBody, disabled }) => {
  const updateTopicMessage = useUpdateTopicMessage(topicId, messageId);

  const [body, setBody] = useState(initialBody);

  const onSaveHandler = async () => {
    await updateTopicMessage.mutateAsync({
      body,
      deleted: undefined,
    });
  };

  const deleteMessage = async () => {
    await updateTopicMessage.mutateAsync({
      body: undefined,
      deleted: true,
    });
  };

  const restoreMessage = async () => {
    await updateTopicMessage.mutateAsync({
      body: undefined,
      deleted: false,
    });
  };

  return (
    <FullScreenDialog
      title="Update Message"
      actionText="SAVE"
      onSaveHandler={onSaveHandler}
      onDeleteHandler={deleted ? restoreMessage : deleteMessage}
      canSave={false}
      isBusy={updateTopicMessage.isPending}
      Icon={Edit}
      disabled={disabled || deleted}
      DeleteIcon={deleted ? <RestoreFromTrash /> : <DeleteForever />}
    >
      <Editor value={body} onChange={setBody} />
    </FullScreenDialog>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").TopicId} props.topicId
 * @param {string} props.name
 */
export const AddMessageToTopic = ({ name, topicId }) => {
  const createTopicMessage = useCreateTopicMessage(topicId);

  const [visible, setVisible] = useState(false);
  const [body, setBody] = useState("");

  const onSaveHandler = async () => {
    await createTopicMessage.mutateAsync({
      body,
    });

    setBody("");
    setVisible(false);
  };

  return (
    <Card>
      {visible ? (
        <Box p={1}>
          <CardHeader avatar={<ThisUserAvatar />} title={`In response to ${name}`} subheader={formatTopicDate()} />
          <Box p={1} id="comment">
            <Editor value={body} onChange={setBody} />
          </Box>
          <CardActions>
            <Button disabled={body.length === 0} onClick={onSaveHandler}>
              Add comment
            </Button>
          </CardActions>
        </Box>
      ) : (
        <Box p={1} onClick={() => setVisible(true)}>
          <CardHeader avatar={<ThisUserAvatar />} title="Add a comment here..." subheader={formatTopicDate()} />
        </Box>
      )}
    </Card>
  );
};
