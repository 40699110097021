import React from "react";
import { GamesListAndDetail } from "./GamesListAndDetail";
import { Path } from "../Path";
import { SellsheetListAndDetail } from "../sellsheets/SellsheetListAndDetail";
import { RulesListAndDetail } from "../rules/RulesListAndDetail";
import { PublicGameDetailFromRoute } from "../games/PublicGameDetail";
import { s2GameId } from "@seabrookstudios/types";
import { SubmissionsListAndDetail } from "../publisher/SubmissionsListAndDetail";
import { AppWithSideMenu } from "../core/AppWithSideMenu";
import { PublisherInterestListAndDetail } from "../games/PublisherInterestListAndDetail";
import {
  GameComponentConfigurationQuotesBreadcrumb,
  GameComponentConfigurationsBreadcrumb,
  GameLanesBreadcrumb,
  GamesBreadcrumb,
  makeBreadcrumbs,
  MyGames,
  SelectedGameBreadcrumbs,
  SelectedGameContractsBreadcrumbs,
  SelectedGameDesignerBreadcrumbs,
  SelectedGamePublisherBreadcrumbs,
  SelectedGamePublisherInterestBreadcrumbs,
  SelectedGameRulesBreadcrumbs,
  SelectedGameSelectedDesignerBreadcrumbs,
  SelectedGameSellsheetsBreadcrumbs,
} from "../core/Breadcrumbs";
import { DesignerListAndDetail } from "./designers/DesignerListAndDetail";
import { s2UserId } from "@seabrookstudios/types";
import { s2PublisherId } from "../util/type-conversion";
import { ListAndDetail as ContractListAndDetail } from "./contracts/ListAndDetail";
import { ListAndDetail as ComponentListAndDetail } from "./components/Concept";
import { ListAndDetail as QuoteListAndDetail } from "./components/quotes/ComponentQuotesConcept";
import { FreightListAndDetail } from "./freight/FreightConcept";
import { FinancialModelling } from "./financial-modelling/Concept";
import { RoyaltiesListAndDetail } from "./royalties/ListAndDetail";
import { GameQuickLinks } from "./GameQuickLinks";

export const routes = [
  {
    path: Path.games().pathname,
    element: <AppWithSideMenu DesignerView={GamesListAndDetail} />,
    handle: {
      prefer: "left",
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).link().pathname,
    element: (
      <AppWithSideMenu
        DesignerView={GamesListAndDetail}
        PublisherView={SubmissionsListAndDetail}
        UnauthenticatedView={PublicGameDetailFromRoute}
      />
    ),
    handle: {
      crumb: SelectedGameBreadcrumbs,
      prefer: "right",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).publishers().pathname,
    element: (
      <AppWithSideMenu
        DesignerView={PublisherInterestListAndDetail}
        PublisherView={Path.Home}
        UnauthenticatedView={Path.Home}
      />
    ),
    handle: {
      /**
       * @param {object} props
       * @param {import("@seabrookstudios/types").GameId} props.gameId
       */
      crumb: SelectedGamePublisherBreadcrumbs,
      prefer: "left",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).publisher(s2PublisherId(":publisherId")).pathname,
    element: (
      <AppWithSideMenu
        DesignerView={PublisherInterestListAndDetail}
        PublisherView={Path.Home}
        UnauthenticatedView={Path.Home}
      />
    ),
    handle: {
      crumb: SelectedGamePublisherInterestBreadcrumbs,
      prefer: "right",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).designers().pathname,
    element: (
      <AppWithSideMenu DesignerView={DesignerListAndDetail} PublisherView={Path.Home} UnauthenticatedView={Path.Home} />
    ),
    handle: {
      crumb: SelectedGameDesignerBreadcrumbs,
      prefer: "left",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).designer(s2UserId(":userId")).pathname,
    element: (
      <AppWithSideMenu DesignerView={DesignerListAndDetail} PublisherView={Path.Home} UnauthenticatedView={Path.Home} />
    ),
    handle: {
      crumb: SelectedGameSelectedDesignerBreadcrumbs,
      prefer: "right",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).sellsheets().pathname,
    element: (
      <AppWithSideMenu>
        <SellsheetListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: SelectedGameSellsheetsBreadcrumbs,
      prefer: "left",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).sellsheet(
      /** @type {import("@seabrookstudios/pitch2table-core").AttachmentId} */ (":attachmentId")
    ).pathname,
    element: (
      <AppWithSideMenu>
        <SellsheetListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: SelectedGameSellsheetsBreadcrumbs,
      prefer: "right",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).rules().pathname,
    element: (
      <AppWithSideMenu>
        <RulesListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: SelectedGameRulesBreadcrumbs,
      prefer: "left",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).rule(
      /** @type {import("@seabrookstudios/pitch2table-core").AttachmentId} */ (":attachmentId")
    ).pathname,
    element: (
      <AppWithSideMenu>
        <RulesListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: SelectedGameRulesBreadcrumbs,
      prefer: "right",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).contracts().pathname,
    element: (
      <AppWithSideMenu>
        <ContractListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: SelectedGameContractsBreadcrumbs,
      prefer: "left",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).contract(
      /** @type {import("@seabrookstudios/pitch2table-core").AttachmentId} */ (":attachmentId")
    ).pathname,
    element: (
      <AppWithSideMenu>
        <ContractListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: SelectedGameContractsBreadcrumbs,
      prefer: "right",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).components().pathname,
    element: (
      <AppWithSideMenu>
        <ComponentListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs("Components", ({ gameId }) => Path.game(gameId).components(), [MyGames, GamesBreadcrumb]),
      prefer: "left",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).component(":configurationId").link().pathname,
    element: (
      <AppWithSideMenu>
        <ComponentListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs(
        "TBC",
        ({ gameId, configurationId }) => Path.game(gameId).component(configurationId).link(),
        [MyGames, GamesBreadcrumb, GameComponentConfigurationsBreadcrumb]
      ),
      prefer: "right",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).component(":configurationId").quotes().pathname,
    element: (
      <AppWithSideMenu>
        <QuoteListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs(
        "Quotes",
        ({ gameId, configurationId }) => Path.game(s2GameId(gameId)).component(configurationId).quotes(),
        [MyGames, GamesBreadcrumb, GameComponentConfigurationsBreadcrumb]
      ),
      prefer: "left",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).component(":configurationId").quote(":quoteId").pathname,
    element: (
      <AppWithSideMenu>
        <QuoteListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs(
        "QUOTE",
        ({ gameId, configurationId, quoteId }) => Path.game(gameId).component(configurationId).quote(quoteId),
        [MyGames, GamesBreadcrumb, GameComponentConfigurationsBreadcrumb, GameComponentConfigurationQuotesBreadcrumb]
      ),
      prefer: "right",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).lanes().pathname,
    element: (
      <AppWithSideMenu>
        <FreightListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs("Lanes", ({ gameId }) => Path.game(gameId).lanes(), [MyGames, GamesBreadcrumb]),
      prefer: "left",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).lane(":laneId").link().pathname,
    element: (
      <AppWithSideMenu>
        <FreightListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs("TBC", ({ gameId, laneId }) => Path.game(gameId).lane(laneId).link(), [
        MyGames,
        GamesBreadcrumb,
        GameLanesBreadcrumb,
      ]),
      prefer: "right",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).financialModelling().pathname,
    element: (
      <AppWithSideMenu>
        <FinancialModelling />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs("Financial Modelling", ({ gameId }) => Path.game(gameId).financialModelling(), [
        MyGames,
        GamesBreadcrumb,
      ]),
      prefer: "left",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).royalties().pathname,
    element: (
      <AppWithSideMenu>
        <RoyaltiesListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs("Royalties", ({ gameId }) => Path.game(gameId).royalties(), [MyGames, GamesBreadcrumb]),
      prefer: "left",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(s2GameId(":gameId")).royalty(":royaltyId").pathname,
    element: (
      <AppWithSideMenu>
        <RoyaltiesListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs("Royalties", ({ gameId }) => Path.game(gameId).royalties(), [MyGames, GamesBreadcrumb]),
      prefer: "right",
      quickLinks: GameQuickLinks,
    },
  },
];
