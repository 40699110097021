import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { EmptyDetailCardWithLogin } from "../core/EmptyDetailCard";
import { useParams } from "react-router-dom";
import { PublicSellsheet } from "../sellsheets/PublicSellsheet";
import { SinglePageView } from "../core/SinglePageView";
import { LatestSizzle } from "../sizzle/LatestSizzle";
import { PublicRules } from "../rules/PublicRules";
import { ItemHeading } from "../core/font-sizes";
import { AddToSubmissionsButton } from "./AddToSubmissionsButton";
import { ImmutableGameTag } from "./ImmutableGameTag";
import { PublicImmutableGameName } from "./EditableGameName";
import { formatAge, formatDuration, formatPlayerCount } from "./formatters";
import { usePublicGame } from "./queries";
import { Designers } from "./Designers";
import { s2GameId } from "@seabrookstudios/types";

const KeyStat = ({ title, value }) => {
  return (
    <Box
      m={1}
      p={1}
      sx={{ border: 1, borderColor: "black", borderStyle: "solid", borderRadius: 1 }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <ItemHeading>{title}</ItemHeading>
      <Typography fontSize={24}>{value}</Typography>
    </Box>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {boolean} [props.designerView] is another designer looking at this?
 * @param {boolean} [props.publisherView] is a publisher account looking at this?
 */
export const PublicGameDetail = ({ gameId, designerView = false, publisherView = false }) => {
  const { data: publicGame, isLoading } = usePublicGame(gameId);

  if (isLoading) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (!publicGame) {
    return (
      <EmptyDetailCardWithLogin text="Game not found. Please check with the designer whether they have changed the visibility to public." />
    );
  }

  return (
    <Box width={960} maxWidth="90vw" m="auto">
      <Card elevation={1} square={false}>
        {designerView && (
          <Box sx={{ backgroundColor: "#A19EB8" }}>
            <Typography p={1} color="black" textAlign="center">
              This is a public version of a game shared by another designer.
            </Typography>
          </Box>
        )}
        {publisherView && (
          <Box sx={{ backgroundColor: "#A19EB8" }}>
            <Typography p={1} color="black" textAlign="center">
              This game is not in your submissions queue, you can add it with one click.
            </Typography>
          </Box>
        )}
        {!designerView && (
          <Stack direction="row" justifyContent="flex-end">
            <Box p={2}>
              <AddToSubmissionsButton gameId={publicGame.id} />
            </Box>
          </Stack>
        )}
        <Stack direction="row" justifyContent="space-between">
          <Box p={2}>
            <PublicImmutableGameName text={publicGame.name} />
            <ImmutableGameTag text={publicGame.tag} />
            <Designers designers={publicGame.designers} />
          </Box>
          <Stack p={1} direction="column" flex="0.5">
            <KeyStat title="Players" value={formatPlayerCount(publicGame.playerCount)} />
            <KeyStat title="Duration" value={formatDuration(publicGame.duration)} />
            <KeyStat title="Age" value={formatAge(publicGame.age)} />
          </Stack>
        </Stack>
        <List>
          <LatestSizzle url={publicGame.sizzle} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <PublicSellsheet url={publicGame.sellsheet} />
            </Grid>
            <Grid item xs={6}>
              <PublicRules url={publicGame.rules} />
            </Grid>
          </Grid>
        </List>
        {!designerView && (
          <Stack direction="row" justifyContent="flex-end">
            <Box p={2}>
              <AddToSubmissionsButton gameId={publicGame.id} />
            </Box>
          </Stack>
        )}
      </Card>
    </Box>
  );
};

export const PublicGameDetailFromRoute = () => {
  const gameId = s2GameId(useParams().gameId || "");

  return (
    <SinglePageView>
      <Box p={2}>
        <PublicGameDetail gameId={gameId} />
      </Box>
    </SinglePageView>
  );
};
