import { usePublisherAndContacts } from "@seabrookstudios/pitch2table";

/**
 * @param {any[]} submissions
 */
export const formatSubmissions = (submissions = []) => {
  if (submissions.length === 0) {
    return `No submissions`;
  }

  if (submissions.length === 1) {
    return `1 submission`;
  }

  return `${submissions.length} submissions`;
};

/**
 * @param {any[]} documents
 */
export const formatDocuments = (documents = []) => {
  if (documents.length === 0) {
    return `No documents`;
  }

  if (documents.length === 1) {
    return `1 document`;
  }

  return `${documents.length} documents`;
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
 */
export const usePublisherName = (publisherId) => {
  const { data: publisherAndContacts = [], isLoading } = usePublisherAndContacts(publisherId);

  const [publisher] = publisherAndContacts;
  const text = publisher ? publisher.name : "Error";

  return [isLoading, text];
};
