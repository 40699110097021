import React from "react";
import { NoContracts } from "./List";
import { useGameContracts } from "@seabrookstudios/pitch2table";
import { useGameIdFromRoute } from "../WithGameIdFromRoute";
import { ContractFromRoute } from "../../sellsheets/ContractFromRoute";
import { idExtractor, useMakeListAndDetail, useMakeListBuilder } from "../../core/useMakeLayout";
import { ListEntry } from "./ListEntry";
import { AddContractDialog } from "./AddContractDialog";
import { WarningBanner } from "./warnings";
import { Path } from "../../Path";

export const ListAndDetail = () => {
  const gameId = useGameIdFromRoute();

  const Action = () => <AddContractDialog gameId={gameId} />;

  const useMakeList = useMakeListBuilder(
    ListEntry,
    idExtractor,
    "Contracts",
    NoContracts,
    Action,
    WarningBanner,
    Path.game(gameId).link()
  );

  const useWrappedQuery = () => useGameContracts(gameId);

  const ListAndDetail = useMakeListAndDetail(useWrappedQuery, [], "contracts", useMakeList, ContractFromRoute);

  return <ListAndDetail />;
};
