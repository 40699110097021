import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch";

const GoalText = "Game archived – it will no longer show up in filter lists.";
const NotSetText = "Game not archived";

/**
 * @param {object} props
 * @param {boolean} props.archived
 * @param {(value: boolean) => void} props.onToggle
 */
export const ArchiveControl = ({ archived, onToggle }) => {
  const onChangeHandler = (e) => {
    onToggle(e.target.checked);
  };

  return (
    <ListItem>
      <ListItemText primary="Archive" secondary={archived ? GoalText : NotSetText}></ListItemText>
      <ListItemButton style={{ justifyContent: "flex-end" }}>
        <Switch defaultChecked={archived} onChange={onChangeHandler} />
      </ListItemButton>
    </ListItem>
  );
};
