import React from "react";
import Box from "@mui/material/Box";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "../Path";
import { StatusToText } from "./StatusToText";
import { CoverSquare } from "./CoverImages";
import { FollowUpDuePill } from "./FollowUpDuePill";
import { getSeverity } from "./publisher-interest/follow-up-severity";

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/pitch2table-core").GameSummary} props.item
 */
export const GameListEntry = ({ item }) => {
  const params = useParams();
  const navigate = useNavigate();

  const selected = params.gameId === item.id;

  const onClickHandler = () => {
    navigate(Path.user().game(item.id));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemAvatar>
        <CoverSquare game={item} width={48} height={48} />
      </ListItemAvatar>
      <ListItemText primary={item.name} secondary={item.tag} />
      <Stack direction="column" style={{ flexShrink: 0 }}>
        <ListItemIcon>{StatusToText[item.status]}</ListItemIcon>
        {item.nextAction && (
          <>
            <FollowUpDuePill severity={getSeverity(item.nextAction)} />
            <Box p={0.5} />
          </>
        )}
      </Stack>
    </ListItemButton>
  );
};
