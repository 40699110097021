import React from "react";
import { Path } from "../Path";
import { AppWithSideMenu } from "../core/AppWithSideMenu";
import { makeBreadcrumbs } from "../core/Breadcrumbs";
import { DocumentsListAndDetail } from "./ListAndDetail";
import { s2AttachmentId } from "../util/type-conversion";

export const routes = [
  {
    path: Path.documents().pathname,
    element: (
      <AppWithSideMenu>
        <DocumentsListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs("My Contracts", () => Path.documents(), []),
      prefer: "left",
    },
  },
  {
    path: Path.document(s2AttachmentId(":attachmentId")).pathname,
    element: (
      <AppWithSideMenu>
        <DocumentsListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs("My Contracts", () => Path.documents(), []),
      prefer: "right",
    },
  },
];
