import React from "react";
import { ListDetail } from "../web/ListDetail";
import { useFilters } from "../core/useFilters";
import { FilterList } from "../core/FilterList";
import { ListView } from "../core/ListView";

/**
 * @template T
 * @param {({ item }) => React.JSX.Element} ListEntry
 * @param {(item: T) => string} keyExtractor
 * @param {string} title
 * @param {() => React.JSX.Element} EmptyList
 * @param {() => React.JSX.Element} [Action]
 * @param {() => React.JSX.Element|undefined} [ListHeader]
 * @param {import("react-router-dom").To} [back]
 * @returns {(queryResult: import("@tanstack/react-query").UseQueryResult<T[]>, filterConfiguration: Filters<T>) => () => React.JSX.Element}
 */
export const useMakeListBuilder = (ListEntry, keyExtractor, title, EmptyList, Action, ListHeader, back) => {
  /**
   * @template T
   * @param {import("@tanstack/react-query").UseQueryResult<T[]>} queryResult
   * @param {Filters<T>} filters
   * @returns {() => React.JSX.Element}
   */
  const useMakeList = (queryResult, filters) => {
    const Filters = () => (
      <FilterList filters={filters.visibleFilters} filter={filters.filter} onFilterClicked={filters.onFilterClicked} />
    );

    return () => (
      <ListView
        back={back}
        title={title}
        Filters={Filters}
        Action={Action}
        EmptyList={EmptyList}
        shown={filters.filtered.length}
        refetch={queryResult.refetch}
        isLoading={queryResult.isLoading}
        isRefetching={queryResult.isRefetching}
      >
        {ListHeader && <ListHeader />}
        {filters.filtered.map((item) => (
          // @ts-ignore
          <ListEntry key={keyExtractor(item)} item={item} />
        ))}
      </ListView>
    );
  };

  return useMakeList;
};

/**
 * @param {object} item
 * @returns {string}
 */
export const idExtractor = (item) => item.id.toString();

/**
 * @template T
 * @param {() => import("@tanstack/react-query").UseQueryResult<T[]>} query
 * @param {Filter<T>[]} filters
 * @param {string} filterKey
 * @param {(queryResult: import("@tanstack/react-query").UseQueryResult<T[]>, filterConfiguration: Filters<T>) => () => React.JSX.Element} useMakeList
 * @param {() => React.JSX.Element} DetailView
 * @returns {() => React.JSX.Element}
 */
export const useMakeListAndDetail = (query, filters, filterKey, useMakeList, DetailView) => {
  const queryResult = query();
  const filterConfiguration = useFilters(queryResult.data || [], filters, filterKey);
  const ListView = useMakeList(queryResult, filterConfiguration);

  return () => <ListDetail ListView={ListView} DetailView={DetailView} />;
};

/**
 * @template T
 * @param {() => import("@tanstack/react-query").UseQueryResult<T[]>} query
 * @param {Filter<T>[]} filters
 * @param {string} filterKey
 * @param {(queryResult: import("@tanstack/react-query").UseQueryResult<T[]>, filterConfiguration: Filters<T>) => () => React.JSX.Element} useMakeList
 * @returns {() => React.JSX.Element}
 */
export const useMakeListView = (query, filters, filterKey, useMakeList) => {
  const queryResult = query();
  const filterConfiguration = useFilters(queryResult.data || [], filters, filterKey);
  const ListView = useMakeList(queryResult, filterConfiguration);

  return () => <ListView />;
};
