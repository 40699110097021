import React from "react";
import { useMyDocumentsRelatingToPublisher } from "@seabrookstudios/pitch2table";
import { idExtractor, useMakeListAndDetail, useMakeListBuilder } from "../core/useMakeLayout";
import { NoItemsFound } from "../publisher/NoItemsFound";
import { Path } from "../Path";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useAttachmentIdFromRoute, usePublisherIdFromRoute } from "../games/WithGameIdFromRoute";
import { formatFullDate } from "@seabrookstudios/pitch2table-core";
import { NonGameContractFromRoute } from "../sellsheets/ContractFromRoute";
import { AddContractToPublisher } from "../games/contracts/AddContractDialog";

const NoDocumentsFound = () => (
  <NoItemsFound>
    <Alert severity="info">
      Upload any contracts you have with this publisher that don't belong to a particular game e.g. NDA. Any other
      contracts that relate to a specific game will also be shown here but must be uploaded from the respective game.
    </Alert>
  </NoItemsFound>
);

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/pitch2table-core").NonGameContract} props.item
 */
export const MyPublisherDocumentListEntry = ({ item }) => {
  const publisherId = usePublisherIdFromRoute();
  const attachmentId = useAttachmentIdFromRoute();
  const navigate = useNavigate();

  const selected = attachmentId === item.id;

  const onClickHandler = () => {
    navigate(Path.publisher(publisherId).document(item.id));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemText primary={item.name || "No title"} secondary={`Signed ${formatFullDate(item.signedDate)}`} />
    </ListItemButton>
  );
};

export const PublishersDocumentsListAndDetail = () => {
  const publisherId = usePublisherIdFromRoute();
  const Action = () => <AddContractToPublisher publisherId={publisherId} />;
  const useMakeList = useMakeListBuilder(
    MyPublisherDocumentListEntry,
    idExtractor,
    "Documents",
    NoDocumentsFound,
    Action,
    undefined,
    Path.publisher(publisherId).link()
  );

  const useWrappedHook = () => useMyDocumentsRelatingToPublisher(publisherId);

  const ListAndDetail = useMakeListAndDetail(
    useWrappedHook,
    [],
    "publisher-documents",
    useMakeList,
    NonGameContractFromRoute
  );

  return <ListAndDetail />;
};
