import React from "react";
import Link from "@mui/material/Link";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardOutlined from "@mui/icons-material/ArrowForwardOutlined";
import { Link as RouterLink } from "react-router-dom";
import { Path } from "../Path";
import { useGameTopics } from "@seabrookstudios/pitch2table";

/**
 * @param {object} props
 * @param {import("react-router-dom").To} props.link
 * @param {import("react").ReactNode} props.primary
 * @param {import("react").ReactNode} props.secondary
 */
const MenuItem = ({ link, primary, secondary }) => {
  return (
    <ListItem>
      <ListItemText primary={primary} secondary={secondary} />
      <Link to={link} component={RouterLink}>
        <ListItemIcon>
          <ListItemButton>
            <ArrowForwardOutlined />
          </ListItemButton>
        </ListItemIcon>
      </Link>
    </ListItem>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const MessageBoardMenuItem = ({ gameId }) => {
  const { data: topics = [] } = useGameTopics(gameId);

  return (
    <MenuItem
      primary="Message Board"
      secondary={topics.length === 1 ? `1 thread` : `${topics.length} threads`}
      link={Path.game(gameId).topics()}
    />
  );
};
