import React from "react";
import { Path } from "../Path";
import { AppWithSideMenu } from "../core/AppWithSideMenu";
import { makeBreadcrumbs3 } from "../core/Breadcrumbs";
import { RoyaltyListForPublisher, RoyaltyListForType, RoyaltyListForYear } from "./ListAndDetail";
import { AppLink } from "../styleguide/AppLink";
import { RoyaltyCategoryLabels } from "./model";
import { RoyaltyCategories } from "./RoyaltyCategories";
import { usePublisherNameOrUnlinked } from "../publisher/queries";
import { RoyaltyTypeLabels } from "@seabrookstudios/pitch2table-core";

export const routes = [
  {
    path: Path.royalties().none().pathname,
    element: (
      <AppWithSideMenu>
        <RoyaltyCategories />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        () => (
          <AppLink key="royalties" to={Path.royalties().none()}>
            Royalties
          </AppLink>
        ),
      ]),
      prefer: "left",
    },
  },
  {
    path: Path.royalties().category(":category").pathname,
    element: (
      <AppWithSideMenu>
        <RoyaltyCategories />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        () => (
          <AppLink key="royalties" to={Path.royalties().none()}>
            Royalties
          </AppLink>
        ),
        ({ category }) => (
          <AppLink key="royalties-category" to={Path.royalties().category(category)}>
            {RoyaltyCategoryLabels[category]}
          </AppLink>
        ),
      ]),
      prefer: "right",
    },
  },
  {
    path: Path.royalties().byYear(":year").all().pathname,
    element: (
      <AppWithSideMenu>
        <RoyaltyListForYear />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        () => (
          <AppLink key="royalties" to={Path.royalties().none()}>
            Royalties
          </AppLink>
        ),
        () => (
          <AppLink key="royalties-by-year" to={Path.royalties().category("by-year")}>
            {RoyaltyCategoryLabels["by-year"]}
          </AppLink>
        ),
        ({ year }) => (
          <AppLink key={year} to={Path.royalties().byYear(year).all()}>
            {year}
          </AppLink>
        ),
      ]),
      prefer: "left",
    },
  },
  {
    path: Path.royalties().byYear(":year").royalty(":royaltyId").pathname,
    element: (
      <AppWithSideMenu>
        <RoyaltyListForYear />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        () => (
          <AppLink key="royalties" to={Path.royalties().none()}>
            Royalties
          </AppLink>
        ),
        () => (
          <AppLink key="royalties-by-year" to={Path.royalties().category("by-year")}>
            {RoyaltyCategoryLabels["by-year"]}
          </AppLink>
        ),
        ({ year }) => (
          <AppLink key={year} to={Path.royalties().byYear(year).all()}>
            {year}
          </AppLink>
        ),
        ({ year, royaltyId }) => (
          <AppLink key={royaltyId} to={Path.royalties().byYear(year).royalty(royaltyId)}>
            Royalty
          </AppLink>
        ),
      ]),
      prefer: "right",
    },
  },
  {
    path: Path.royalties().byPublisher(":publisherId").all().pathname,
    element: (
      <AppWithSideMenu>
        <RoyaltyListForPublisher />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        () => (
          <AppLink key="royalties" to={Path.royalties().none()}>
            Royalties
          </AppLink>
        ),
        () => (
          <AppLink key="royalties-by-publisher" to={Path.royalties().category("by-publisher")}>
            {RoyaltyCategoryLabels["by-publisher"]}
          </AppLink>
        ),
        ({ publisherId }) => {
          const publisherName = usePublisherNameOrUnlinked(publisherId);

          return (
            <AppLink key={publisherId} to={Path.royalties().byPublisher(publisherId).all()}>
              {publisherName}
            </AppLink>
          );
        },
      ]),
      prefer: "left",
    },
  },
  {
    path: Path.royalties().byPublisher(":publisherId").royalty(":royaltyId").pathname,
    element: (
      <AppWithSideMenu>
        <RoyaltyListForPublisher />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        () => (
          <AppLink key="royalties" to={Path.royalties().none()}>
            Royalties
          </AppLink>
        ),
        () => (
          <AppLink key="royalties-by-publisher" to={Path.royalties().category("by-publisher")}>
            {RoyaltyCategoryLabels["by-publisher"]}
          </AppLink>
        ),
        ({ publisherId }) => {
          const publisherName = usePublisherNameOrUnlinked(publisherId);

          return (
            <AppLink key={publisherId} to={Path.royalties().byPublisher(publisherId).all()}>
              {publisherName}
            </AppLink>
          );
        },
        ({ publisherId, royaltyId }) => (
          <AppLink key={royaltyId} to={Path.royalties().byPublisher(publisherId).royalty(royaltyId)}>
            Royalty
          </AppLink>
        ),
      ]),
      prefer: "right",
    },
  },
  {
    path: Path.royalties().byType(":type").all().pathname,
    element: (
      <AppWithSideMenu>
        <RoyaltyListForType />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        () => (
          <AppLink key="royalties" to={Path.royalties().none()}>
            Royalties
          </AppLink>
        ),
        () => (
          <AppLink key="royalties-by-type" to={Path.royalties().category("by-type")}>
            {RoyaltyCategoryLabels["by-type"]}
          </AppLink>
        ),
        ({ type }) => (
          <AppLink key={type} to={Path.royalties().byType(type).all()}>
            {RoyaltyTypeLabels[type]}
          </AppLink>
        ),
      ]),
      prefer: "left",
    },
  },
  {
    path: Path.royalties().byType(":type").royalty(":royaltyId").pathname,
    element: (
      <AppWithSideMenu>
        <RoyaltyListForType />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        () => (
          <AppLink key="royalties" to={Path.royalties().none()}>
            Royalties
          </AppLink>
        ),
        () => (
          <AppLink key="royalties-by-type" to={Path.royalties().category("by-type")}>
            {RoyaltyCategoryLabels["by-type"]}
          </AppLink>
        ),
        ({ type }) => (
          <AppLink key={type} to={Path.royalties().byType(type).all()}>
            {RoyaltyTypeLabels[type]}
          </AppLink>
        ),
        ({ type, royaltyId }) => (
          <AppLink key={royaltyId} to={Path.royalties().byType(type).royalty(royaltyId)}>
            Royalty
          </AppLink>
        ),
      ]),
      prefer: "right",
    },
  },
];
