import React, { useContext } from "react";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import CropPortraitIcon from "@mui/icons-material/CropPortrait";
import { LayoutContext } from "../layout/use-layout-mode";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { SideMenuOption } from "./SideMenuOption";

export const ChangeLayoutButton = () => {
  const { singleView, toggleView } = useContext(LayoutContext);

  const Icon = singleView ? VerticalSplitIcon : CropPortraitIcon;

  const onClickHandler = () => {
    toggleView();
  };

  return (
    <SideMenuOption
      onClick={onClickHandler}
      tooltip="Toggle between two-column and single column view"
      selected={false}
      label="Layout"
    >
      <Icon />
    </SideMenuOption>
  );
};

const SideMenuOptionAlt = ({ tooltip, onClick, children }) => {
  const onClickHandler = () => {
    onClick();
  };

  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={onClickHandler}>{children}</IconButton>
    </Tooltip>
  );
};

export const ChangeLayoutButtonSmall = () => {
  const { singleView, toggleView } = useContext(LayoutContext);

  const Icon = singleView ? VerticalSplitIcon : CropPortraitIcon;

  const onClickHandler = () => {
    toggleView();
  };

  return (
    <SideMenuOptionAlt onClick={onClickHandler} tooltip="Toggle between two-column and single column view">
      <Icon />
    </SideMenuOptionAlt>
  );
};
