import React from "react";
import { ListDetail } from "../web/ListDetail";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { Path } from "../Path";
import { useNavigate } from "react-router-dom";
import { useCategoryFromRoute } from "./params";
import {
  RoyaltiesByGameId,
  RoyaltiesByPublisher,
  RoyaltiesByType,
  RoyaltiesByYear,
  RoyaltiesListAndDetail,
} from "./ListAndDetail";
import { RoyaltyCategoriesList } from "../settings/RoyaltyCategoriesList";

export const RoyaltyCategories = () => {
  const category = useCategoryFromRoute();
  const navigate = useNavigate();

  const onListItemSelected = (id) => {
    navigate(Path.royalties().category(id));
  };

  const SelectedCategory = () => {
    if (category === "all") {
      return <RoyaltiesListAndDetail />;
    }
    if (category === "by-year") {
      return <RoyaltiesByYear />;
    }
    if (category === "by-game") {
      return <RoyaltiesByGameId />;
    }
    if (category === "by-publisher") {
      return <RoyaltiesByPublisher />;
    }
    if (category === "by-type") {
      return <RoyaltiesByType />;
    }

    return <EmptyDetailCard text="Select setting group from list on the left" />;
  };

  const ListView = () => (
    <RoyaltyCategoriesList selectedListItemId={category} onListItemSelected={onListItemSelected} />
  );

  return <ListDetail ListView={ListView} DetailView={SelectedCategory} />;
};
