import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardOutlined from "@mui/icons-material/ArrowForwardOutlined";
import Check from "@mui/icons-material/Check";
import ContentCopy from "@mui/icons-material/ContentCopy";
import OpenInNewOutlined from "@mui/icons-material/OpenInNewOutlined";
import { enqueueSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import { useCopyToClipboard, useHover } from "usehooks-ts";
import { Path } from "../Path";
import { useGameSellsheets } from "@seabrookstudios/pitch2table";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/types").Url|null} props.url
 * @param {boolean} props.isPitching
 */
export const UploadedSellsheet = ({ gameId, url, isPitching }) => {
  const { data: sellsheets = [] } = useGameSellsheets(gameId);

  const [, copy] = useCopyToClipboard();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  const copyUrlToClipboard = () => {
    if (!url) {
      return;
    }

    copy(url);
    enqueueSnackbar("Url copied to clipboard", { variant: "info" });
  };

  const notUploadedText = isPitching ? "Alert - Status is pitching now and no sellsheet uploaded." : "Not Uploaded";

  return (
    <ListItem ref={hoverRef}>
      <ListItemText
        primary="Sellsheets"
        secondary={url ? `Versions uploaded: ${sellsheets.length}` : notUploadedText}
      />
      {isHover && url && (
        <>
          <ListItemIcon>
            <ListItemButton onClick={copyUrlToClipboard}>
              <ContentCopy />
            </ListItemButton>
          </ListItemIcon>
          <a href={url} target="_blank" rel="noreferrer">
            <ListItemIcon>
              <ListItemButton>
                <OpenInNewOutlined />
              </ListItemButton>
            </ListItemIcon>
          </a>
        </>
      )}
      {url && (
        <ListItemIcon>
          <Box justifyContent="center" alignItems="center" display="flex" flex={1}>
            <Chip icon={<Check />} label="DONE" color="success" />
          </Box>
        </ListItemIcon>
      )}
      <Link to={Path.game(gameId).sellsheets()} component={RouterLink}>
        <ListItemIcon>
          <ListItemButton>
            <ArrowForwardOutlined />
          </ListItemButton>
        </ListItemIcon>
      </Link>
    </ListItem>
  );
};
