import React from "react";
import Card from "@mui/material/Card";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { useAttachmentIdFromRoute, useGameIdFromRoute } from "../games/WithGameIdFromRoute";
import { useGameSellsheets } from "@seabrookstudios/pitch2table";
import { ImageOrPdfViewer } from "../core/ImageOrPdfViewer";

export const SellsheetFromRoute = () => {
  const gameId = useGameIdFromRoute();
  const attachmentId = useAttachmentIdFromRoute();
  const { data: sellsheets = [] } = useGameSellsheets(gameId);

  if (!attachmentId) {
    return <EmptyDetailCard text="Select a sellsheet from the list on the left." />;
  }

  const sellsheet = sellsheets.find((sellsheet) => sellsheet.id === attachmentId);
  return (
    <Card elevation={1} square={false}>
      <ImageOrPdfViewer document={sellsheet} />
    </Card>
  );
};
