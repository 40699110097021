import React from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { useUserProfile } from "../../user/queries";
import { AlwaysOnAppLink } from "../../styleguide/AppLink";
import { Path } from "../../Path";

export const CurrencyNotice = () => {
  const { data: profile } = useUserProfile();

  if (!profile) {
    return;
  }

  if (profile && !profile.currencySettings.preferredCurrency) {
    return (
      <Alert severity="error">
        Set your <AlwaysOnAppLink to={Path.settings().category("your-location")}>currency preferences</AlwaysOnAppLink>{" "}
        to use this feature.
      </Alert>
    );
  }

  return (
    <Box p={1}>
      <Alert severity="info">
        All amounts are shown in{" "}
        <AlwaysOnAppLink to={Path.settings().category("your-location")}>
          {profile.currencySettings.preferredCurrency}
        </AlwaysOnAppLink>{" "}
        based on available exchange rates.
      </Alert>
    </Box>
  );
};
