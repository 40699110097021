import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useGame } from "@seabrookstudios/pitch2table";
import { useDesigners } from "../queries";
import { formatFullName } from "../../contacts/model";

const Label = "Contract Designers";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/types").UserId[]} props.value
 * @param {(designerIds: import("@seabrookstudios/types").UserId[]) => void} props.onChange
 * @param {boolean} [props.disabled]
 */
export const ContractDesignersSelect = ({ gameId, value = [], onChange, disabled = false }) => {
  const { data: game } = useGame(gameId);
  const { data: designers = [] } = useDesigners(game ? game.designers : []);
  const [selected, setSelected] = useState(value);

  const handleChange = (event) => {
    const { value } = event.target;

    setSelected(typeof value === "string" ? value.split(",") : value);
  };

  const handleClose = async () => {
    await onChange(selected);
  };

  return (
    <FormControl sx={{ width: "100%" }} margin="normal" disabled={disabled}>
      <InputLabel id="designers-publishers-label">{Label}</InputLabel>
      <Select
        id="designers-publishers"
        labelId="designers-publishers-label"
        value={selected}
        multiple
        onChange={handleChange}
        onClose={handleClose}
        placeholder={Label}
        label={Label}
        input={<OutlinedInput label={Label} />}
        renderValue={(items = []) => {
          return items
            .map((designerId) => {
              const contact = designers.find((d) => d.id === designerId);
              return contact ? formatFullName(contact) : "";
            })
            .join(", ");
        }}
      >
        {designers.map((d) => (
          <MenuItem key={d.id} value={d.id}>
            <Checkbox id={d.id} checked={selected.includes(d.id)} />
            <ListItemText primary={formatFullName(d)} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        By associating a contract with a publisher you will also be able to attribute royalties from this contract to
        the publisher.
      </FormHelperText>
    </FormControl>
  );
};
