import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch";

const PublicText = "Anyone with the link can view this game.";
const PrivateText = "Only co-designers and publishers you have submitted to have access";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").VisibilityTypes} props.visibility
 * @param {Function} props.onToggle
 */
export const GameVisibilityControl = ({ visibility, onToggle }) => {
  const onChangeHandler = (e) => {
    onToggle(e.target.checked ? "Public" : "Private");
  };

  return (
    <ListItem>
      <ListItemText primary="Visibility" secondary={visibility === "Public" ? PublicText : PrivateText}></ListItemText>
      <ListItemButton style={{ justifyContent: "flex-end" }}>
        <Switch defaultChecked={visibility === "Public"} onChange={onChangeHandler} />
      </ListItemButton>
    </ListItem>
  );
};
