import React from "react";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import { useContact } from "@seabrookstudios/pitch2table";
import CardMedia from "@mui/material/CardMedia";
import { FullScreenDialog } from "../core/FullScreenDialog";
import { UploadUserAvatar } from "./UploadUserAvatar";
import { useUserProfile } from "../user/queries";

/**
 * @param {object} props
 * @param {import('@seabrookstudios/types').UserId|undefined} props.userId
 */
export const UserAvatar = ({ userId }) => {
  const { data: profile } = useContact(userId);

  if (!profile) {
    return <Skeleton variant="circular" />;
  }

  return <Avatar src={profile ? profile.pictureUrl : ""} />;
};

export const ThisUserAvatar = () => {
  const { data: profile } = useUserProfile();

  if (!profile) {
    return <Skeleton variant="circular" />;
  }

  return <Avatar src={profile ? profile.pictureUrl : ""} />;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").AdminOnlyPublicContact} props.contact
 * @param {boolean} props.editable
 */
export const UserAvatarCardCover = ({ contact, editable }) => {
  if (editable && !contact.pictureUrl) {
    return <UploadUserAvatar />;
  }

  return (
    <FullScreenDialog
      Icon={() => (
        <CardMedia
          component="img"
          sx={{ maxHeight: 200, objectFit: "contain" }}
          image={contact.pictureUrl}
          title={`Avatar image for ${contact.preferredName}`}
        />
      )}
      iconAlign="center"
      fullscreen={false}
      title="Replace User Avatar"
      canSave={true}
    >
      <UploadUserAvatar />
    </FullScreenDialog>
  );
};
