import React from "react";
import { useGame, useMySubmissionsToPublisher } from "@seabrookstudios/pitch2table";
import { Path } from "../Path";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { PublisherStatus } from "../games/PublisherStatus";
import { CoverSquare } from "../games/CoverImages";
import { formatFollowUp, showAlarm } from "@seabrookstudios/pitch2table-core";
import { FollowUpDuePill } from "../games/FollowUpDuePill";
import { getSeverity } from "../games/publisher-interest/follow-up-severity";
import { formatSubmissions } from "./model";
import { ListHeaderWithExpand } from "../core/ListHeaderWithExpand";

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/pitch2table-core").DesignerPublisherGameView} props.submission
 */
const MySubmissionListEntry = ({ submission }) => {
  const { data: game, isLoading } = useGame(submission.gameId);

  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(Path.game(submission.gameId).publisher(submission.publisherId));
  };

  return (
    <ListItemButton alignItems="flex-start" onClick={onClickHandler}>
      <ListItemAvatar>
        {isLoading || !game ? <CircularProgress /> : <CoverSquare game={game} width={48} height={48} />}
      </ListItemAvatar>
      <ListItemText primary={game && game.name} secondary={formatFollowUp(submission)} />
      {showAlarm(submission.nextAction) && <FollowUpDuePill severity={getSeverity(submission.nextAction)} />}
      <Box p={0.5} />
      <ListItemIcon>
        <PublisherStatus status={submission.status} />
      </ListItemIcon>
    </ListItemButton>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} props.publisherId
 */
export const MySubmissionsWithPublisher = ({ publisherId }) => {
  const { data: submissions = [], isLoading } = useMySubmissionsToPublisher(publisherId);

  if (isLoading) {
    return (
      <Card elevation={1} square={false}>
        <CircularProgress />
      </Card>
    );
  }

  return (
    <Card elevation={1} square={false}>
      <Box p={2}>
        <List>
          <ListHeaderWithExpand title="Submissions" secondary={formatSubmissions(submissions)} />
          {submissions.map((submission) => (
            <MySubmissionListEntry key={submission.id} submission={submission} />
          ))}
        </List>
      </Box>
    </Card>
  );
};
