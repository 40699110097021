import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch";

const GoalText = "Signed in 2025. That's the goal.";
const NotSetText = "Not a goal for 2025.";

/**
 * @param {object} props
 * @param {("2025"|"not-set"|undefined)} props.personalGoal
 * @param {(value: ("2025"|"not-set")) => void} props.onToggle
 */
export const PersonalGoalControl = ({ personalGoal, onToggle }) => {
  const onChangeHandler = (e) => {
    onToggle(e.target.checked ? "2025" : "not-set");
  };

  return (
    <ListItem>
      <ListItemText primary="2025 Goal" secondary={personalGoal === "2025" ? GoalText : NotSetText}></ListItemText>
      <ListItemButton style={{ justifyContent: "flex-end" }}>
        <Switch defaultChecked={personalGoal === "2025"} onChange={onChangeHandler} />
      </ListItemButton>
    </ListItem>
  );
};
