export const Path = {
  Home: "/",
  Error: "/error",
  Mobile: "/mobile",
  Support: "/support",
  Updates: "/updates",
  SelectAccount: "/select-account",
  settings: () => ({
    none: () => "/settings",
    /**
     * @param {('your-location'|'about-you'|'notifications'|'invites'|":category")} category
     */
    category: (category) => ({ pathname: `/settings/${category}`, search: window.location.search }),
  }),
  Landing: {
    AddToSubmissionsBase: `/landing/add-to-submissions-logged-out`,
    AddToSubmissionsBaseLoggedIn: `/landing/add-to-submissions-logged-in`,
    /**
     * @param {import("@seabrookstudios/types").GameId} gameId
     */
    AddToSubmissions: (gameId) => `/landing/add-to-submissions-logged-out?gameId=${gameId}`,
  },
  Publisher: {
    New: "/publisher/new",
  },
  user: () => ({
    /**
     * @param {import("@seabrookstudios/types").GameId} gameId
     */
    game: (gameId) => ({ pathname: `/games/${gameId}`, search: window.location.search }),
    games: (gameId) => ({
      sellsheets: `/games/${gameId}/sellsheets`,
    }),
  }),
  games: () => ({ pathname: `/games`, search: window.location.search }),
  /**
   * @param {import("@seabrookstudios/types").GameId|":gameId"} gameId
   */
  game: (gameId) => ({
    public: () => ({ pathname: `/username/${gameId}`, search: window.location.search }),
    link: () => ({ pathname: `/games/${gameId}`, search: window.location.search }),
    sellsheets: () => ({ pathname: `/games/${gameId}/sellsheets`, search: window.location.search }),
    /**
     * @param {import("@seabrookstudios/pitch2table-core").AttachmentId} attachmentId
     */
    sellsheet: (attachmentId) => ({
      pathname: `/games/${gameId}/sellsheets/${attachmentId}`,
      search: window.location.search,
    }),
    contracts: () => ({ pathname: `/games/${gameId}/contracts`, search: window.location.search }),
    /**
     * @param {import("@seabrookstudios/pitch2table-core").AttachmentId} attachmentId
     */
    contract: (attachmentId) => ({
      pathname: `/games/${gameId}/contracts/${attachmentId}`,
      search: window.location.search,
    }),
    rules: () => ({ pathname: `/games/${gameId}/rules`, search: window.location.search }),
    /**
     * @param {import("@seabrookstudios/pitch2table-core").AttachmentId} attachmentId
     */
    rule: (attachmentId) => ({ pathname: `/games/${gameId}/rules/${attachmentId}`, search: window.location.search }),
    publishers: () => ({ pathname: `/games/${gameId}/publishers`, search: window.location.search }),
    /**
     * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
     */
    publisher: (publisherId) => ({
      pathname: `/games/${gameId}/publishers/${publisherId}`,
      search: window.location.search,
    }),
    /**
     * @param {import("@seabrookstudios/types").UserId} designerId
     */
    designer: (designerId) => ({
      pathname: `/games/${gameId}/designers/${designerId}`,
      search: window.location.search,
    }),
    designers: () => ({ pathname: `/games/${gameId}/designers`, search: window.location.search }),
    /**
     * @param {string} configurationId
     */
    component: (configurationId) => ({
      link: () => ({
        pathname: `/games/${gameId}/components/${configurationId}`,
        search: window.location.search,
      }),
      quotes: () => ({
        pathname: `/games/${gameId}/components/${configurationId}/quotes`,
        search: window.location.search,
      }),
      quote: (quoteId) => ({
        pathname: `/games/${gameId}/components/${configurationId}/quotes/${quoteId}`,
        search: window.location.search,
      }),
    }),
    components: () => ({ pathname: `/games/${gameId}/components`, search: window.location.search }),
    financialModelling: () => ({ pathname: `/games/${gameId}/financial-modelling`, search: window.location.search }),
    lanes: () => ({ pathname: `/games/${gameId}/lanes`, search: window.location.search }),
    /**
     * @param {string} laneId
     */
    lane: (laneId) => ({
      link: () => ({ pathname: `/games/${gameId}/lanes/${laneId}`, search: window.location.search }),
      quotes: () => ({
        pathname: `/games/${gameId}/lanes/${laneId}/quotes`,
        search: window.location.search,
      }),
      quote: (quoteId) => ({
        pathname: `/games/${gameId}/lanes/${laneId}/quotes/${quoteId}`,
        search: window.location.search,
      }),
    }),
    royalties: () => ({
      pathname: `/games/${gameId}/royalties`,
      search: window.location.search,
    }),
    /**
     * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventId|":royaltyId"} royaltyId
     */
    royalty: (royaltyId) => ({
      pathname: `/games/${gameId}/royalties/${royaltyId}`,
      search: window.location.search,
    }),
    topics: () => ({
      pathname: `/games/${gameId}/topics`,
      search: window.location.search,
    }),
    /**
     * @param {import("@seabrookstudios/pitch2table-core").TopicId|":topicId"} topicId
     */
    topic: (topicId) => ({
      pathname: `/games/${gameId}/topics/${topicId}`,
      search: window.location.search,
    }),
  }),
  royalties: () => ({
    none: () => ({ pathname: `/royalties`, search: window.location.search }),
    /**
     * @param {RoyaltyCategories|":category"} category
     */
    category: (category) => ({ pathname: `/royalties/${category}`, search: window.location.search }),
    /**
     * @param {string} year
     */
    byYear: (year) => ({
      all: () => ({ pathname: `/royalties/years/${year}`, search: window.location.search }),
      /**
       * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventId|":royaltyId"} royaltyId
       */
      royalty: (royaltyId) => ({ pathname: `/royalties/years/${year}/${royaltyId}`, search: window.location.search }),
    }),
    /**
     * @param {import("@seabrookstudios/pitch2table-core").PublisherId|"unlinked"|":publisherId"} publisherId
     */
    byPublisher: (publisherId) => ({
      all: () => ({ pathname: `/royalties/publishers/${publisherId}`, search: window.location.search }),
      /**
       * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventId|":royaltyId"} royaltyId
       */
      royalty: (royaltyId) => ({
        pathname: `/royalties/publishers/${publisherId}/${royaltyId}`,
        search: window.location.search,
      }),
    }),
    /**
     * @param {import("@seabrookstudios/pitch2table-core").RoyaltyType|":type"} type
     */
    byType: (type) => ({
      all: () => ({ pathname: `/royalties/types/${type}`, search: window.location.search }),
      /**
       * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventId|":royaltyId"} royaltyId
       */
      royalty: (royaltyId) => ({
        pathname: `/royalties/types/${type}/${royaltyId}`,
        search: window.location.search,
      }),
    }),
  }),
  documents: () => ({ pathname: `/documents`, search: window.location.search }),
  /**
   * @param {import("@seabrookstudios/pitch2table-core").AttachmentId} attachmentId
   */
  document: (attachmentId) => ({ pathname: `/documents/${attachmentId}`, search: window.location.search }),
  publishers: () => ({ pathname: `/publishers`, search: window.location.search }),
  /**
   * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
   */
  publisher: (publisherId) => ({
    link: () => ({ pathname: `/publishers/${publisherId}`, search: window.location.search }),
    submissions: () => ({ pathname: `/publishers/${publisherId}/submissions`, search: window.location.search }),
    documents: () => ({ pathname: `/publishers/${publisherId}/documents`, search: window.location.search }),
    contacts: () => ({ pathname: `/publishers/${publisherId}/contacts`, search: window.location.search }),
    /**
     * @param {import("@seabrookstudios/pitch2table-core").AttachmentId} attachmentId
     */
    document: (attachmentId) => ({
      pathname: `/publishers/${publisherId}/documents/${attachmentId}`,
      search: window.location.search,
    }),
  }),
  public: () => ({
    /**
     * @param {string} username
     */
    designer: (username) => ({
      pathname: `/d/${username}`,
      search: window.location.search,
    }),
  }),
};
