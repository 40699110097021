import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { UploadedSellsheet } from "../sellsheets/UploadedSellsheet";
import { UploadedRules } from "../rules/UploadedRules";
import { enqueueSnackbar } from "notistack";
import { UploadedSizzle } from "../sizzle/UploadedSizzle";
import { isStringEmpty } from "../core/validation";
import { formatPlayerCount, formatAge, formatDuration } from "./formatters";
import { useGameIdFromRoute } from "./WithGameIdFromRoute";
import { StatusDropDown } from "./StatusDropDown";
import { PublicGameDetail } from "./PublicGameDetail";
import { useGame, useMyGames, useUpdateGameV2 } from "@seabrookstudios/pitch2table";
import { GameCardCover } from "./CoverImages";
import { ContractCardForGame } from "./contracts/ContractCardForGame";
import { LastUpdated } from "../core/LastUpdated";
import { PublisherInterestForGameMenuItem } from "./PublisherInterestForGameMenuItem";
import { GameVisibilityControl } from "./GameVisibilityControl";
import { EditableInternalId } from "./InternalId";
import { GameDesignersMenuItem } from "./GameDesignersMenuItem";
import { EditableHeading } from "./EditableHeading";
import { EditableText } from "./EditableText";
import { EditableListItem } from "./EditableListItem";
import { EditableListItemText } from "../core/EditableListItemText";
import { ArrowListItem } from "../core/ArrowListItem";
import { Path } from "../Path";
import { Features } from "./features";
import { showContracts } from "./contracts/model";
import { RoyaltyCardForGame } from "./royalties/RoyaltyCardForGame";
import { GameQuickLinks } from "./GameQuickLinks";
import { MessageBoardMenuItem } from "./MessageBoardMenuItem";
import { PersonalGoalControl } from "./PersonalGoalControl";
import { ArchiveControl } from "./ArchiveControl";
import { useDocumentTitle } from "usehooks-ts";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
const GameDetail = ({ gameId }) => {
  const { isLoading, data: game, error } = useGame(gameId);

  const onUpdateSuccess = () => {
    enqueueSnackbar("Saved", { variant: "success" });
  };

  const onUpdateFailure = () => {
    enqueueSnackbar("Update failed", { variant: "error" });
  };

  const updateGame = useUpdateGameV2(gameId, onUpdateSuccess, onUpdateFailure);
  /**
   * @param {string} name
   */
  const updateName = (name) => {
    if (isStringEmpty(name)) {
      return;
    }

    updateGame.mutate({
      name,
      sizzle: undefined,
      bgg: undefined,
      personalGoal: undefined,
    });
  };

  /**
   * @param {string} tag
   */
  const updateTag = (tag) => {
    if (isStringEmpty(tag)) {
      return;
    }

    updateGame.mutate({
      tag,
      sizzle: undefined,
      bgg: undefined,
      personalGoal: undefined,
    });
  };

  /**
   * @param {string} description
   */
  const updateDescription = (description) => {
    if (isStringEmpty(description)) {
      return;
    }

    updateGame.mutate({
      description,
      sizzle: undefined,
      bgg: undefined,
      personalGoal: undefined,
    });
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").DesignerGameStatus} status
   */
  const updateStatus = (status) => {
    if (!gameId) {
      return;
    }

    updateGame.mutate({
      status,
      sizzle: undefined,
      bgg: undefined,
      personalGoal: undefined,
    });
  };

  /**
   * @param {string} playerCount
   */
  const updatePlayerCount = (playerCount) => {
    if (isStringEmpty(playerCount)) {
      return;
    }

    updateGame.mutate({
      playerCount,
      sizzle: undefined,
      bgg: undefined,
      personalGoal: undefined,
    });
  };

  /**
   * @param {string} duration
   */
  const updateDuration = (duration) => {
    if (isStringEmpty(duration)) {
      return;
    }

    updateGame.mutate({ duration, sizzle: undefined, bgg: undefined, personalGoal: undefined });
  };

  /**
   * @param {number} age
   */
  const updateAge = (age) => {
    if (age <= 0) {
      return;
    }

    updateGame.mutate({ age, sizzle: undefined, bgg: undefined, personalGoal: undefined });
  };

  /**
   * @param {number} year
   */
  const updateYear = (year) => {
    if (year <= 0) {
      return;
    }

    updateGame.mutate({ year, sizzle: undefined, bgg: undefined, personalGoal: undefined });
  };

  /**
   * @param {string} internalId
   */
  const updateInternalId = (internalId) => {
    if (isStringEmpty(internalId)) {
      return;
    }

    updateGame.mutate({ internalId, sizzle: undefined, bgg: undefined, personalGoal: undefined });
  };

  /**
   * @param {import("@seabrookstudios/types").Url} bgg
   */
  const updateBGG = (bgg) => {
    updateGame.mutate({ bgg, sizzle: undefined, personalGoal: undefined });
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").VisibilityTypes} visibility
   */
  const updateVisibility = (visibility) => {
    updateGame.mutate({ visibility, sizzle: undefined, bgg: undefined, personalGoal: undefined });
  };

  /**
   * @param {("2025"|"not-set")} personalGoal
   */
  const updatePersonalGoal = (personalGoal) => {
    updateGame.mutate({ personalGoal, sizzle: undefined, bgg: undefined });
  };

  /**
   * @param {boolean} archived
   */
  const updateArchived = (archived) => {
    updateGame.mutate({ archived, sizzle: undefined, bgg: undefined, personalGoal: undefined });
  };

  useDocumentTitle(game ? game.name : "Pitch2Table");

  if (isLoading) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (!game || !gameId) {
    if (error instanceof Error) {
      enqueueSnackbar("Error loading content", { variant: "error" });
    }

    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <ErrorOutline />
        </Box>
      </Card>
    );
  }

  return (
    <>
      <Card elevation={1} square={false}>
        {!Features.BaseCampLayout && <GameQuickLinks />}
        <Box p={1}>
          <StatusDropDown value={game.status} onChange={updateStatus} />
        </Box>
      </Card>
      <Box p={0.5} />
      <Card elevation={1} square={false}>
        <List>
          <Stack direction="row">
            <Stack direction="row" p={2} flex={1} justifyContent="space-between">
              <Chip label={game.visibility} color={game.visibility === "Public" ? "warning" : "default"} size="small" />
              <EditableInternalId
                title="Project ID"
                text={game.internalId}
                onSave={updateInternalId}
                helperText="The project id is your personal reference for this game. Some designers give their games a special code that never changes which when the game name changes."
              />
            </Stack>
          </Stack>
          <Box p={1}>
            <GameCardCover game={game} editable />
          </Box>
          <Stack direction="row">
            <Box p={2} pt={0}>
              <EditableHeading
                text={game.name}
                onSave={updateName}
                helperText="A good pitch starts with a good name."
                title="Game Name"
              />
              <EditableText
                text={game.tag}
                onSave={updateTag}
                helperText="A short snappy tagline or brief description"
                title="Short Description"
              />
            </Box>
          </Stack>
          <Stack direction="row">
            <ListItem>
              <EditableListItemText
                primary="Players"
                onSave={updatePlayerCount}
                secondary={game.playerCount}
                formatter={formatPlayerCount}
                helperText="How many players does this game support e.g. 2-4, solo, 2, 1-2 or 4"
              />
            </ListItem>
            <ListItem>
              <EditableListItemText
                primary="Duration"
                onSave={updateDuration}
                secondary={game.duration}
                formatter={formatDuration}
                helperText="How long does a game usually go for e.g. 30-60 minutes, 15 minutes per-player "
              />
            </ListItem>
            <ListItem>
              <EditableListItemText
                primary="Age"
                onSave={updateAge}
                secondary={game.age}
                formatter={formatAge}
                helperText="Minimum age for players to enjoy the game."
              />
            </ListItem>
          </Stack>
          <Stack direction="row">
            <ListItem>
              <EditableListItem
                text={game.description}
                onSave={updateDescription}
                helperText="A long description of your game (optional)"
                title="Long Description"
              />
            </ListItem>
          </Stack>
        </List>
      </Card>
      {showContracts(game) && (
        <>
          <Box p={0.5} />
          <RoyaltyCardForGame game={game} />
        </>
      )}
      {showContracts(game) && (
        <>
          <Box p={0.5} />
          <ContractCardForGame game={game} />
        </>
      )}
      <Box p={0.5} />
      <Card elevation={1} square={false}>
        <List>
          <PublisherInterestForGameMenuItem gameId={gameId} />
          <UploadedSellsheet url={game.sellsheet} gameId={gameId} isPitching={game.status === "PitchingNow"} />
          <UploadedRules url={game.rules} gameId={gameId} />
          <UploadedSizzle url={game.sizzle} gameId={gameId} />
        </List>
      </Card>
      <Box p={0.5} />
      <Card elevation={1} square={false}>
        <List>
          <MessageBoardMenuItem gameId={game.id} />
          <GameDesignersMenuItem gameId={game.id} designers={game.designers} />
          <ListItem>
            <EditableListItem
              helperText="The year you started this project. Purely informational"
              onSave={updateYear}
              text={`${game.year}`}
              title="Year Started"
            />
          </ListItem>
          <ListItem>
            <EditableListItem
              helperText="Link to game on BGG"
              onSave={updateBGG}
              text={game.bgg}
              title="BoardGameGeek Link"
            />
          </ListItem>
          <PersonalGoalControl personalGoal={game.personalGoal} onToggle={updatePersonalGoal} />
          <GameVisibilityControl visibility={game.visibility} onToggle={updateVisibility} />
          <ArchiveControl archived={game.archived} onToggle={updateArchived} />
        </List>
      </Card>
      <Box p={0.5} />
      {Features.FinancialModelling && (
        <Card elevation={1} square={false}>
          <List>
            <ArrowListItem primary="Component Configurations" secondary="5 found" to={Path.game(gameId).components()} />
            <ArrowListItem primary="Shipping Lanes" secondary="AU, USA, UK and India" to={Path.game(gameId).lanes()} />
            <ArrowListItem
              primary="Financial Modelling"
              secondary="Combine component configurations, quotes and shipping lanes"
              to={Path.game(gameId).financialModelling()}
            />
          </List>
        </Card>
      )}
      <LastUpdated timestamp={game.updated} />
    </>
  );
};

export const GameDetailFromRoute = () => {
  const gameId = useGameIdFromRoute();
  const { data: games = [], isLoading } = useMyGames();

  if (!gameId) {
    return <EmptyDetailCard text="Select a game from the list on the left." />;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!isLoading && !games.map((game) => game.id).includes(gameId)) {
    return (
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <PublicGameDetail gameId={gameId} designerView />
      </Backdrop>
    );
  }

  return <GameDetail gameId={gameId} />;
};
