import React from "react";
import Alert from "@mui/material/Alert";
import { NoItemsFound } from "../publisher/NoItemsFound";

export const EmptyMessageBoard = () => {
  return (
    <NoItemsFound>
      <Alert severity="info">Start a conversation, a design diary or anything you want to record.</Alert>
    </NoItemsFound>
  );
};
