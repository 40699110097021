import React from "react";
import { ListView } from "../core/ListView";
import Badge from "@mui/material/Badge";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import LocationCity from "@mui/icons-material/LocationCity";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { RoyaltyTotals } from "../royalties/RoyaltyTotals";
import { useAllRoyalties } from "@seabrookstudios/pitch2table";
import Business from "@mui/icons-material/Business";
import Money from "@mui/icons-material/Money";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import Category from "@mui/icons-material/Category";

/**
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.category
 * @param {string} props.helperText
 * @param {boolean} props.selected
 * @param {Function} props.onClick
 * @param {number} props.badgeCount
 * @param {import("@mui/material/OverridableComponent").OverridableComponent<import("@mui/material").SvgIconTypeMap<{}, "svg">>} props.Icon
 */
const SettingsCategory = ({ id, category, helperText, selected, onClick, badgeCount = 0, Icon }) => {
  const onClickHandler = () => {
    onClick(id);
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemAvatar>
        <Badge color="primary" badgeContent={badgeCount}>
          <Icon />
        </Badge>
      </ListItemAvatar>
      <ListItemText primary={category} secondary={helperText} />
    </ListItemButton>
  );
};

export const RoyaltyCategoriesList = ({ onListItemSelected, selectedListItemId }) => {
  const { data: royalties = [] } = useAllRoyalties();
  return (
    <ListView title="Royalty Categories" Filters={null} Action={undefined} EmptyList={EmptyDetailCard} neverEmpty>
      <RoyaltyTotals royalties={royalties} />
      <SettingsCategory
        category="Show all"
        id="all"
        helperText="View all royalties"
        selected={selectedListItemId === "all"}
        onClick={onListItemSelected}
        badgeCount={0}
        Icon={Money}
      />
      <SettingsCategory
        category="By Year"
        id="by-year"
        helperText="View all royalties grouped by year"
        selected={selectedListItemId === "by-year"}
        onClick={onListItemSelected}
        badgeCount={0}
        Icon={CalendarMonth}
      />
      <SettingsCategory
        category="By Game"
        id="by-game"
        helperText="View royalties grouped by game"
        selected={selectedListItemId === "by-game"}
        onClick={onListItemSelected}
        badgeCount={0}
        Icon={LocationCity}
      />
      <SettingsCategory
        category="By Publisher"
        id="by-publisher"
        helperText="View royalties grouped by publisher"
        selected={selectedListItemId === "by-publisher"}
        onClick={onListItemSelected}
        badgeCount={0}
        Icon={Business}
      />
      <SettingsCategory
        category="By Type"
        id="by-type"
        helperText="View royalties grouped by type"
        selected={selectedListItemId === "by-type"}
        onClick={onListItemSelected}
        badgeCount={0}
        Icon={Category}
      />
    </ListView>
  );
};
