import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "../../Path";
import { formatFullDate } from "../../core/date-formatting";

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/pitch2table-core").Contract} props.item
 */
export const ListEntry = ({ item }) => {
  const params = useParams();
  const navigate = useNavigate();

  const selected = params.attachmentId === item.id;

  const onClickHandler = () => {
    navigate(Path.game(item.gameId).contract(item.id));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemText primary={item.name} secondary={`Signed ${formatFullDate(item.signedDate)}`} />
    </ListItemButton>
  );
};
