import React, { useContext, useState } from "react";
import Image from "mui-image";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import ListItemButton from "@mui/material/ListItemButton";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Badge from "@mui/material/Badge";
import Settings from "@mui/icons-material/Settings";
import BusinessIcon from "@mui/icons-material/Business";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutButton from "../login/LogoutButton";
import { Path } from "../Path";
import { Link as RouterLink } from "react-router-dom";
import { AccountContext } from "./AccountProvider";
import { ModeButton } from "./ModeButton";
import { useInvitesForUser } from "@seabrookstudios/pitch2table";
import { usePublisher } from "../publisher/queries";
import logo from "../assets/logo.png";
import { GoMobileButton } from "../general/GetMobilePage";
import { FeedbackAndSupportButton } from "../general/FeedbackAndSupportPage";
import { SideMenuOption } from "./SideMenuOption";
import { UpdatesButton } from "../general/UpdatesPage";
import Article from "@mui/icons-material/Article";
import Money from "@mui/icons-material/Money";
import Box from "@mui/material/Box";
import { Features } from "../games/features";
import Casino from "@mui/icons-material/Casino";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

/**
 * @param {string} label
 * @param {string} tooltip
 * @param {import("react-router-dom").To} to
 * @param {import("@mui/material/OverridableComponent").OverridableComponent<import("@mui/material").SvgIconTypeMap<{}, "svg">>} Icon
 */
const makeMenuOption = (label, tooltip, to, Icon) => {
  /**
   * @param {object} props
   * @param {boolean} [props.iconOnly]
   */
  const MenuOption = ({ iconOnly = false }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const onClickHandler = () => {
      navigate(to);
    };

    return (
      <SideMenuOption
        onClick={onClickHandler}
        tooltip={tooltip}
        label={label}
        // @ts-ignore
        selected={location.pathname.startsWith(to.pathname)}
        iconOnly={iconOnly}
      >
        <Icon fontSize={Features.BaseCampLayout ? "small" : "medium"} />
      </SideMenuOption>
    );
  };

  return MenuOption;
};

const PublisherHomeButton = () => {
  const location = useLocation();

  const { publisherId } = useContext(AccountContext);
  const { data: publisher } = usePublisher(publisherId);

  return (
    <ListItemButton
      sx={{ display: "flex", padding: 1, justifyContent: "center", alignItems: "center" }}
      selected={location.pathname === "/"}
    >
      <Link
        color="inherit"
        component={RouterLink}
        to={Path.Home}
        aria-label="go publisher home"
        aria-controls="publisher home"
        sx={{ flexGrow: 1 }}
      >
        <Avatar src={publisher && publisher.logoUrl} />
      </Link>
    </ListItemButton>
  );
};

const UserHomeButton = makeMenuOption("Games", "Head back to your dashboard", Path.games(), () => (
  <Image alt="My Games" src={logo} fit="contain" width={32} height={32} />
));

const Pitch2TableMenu = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(/** @type {HTMLButtonElement|null} */ (null));
  const open = Boolean(anchorEl);

  /**
   * @param {React.MouseEvent<HTMLButtonElement>} event
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * @param {import("react-router-dom").To} path
   */
  const handleCloseAndNavigate = (path) => () => {
    navigate(path);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="app-menu"
        aria-controls={open ? "app-menu-overlay" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Image alt="Pitch2Table" src={logo} fit="contain" width={32} height={32} />
      </Button>
      <Menu
        id="app-menu-overlay"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "app-menu",
        }}
      >
        <MenuItem onClick={handleCloseAndNavigate(Path.Mobile)}>Go Mobile</MenuItem>
        <MenuItem onClick={handleCloseAndNavigate(Path.Support)}>Feedback & Support</MenuItem>
        <MenuItem onClick={handleCloseAndNavigate(Path.Updates)}>Latest Updates</MenuItem>
      </Menu>
    </div>
  );
};

const Pitch2TableButton = () => {
  return <Pitch2TableMenu />;
};

const MyGames = makeMenuOption("Games", "View your games", Path.games(), () => <Casino />);
const MyPublishers = makeMenuOption("Publishers", "View your publishers", Path.publishers(), () => <BusinessIcon />);
const MyDocuments = makeMenuOption("Contracts", "View your contracts", Path.documents(), () => <Article />);
const MyRoyalties = makeMenuOption("Royalties", "View your royalties", Path.royalties().none(), () => <Money />);

const UserSettingsButton = ({ iconOnly = false }) => {
  const { data: invites = [] } = useInvitesForUser();
  const location = useLocation();

  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(Path.settings().none());
  };

  return (
    <SideMenuOption
      label="Settings"
      tooltip="Edit account and application settings"
      onClick={onClickHandler}
      selected={location.pathname.startsWith(Path.settings().none())}
      iconOnly={iconOnly}
    >
      <Badge color="primary" badgeContent={invites.length}>
        <Settings />
      </Badge>
    </SideMenuOption>
  );
};

const PublisherSettingsButton = () => {
  const location = useLocation();

  return (
    <ListItemButton
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      selected={location.pathname.startsWith(Path.Settings)}
    >
      <Link
        color="inherit"
        component={RouterLink}
        to={Path.Settings}
        aria-label="go to publisher settings"
        aria-controls="publisher settings"
        sx={{ flexGrow: 1 }}
      >
        <Settings />
      </Link>
    </ListItemButton>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} props.publisherId
 */
const UserMenu = () => {
  const { mode } = useContext(AccountContext);

  return (
    <Paper style={{ minHeight: "100vh", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
      <Stack sx={{ width: { xs: 60, md: 175, lg: 200 } }} direction="column">
        <Stack
          spacing={2}
          pt={2}
          pb={2}
          sx={{
            alignItems: { xs: "center", md: "flex-start" },
            mt: "auto",
            flexGrow: 1,
          }}
        >
          {mode === "Publisher" ? <PublisherHomeButton /> : <UserHomeButton />}
          <MyPublishers />
          <MyDocuments />
          <MyRoyalties />
          {/* {mode === "Designer" && <MyGamesButton />} */}
          {false && <ModeButton />}
          {mode === "Publisher" ? <PublisherSettingsButton /> : <UserSettingsButton />}
          <GoMobileButton />
          <FeedbackAndSupportButton />
          <UpdatesButton />
          <LogoutButton />
        </Stack>
      </Stack>
    </Paper>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} props.publisherId
 */
export const UserMenuTop = () => {
  return (
    <Box width="100%">
      <Stack alignItems="center" justifyContent="space-between" direction="row">
        <Pitch2TableButton />
        <Stack alignItems="center" justifyContent="space-evenly" direction="row">
          <MyGames />
          <MyPublishers />
          <MyDocuments />
          <MyRoyalties />
        </Stack>
        <UserSettingsButton iconOnly />
      </Stack>
    </Box>
  );
};

export default UserMenu;
