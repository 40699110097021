import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SectionHeading } from "../core/font-sizes";

/**
 *
 * @param {object} props
 * @param {(import("@seabrookstudios/pitch2table-core").PublicGameDesigners[])} props.designers
 */
export const Designers = ({ designers }) => {
  return (
    <Stack pt={2} direction="column" display="flex" justifyContent="space-between">
      <SectionHeading>{designers.length === 1 ? "Designer" : "Designers"}</SectionHeading>
      <Typography pt={1}>
        {designers.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(", ")}
      </Typography>
    </Stack>
  );
};
