import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useLayoutMode } from "../layout/use-layout-mode";
import { Features } from "../games/features";

/**
 * @param {object} props
 * @param {() => React.JSX.Element} props.ListView
 * @param {() => React.JSX.Element} props.DetailView
 */
export const ListDetail = ({ ListView, DetailView }) => {
  const { showLeft, showRight, singleView, preferRight } = useLayoutMode();

  if (Features.BaseCampLayout) {
    return (
      <Box pl={2} pr={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {preferRight ? <DetailView /> : <ListView />}
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box p={2} width="100%" flex={1}>
      <Grid container spacing={2}>
        {showLeft && (
          <Grid item xs={singleView ? 12 : 6}>
            <ListView />
          </Grid>
        )}
        {showRight && (
          <Grid item xs={singleView ? 12 : 6}>
            <DetailView />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
