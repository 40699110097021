import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const ImmutableGameTag = ({ text }) => {
  return (
    <Typography variant="h5" textAlign="left">
      <Box>{text}</Box>
    </Typography>
  );
};
