import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useContact, usePublisherAndContacts } from "@seabrookstudios/pitch2table";
import { formatFullName } from "../contacts/model";

/**
 * @param {import("@seabrookstudios/types").UserId|undefined|null} userId
 */
export const useContactName = (userId) => {
  const { data: contact } = useContact(userId);

  if (!contact) {
    return "";
  }

  return formatFullName(contact);
};

/**
 * @param {object} props
 * @param {import('@seabrookstudios/types').UserId|undefined|null} props.userId
 */
export const UserName = ({ userId }) => {
  const { data: profile } = useContact(userId);

  if (!profile) {
    return <Skeleton />;
  }

  return (
    <Typography variant="inherit" component="span">
      {formatFullName(profile)}
    </Typography>
  );
};

/**
 * @param {object} props
 * @param {import('@seabrookstudios/pitch2table-core').PublisherId|undefined|null} props.publisherId
 */
export const PublisherName = ({ publisherId }) => {
  const { data: pubAndContact = [] } = usePublisherAndContacts(publisherId);

  const [publisher] = pubAndContact;
  if (!publisher) {
    return <Skeleton />;
  }

  return (
    <Typography variant="inherit" component="span">
      {publisher.name}
    </Typography>
  );
};
