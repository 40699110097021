import React from "react";
import { DateTime } from "luxon";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { EditDateDialog } from "../core/EditDateDialog";
import { formatFullDate } from "../core/date-formatting";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} [props.popupTitle]
 * @param {number} props.timestamp
 * @param {(newDate: number) => void} props.onSave
 * @param {boolean} props.isBusy
 * @param {boolean} [props.disablePast]
 * @param {boolean} [props.disableFuture]
 */
export const EditableDate = ({
  title,
  popupTitle,
  timestamp = 0,
  onSave,
  isBusy,
  disablePast = false,
  disableFuture = false,
}) => {
  return (
    <Box>
      <EditDateDialog
        onSave={onSave}
        title={popupTitle || title}
        isBusy={isBusy}
        initialValue={timestamp === 0 ? DateTime.now().toMillis() : timestamp}
        disablePast={disablePast}
        disableFuture={disableFuture}
      >
        <Stack>
          <Typography>{title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {timestamp === 0 ? "" : formatFullDate(timestamp)}
          </Typography>
        </Stack>
      </EditDateDialog>
    </Box>
  );
};
