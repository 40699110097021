import React from "react";
import { GamesListAndDetail } from "./games/GamesListAndDetail";
import { Path } from "./Path";
import { AddToSubmissionsLandingPage } from "./landing-pages/add-to-submissions";
import { SubmissionsListAndDetail } from "./publisher/SubmissionsListAndDetail";
import { AddToSubmissionsLandingPageLoggedIn } from "./landing-pages/add-to-submissions-logged-in";
import { AppWithSideMenu } from "./core/AppWithSideMenu";
import { LogoutScreen } from "./login/LogoutButton";
import { SelectAccountPage } from "./core/Switcher";
import AccountProvider from "./core/AccountProvider";
import { Auth0Stack } from "@seabrookstudios/auth-web";
import { ErrorPage } from "./core/ErrorPage";
import { LoginPage } from "./auth/LoginPage";
import { MyGamesBreadcrumbs } from "./core/Breadcrumbs";
import { routes as gameRoutes } from "./games/routes";
import { routes as settingsRoutes } from "./settings/routes";
import { routes as publicRoutes } from "./public/routes";
import { routes as publishersRoutes } from "./publishers/routes";
import { routes as documentRoutes } from "./documents/routes";
import { routes as royaltyRoutes } from "./royalties/routes";
import { routes as messageBoardRoutes } from "./message-board/routes";
import { GetMobilePage } from "./general/GetMobilePage";
import { FeedbackAndSupportPage } from "./general/FeedbackAndSupportPage";
import { UpdatesPage } from "./general/UpdatesPage";

const doesntSupportPublisherView = [
  {
    path: Path.Landing.AddToSubmissionsBase,
    element: <AddToSubmissionsLandingPage />,
  },
  {
    path: Path.Landing.AddToSubmissionsBaseLoggedIn,
    element: (
      <AppWithSideMenu>
        <AddToSubmissionsLandingPageLoggedIn />
      </AppWithSideMenu>
    ),
  },
  {
    path: "/contacts",
    element: <AppWithSideMenu>{/* <ContactListWithDetail /> */}</AppWithSideMenu>,
  },
];

/**
 * @param {object} props
 * @param {import("react").ReactNode} [props.children]
 */
export const AuthedRoute = ({ children }) => {
  const prod = "XFylpydHLSsmQ9mTeJTMfrub9JP9G7pk";
  const dev = "pwjI0u61Ntwx9tQjuUDHtOpp0B76oz63";

  return (
    <Auth0Stack
      domain="pitch2table.us.auth0.com"
      clientId={process.env.NODE_ENV === "production" ? prod : dev}
      audience="https://secure.pitch2table.com"
    >
      <AccountProvider>{children}</AccountProvider>
    </Auth0Stack>
  );
};

const HomePage = () => <AppWithSideMenu DesignerView={GamesListAndDetail} PublisherView={SubmissionsListAndDetail} />;

const mainRoutes = [
  {
    path: "/",
    element: <HomePage />,
    handle: {
      crumb: MyGamesBreadcrumbs,
      prefer: "left",
    },
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: Path.Error,
    element: <ErrorPage />,
  },
  {
    path: Path.SelectAccount,
    element: <SelectAccountPage />,
  },
  {
    path: "/mobile",
    element: (
      <AppWithSideMenu>
        <GetMobilePage />
      </AppWithSideMenu>
    ),
  },
  {
    path: "/support",
    element: (
      <AppWithSideMenu>
        <FeedbackAndSupportPage />
      </AppWithSideMenu>
    ),
  },
  {
    path: "/updates",
    element: (
      <AppWithSideMenu>
        <UpdatesPage />
      </AppWithSideMenu>
    ),
  },
  {
    path: "/logout",
    element: <LogoutScreen />,
  },
];

export const routes = [
  ...mainRoutes,
  ...settingsRoutes,
  ...gameRoutes,
  ...publicRoutes,
  ...doesntSupportPublisherView,
  ...publishersRoutes,
  ...documentRoutes,
  ...royaltyRoutes,
  ...messageBoardRoutes,
];
