import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { EditTextFieldDialog } from "../core/EditTextFieldDialog";
import ContentCopy from "@mui/icons-material/ContentCopy";
import OpenInNewOutlined from "@mui/icons-material/OpenInNewOutlined";
import { enqueueSnackbar } from "notistack";
import { useCopyToClipboard } from "usehooks-ts";
import { ExternalLink } from "../styleguide/AppLink";

const Buttons = ({ value }) => {
  const [, copy] = useCopyToClipboard();

  const copyUrlToClipboard = (e) => {
    if (!value) {
      return e.preventDefault();
    }

    copy(value);
    enqueueSnackbar("Url copied to clipboard", { variant: "info" });

    return e.preventDefault();
  };

  return (
    <>
      <ListItemIcon>
        <ListItemButton onClick={copyUrlToClipboard}>
          <ContentCopy />
        </ListItemButton>
      </ListItemIcon>
      <ExternalLink href={value}>
        <ListItemIcon>
          <ListItemButton>
            <OpenInNewOutlined />
          </ListItemButton>
        </ListItemIcon>
      </ExternalLink>
    </>
  );
};

const Display = ({ title, value }) => {
  return (
    <ListItemText
      primary={title}
      secondary={value || "click to edit"}
      sx={{ maxWidth: "66ch", whiteSpace: "pre-wrap" }}
    />
  );
};

/**
 * @param {object} props
 * @param {string} props.title
 * @param {import("@seabrookstudios/types").Url} props.text
 * @param {string} props.helperText
 * @param {boolean} [props.disabled]
 * @param {(text: import("@seabrookstudios/types").Url) => void} props.onSave
 */
export const EditableListItemUrl = ({ title, text, helperText, onSave, disabled }) => {
  const hasUrl = text && text.length > 0;

  return (
    <Stack direction="column">
      {disabled ? (
        <ListItem>
          <Display title={title} value={text} />
          {hasUrl && <Buttons value={text} />}
        </ListItem>
      ) : (
        <Stack direction="row">
          <ListItem>
            <ListItemText>
              <EditTextFieldDialog
                onSave={onSave}
                title={title}
                initialValue={text}
                isBusy={false}
                helperText={helperText}
                multiline
              >
                <Tooltip title="Click to edit">
                  <>
                    <Display title={title} value={text} />
                  </>
                </Tooltip>
              </EditTextFieldDialog>
            </ListItemText>
            {hasUrl && <Buttons value={text} />}
          </ListItem>
        </Stack>
      )}
    </Stack>
  );
};

/**
 * @param {object} props
 * @param {string} props.title
 * @param {import("@seabrookstudios/types").Url} props.text
 */
export const StaticListItemUrl = ({ title, text }) => {
  const hasUrl = text && text.length > 0;

  if (!hasUrl) {
    return;
  }

  return (
    <Stack direction="column">
      <ListItem>
        <ListItemText
          primary={title}
          secondary={
            <a href={text} target="_blank" rel="noreferrer">
              {text}
            </a>
          }
          sx={{ maxWidth: "66ch", whiteSpace: "pre-wrap" }}
        />
      </ListItem>
    </Stack>
  );
};
