import { useParams } from "react-router-dom";

/**
 * @returns {RoyaltyCategories|undefined}
 */
export const useCategoryFromRoute = () => {
  return /** @type {RoyaltyCategories} */ (useParams().category) || undefined;
};

/**
 * @returns {string|undefined}
 */
export const useYearFromParam = () => {
  return useParams().year;
};

/**
 * @returns {import("@seabrookstudios/pitch2table-core").RoyaltyType|undefined}
 */
export const useTypeFromParam = () => {
  return useParams().type;
};
