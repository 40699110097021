import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useGamePublishers } from "@seabrookstudios/pitch2table";

const Label = "Contract Publishers";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId[]} props.value
 * @param {(publisherIds: import("@seabrookstudios/pitch2table-core").PublisherId[]) => void} props.onChange
 * @param {boolean} [props.disabled]
 */
export const ContractPublishersSelect = ({ gameId, value = [], onChange, disabled = false }) => {
  const { data: interest = [] } = useGamePublishers(gameId);
  const [selected, setSelected] = useState(value);
  const [uniqueInterest, setUniqueInterest] = useState(interest);

  useEffect(() => {
    const unique = interest.reduce((acc, pi) => {
      const soFar = acc.map((x) => x.publisherId);
      return soFar.includes(pi.publisherId) ? acc : [...acc, pi];
    }, /** @type {import("@seabrookstudios/pitch2table-core").DesignerPublisherGameView[]} */ ([]));

    setUniqueInterest(unique);
  }, [interest]);

  const handleChange = (event) => {
    const { value } = event.target;

    setSelected(typeof value === "string" ? value.split(",") : value);
  };

  const handleClose = async () => {
    await onChange(selected);
  };

  return (
    <FormControl sx={{ width: "100%" }} margin="normal" disabled={disabled}>
      <InputLabel id="contract-publishers-label">{Label}</InputLabel>
      <Select
        id="contract-publishers"
        labelId="contract-publishers-label"
        value={selected}
        multiple
        onChange={handleChange}
        onClose={handleClose}
        placeholder={Label}
        label={Label}
        input={<OutlinedInput label={Label} />}
        renderValue={(items = []) => {
          return items
            .map((publisherId) => {
              const publisher = interest.find((pi) => pi.publisherId === publisherId);
              return publisher ? publisher.publisherName : "";
            })
            .join(", ");
        }}
      >
        {uniqueInterest.map((pi) => (
          <MenuItem key={pi.publisherId} value={pi.publisherId}>
            <Checkbox id={pi.publisherId} checked={selected.includes(pi.publisherId)} />
            <ListItemText primary={pi.publisherName} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        By associating a contract with a publisher you will also be able to attribute royalties from this contract to
        the publisher.
      </FormHelperText>
    </FormControl>
  );
};
