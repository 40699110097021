import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { useHover } from "usehooks-ts";

/**
 * @param {object} props
 * @param {string} props.text
 * @param {boolean} [props.disabled]
 * @returns {React.JSX.Element}
 */
export const EditableTag = ({ text, disabled }) => {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <Stack direction="row">
      <Box ref={hoverRef}>
        <Typography variant="body1" fontStyle="italic" textAlign="left" color="text.secondary">
          {text}
        </Typography>
      </Box>
      <Box pl={1} display="flex" justifyContent="center" alignItems="center">
        {isHover && !disabled ? <EditOutlined fontSize="small" /> : <Icon fontSize="small" />}
      </Box>
    </Stack>
  );
};
