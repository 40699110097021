import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useAcceptInvite, useDeclineInvite, useInviteDetails, useInvitesForUser } from "@seabrookstudios/pitch2table";
import { NoItemsFound } from "../publisher/NoItemsFound";
import Check from "@mui/icons-material/Check";
import Delete from "@mui/icons-material/Delete";
import { UserName } from "../users/UserName";
import { UserAvatar } from "../users/UserAvatar";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").Invite} props.invite
 */
const InvitationsListItem = ({ invite }) => {
  const { data: details, isLoading } = useInviteDetails(invite.id);
  const acceptInvite = useAcceptInvite();
  const declineInvite = useDeclineInvite();

  const acceptInviteHandler = () => {
    acceptInvite.mutate({ inviteId: invite.id });
  };

  const declineInviteHandler = () => {
    declineInvite.mutate({ inviteId: invite.id });
  };

  if (isLoading) {
    return <CircularProgress size={16} />;
  }

  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" aria-label="delete" onClick={declineInviteHandler}>
          <Delete />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <UserAvatar userId={invite.invitor} />
      </ListItemAvatar>
      <ListItemText
        primary={details ? details.name : "Error"}
        secondary={
          <Typography component="span" variant="inherit">
            Invited by <UserName userId={invite.invitor} />
          </Typography>
        }
      />
      <IconButton aria-label="accept" onClick={acceptInviteHandler}>
        <Check />
      </IconButton>
    </ListItem>
  );
};

export const InvitationsList = () => {
  const { data: invites = [], isFetching } = useInvitesForUser();

  if (isFetching) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (invites.length === 0) {
    return (
      <Paper elevation={1} square={false}>
        <NoItemsFound>
          <Typography align="center">No invites found.</Typography>
        </NoItemsFound>
      </Paper>
    );
  }

  return (
    <Card elevation={1} square={false}>
      <List>
        {invites.map((invite) => (
          <InvitationsListItem key={invite.id} invite={invite} />
        ))}
      </List>
    </Card>
  );
};
