import React from "react";
import { ListView } from "../core/ListView";
import Chip from "@mui/material/Chip";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "../Path";
import { NoItemsFound } from "./NoItemsFound";
import Typography from "@mui/material/Typography";

/**
 *
 * @param {Object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").PublisherGameSummary} props.submission
 */
const SubmissionsListEntry = ({ gameId, submission }) => {
  const params = useParams();
  const navigate = useNavigate();

  const selected = params.gameId === gameId;

  const onClickHandler = () => {
    navigate(Path.user().game(gameId));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemText primary={submission.name} secondary={submission.tag} />
      {/* <ListItemIcon>{StatusToText[summary.status]}</ListItemIcon> */}
    </ListItemButton>
  );
};

export const SubmissionsList = ({ onFilterClicked, submissions, filter, filters = [] }) => {
  const Filters = () => (
    <>
      {filters.map(({ label }) => {
        const onChipClicked = () => onFilterClicked(label);
        return (
          <Chip key={label} label={label} onClick={onChipClicked} variant={label === filter ? "filled" : "outlined"} />
        );
      })}
    </>
  );

  return (
    <ListView
      title="SUBMISSIONS"
      Filters={Filters}
      EmptyList={() => (
        <NoItemsFound>
          <Typography align="center">No games found.</Typography>
        </NoItemsFound>
      )}
      shown={submissions.length}
      total={submissions.length}
    >
      {submissions.map((submission) => (
        <SubmissionsListEntry
          gameId={submission.gameId.toString()}
          key={submission.id.toString()}
          submission={submission}
        />
      ))}
    </ListView>
  );
};
