import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ListItemIcon from "@mui/material/ListItemIcon";
import NotificationImportant from "@mui/icons-material/NotificationImportant";

/**
 * @param {object} props
 * @param {("default"|"error"|"success"|"primary"|"secondary"|"info"|"warning")} props.severity
 * @param {("small"|"large")} [props.size]
 */
export const FollowUpDuePill = ({ severity, size = "large" }) => {
  if (size === "small") {
    return (
      <ListItemIcon>
        <NotificationImportant color="error" />
      </ListItemIcon>
    );
  }

  return (
    <ListItemIcon>
      <Box justifyContent="center" alignItems="center" display="flex" flex={1}>
        <Chip icon={<NotificationImportant />} label="DUE" color={severity} />
      </Box>
    </ListItemIcon>
  );
};
