import React from "react";
import Image from "mui-image";
import CardMedia from "@mui/material/CardMedia";
import LinkOff from "@mui/icons-material/LinkOff";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").Url} props.url
 * @param {string} props.name
 */
export const PublisherLogo = ({ url, name }) => {
  if (!url) {
    return null;
  }

  return (
    <CardMedia
      component="img"
      sx={{ maxHeight: 200, objectFit: "contain" }}
      image={url}
      title={`Company logo for ${name}`}
    />
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").DesignerPublisherGameView} props.publisher
 * @param {number} [props.width]
 * @param {number} [props.height]
 */
export const PublisherLogoSquare = ({ publisher, width = 32, height = 32 }) => {
  return (
    <Image
      alt={publisher.publisherName}
      src={publisher.logoUrl}
      fit="contain"
      width={width}
      height={height}
      errorIcon={<LinkOff />}
    />
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PublisherForApp|import("@seabrookstudios/pitch2table-core").ContactBookPublisher} props.publisher
 * @param {number} [props.width]
 * @param {number} [props.height]
 */
export const PublisherLogoSquare2 = ({ publisher, width = 32, height = 32 }) => {
  return (
    <Image
      alt={publisher.name}
      src={publisher.logoUrl}
      fit="contain"
      width={width}
      height={height}
      errorIcon={<LinkOff />}
    />
  );
};
