import React from "react";
import Image from "mui-image";
import CardMedia from "@mui/material/CardMedia";
import LinkOff from "@mui/icons-material/LinkOff";
import { UploadGameCover } from "./UploadGameCover";
import { FullScreenDialog } from "../core/FullScreenDialog";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").Game} props.game
 * @param {boolean} props.editable
 */
export const GameCardCover = ({ game, editable }) => {
  if (!game) {
    return null;
  }

  if (editable && !game.image) {
    return <UploadGameCover gameId={game.id} />;
  }

  if (!editable) {
    return (
      <CardMedia
        component="img"
        sx={{ maxHeight: 200, minHeight: 200, objectFit: "contain" }}
        image={game.image}
        title={`Game cover for ${game.name}`}
      />
    );
  }

  return (
    <FullScreenDialog
      iconAlign="center"
      Icon={() => (
        <CardMedia
          component="img"
          sx={{ maxHeight: 200, minHeight: 200, objectFit: "contain" }}
          image={game.image}
          title={`Game cover for ${game.name}`}
        />
      )}
      fullscreen={false}
      title="Replace Game Cover"
      canSave={true}
    >
      <UploadGameCover gameId={game.id} />
    </FullScreenDialog>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").Game|import("@seabrookstudios/pitch2table-core").GameSummary} props.game
 * @param {number} [props.width]
 * @param {number} [props.height]
 */
export const CoverSquare = ({ game, width = 32, height = 32 }) => {
  return (
    <Image
      alt={`Cover image for ${game.name}`}
      src={game.image}
      fit="contain"
      width={width}
      height={height}
      errorIcon={<LinkOff />}
    />
  );
};
