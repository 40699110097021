import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import LoginButton from "../login/LoginButton";

export const EmptyDetailCard = ({ text }) => (
  <Card
    elevation={1}
    square={false}
    sx={{ height: "85vh", justifyContent: "center", alignItems: "center", flex: 1, display: "flex" }}
  >
    <Box display="flex" justifyContent="center" alignItems="center" p={2}>
      <Typography align="center">{text}</Typography>
    </Box>
  </Card>
);

export const EmptyDetailCardWithLogin = ({ text }) => (
  <Card
    elevation={1}
    square={false}
    sx={{
      height: "85vh",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Typography>{text}</Typography>
    <Box p={1}>
      <LoginButton overrideLabel="Login" />
    </Box>
  </Card>
);
