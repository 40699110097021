import React, { useContext } from "react";
import debounce from "lodash/debounce";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { UpdatableNameTextField } from "./UpdatableNameTextField";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { AccountContext } from "../core/AccountProvider";
import { PublisherContactKey, usePublisherContact } from "../publisher/queries";
import {
  updateContactEmail,
  updateContactName,
  updateContactNumber,
  updateContactPronouns,
} from "../publisher/service";

const ContactPersonName = ({ initialValue, publisherId }) => {
  const updateFunctionHandler = async (token, newValue) => {
    await updateContactName(token, publisherId, newValue);
  };

  return (
    <UpdatableNameTextField
      label="Contact Person"
      helperText={`Name of person designers should contact`}
      initialValue={initialValue}
      updateFunction={debounce(updateFunctionHandler, 300)}
      queryKey={PublisherContactKey(publisherId)}
    />
  );
};

const ContactPersonPronouns = ({ initialValue, publisherId }) => {
  const updateFunctionHandler = async (token, newValue) => {
    await updateContactPronouns(token, publisherId, newValue);
  };

  return (
    <UpdatableNameTextField
      label="Contact Person Pronouns"
      helperText={`Pronouns of contact person`}
      initialValue={initialValue}
      updateFunction={debounce(updateFunctionHandler, 300)}
      queryKey={PublisherContactKey(publisherId)}
    />
  );
};

const ContactPersonEmail = ({ initialValue, publisherId }) => {
  const updateFunctionHandler = async (token, newValue) => {
    await updateContactEmail(token, publisherId, newValue);
  };

  return (
    <UpdatableNameTextField
      label="Contact Email Address"
      helperText={`Email address for contact or front-door.`}
      initialValue={initialValue}
      updateFunction={debounce(updateFunctionHandler, 300)}
      queryKey={PublisherContactKey(publisherId)}
    />
  );
};

const ContactPersonNumber = ({ initialValue, publisherId }) => {
  const updateFunctionHandler = async (token, newValue) => {
    await updateContactNumber(token, publisherId, newValue);
  };

  return (
    <UpdatableNameTextField
      label="Contact Number"
      helperText={`Phone number of contact.`}
      initialValue={initialValue}
      updateFunction={debounce(updateFunctionHandler, 300)}
      queryKey={PublisherContactKey(publisherId)}
    />
  );
};

export const PublisherContact = () => {
  const { publisherId } = useContext(AccountContext);
  const { isLoading, data } = usePublisherContact(publisherId);

  if (isLoading) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (!data) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <span>An error occurred, please refresh the page.</span>
        </Box>
      </Card>
    );
  }

  return (
    <Card elevation={1} square={false}>
      <List>
        <Box p={2}>
          <Typography>
            Updates to these properties will not update the contact information for the user they were originally based
            upon.
          </Typography>
        </Box>
        <ListItem>
          <ContactPersonName initialValue={data.name} publisherId={publisherId} />
        </ListItem>
        <ListItem>
          <ContactPersonPronouns initialValue={data.pronouns} publisherId={publisherId} />
        </ListItem>
        <ListItem>
          <ContactPersonEmail initialValue={data.email} publisherId={publisherId} />
        </ListItem>
        <ListItem>
          <ContactPersonNumber initialValue={data.number} publisherId={publisherId} />
        </ListItem>
      </List>
    </Card>
  );
};
