/**
 * @type {Record<import("@seabrookstudios/pitch2table-core").DesignerGameStatus,string>}
 */
export const DesignerGameStatusToLabel = {
  Idea: "Idea",
  InDevelopmentEarly: "In Development - Early",
  InDevelopmentMid: "In Development - Mid",
  InDevelopmentLate: "In Development - Late",
  PitchingNow: "Pitching Now",
  Published: "Published",
  Signed: "Signed",
};

/**
 * @type {Record<import("@seabrookstudios/pitch2table-core").DesignerGameStatus, string>}
 */
export const DesignerGameStatusHelperText = {
  Idea: "An untested game idea.",
  InDevelopmentEarly: "Early tests with significant changes expected.",
  InDevelopmentMid: "Basic systems bedded down, changes still expected.",
  InDevelopmentLate: "Balancing and iteration",
  PitchingNow: "Actively pitching to publishers.",
  Signed: "Contract signed with publisher.",
  Published: "In the market or out of print.",
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").Sellsheet[]} sellsheets
 * @param {number} index
 */
export const sellsheetVersion = (sellsheets, index) => `v${sellsheets.length - index}`;

/**
 * @param {import("@seabrookstudios/pitch2table-core").Rules[]} rules
 * @param {number} index
 */
export const rulesVersion = (rules, index) => `v${rules.length - index}`;

/**
 * @param {import("@seabrookstudios/pitch2table-core").Game} game
 */
export const isCoDesign = (game) => {
  const coDesigners = game.designers.filter(
    (designer) => designer.role === undefined || designer.role === "owner" || designer.role === "codesigner"
  );

  return coDesigners.length >= 2;
};
