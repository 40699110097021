import React from "react";
import numeral from "numeral";
import { Path } from "../../../Path";
import { useGameIdFromRoute } from "../../WithGameIdFromRoute";
import { useQuery } from "@tanstack/react-query";
import { NoItemsFound } from "../../../publisher/NoItemsFound";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { ListView } from "../../../core/ListView";
import { useNavigate, useParams } from "react-router-dom";
import { ListDetail } from "../../../web/ListDetail";
import { EmptyDetailCard } from "../../../core/EmptyDetailCard";
import { quotes } from "../fake-data";
import { formatFullDate } from "@seabrookstudios/pitch2table-core";

const useComponentQuotes = (componentId) => {
  return useQuery({
    queryKey: ["component-configuration-quotes", componentId],
    /**
     * @returns {import("../fake-data").Quote[]}
     */
    queryFn: () => {
      return quotes.filter((quote) => quote.componentConfigurationId === componentId);
    },
  });
};

const useComponentQuote = (componentId, quoteId) => {
  return useQuery({
    queryKey: ["component-configuration-quote", componentId, quoteId],
    /**
     * @returns {import("../fake-data").Quote|undefined}
     */
    queryFn: () => {
      return quotes.find((quote) => quote.componentConfigurationId === componentId && quote.id === quoteId);
    },
  });
};

const ComponentConfigurationQuoteItem = () => {
  const { configurationId, quoteId } = useParams();

  const { data: quote } = useComponentQuote(configurationId, quoteId);

  if (!quote) {
    return <EmptyDetailCard text="Select a component configuration from the list on the left." />;
  }

  return (
    <Box>
      <Card elevation={1} square={false}>
        <List>
          <ListItem>
            <ListItemText primary="Printer" secondary={quote.printerId} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Quantity" secondary={numeral(quote.qty).format("0,000")} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Cost per Unit"
              secondary={`${numeral(quote.costPerUnit).format("0.00")} ${quote.currency}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Total"
              secondary={`${numeral(quote.costPerUnit * quote.qty).format("0,0.00")} ${quote.currency}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Terms" secondary={quote.terms} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Created" secondary={formatFullDate(quote.timestamp)} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Valid Until" secondary={formatFullDate(quote.expiry)} />
          </ListItem>
        </List>
      </Card>
    </Box>
  );
};

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("../fake-data").Quote} props.quote
 */
const ListEntry = ({ gameId, quote }) => {
  const { quoteId } = useParams();
  const navigate = useNavigate();

  const selected = quoteId === quote.id;

  const onClickHandler = () => {
    navigate(Path.game(gameId).component(quote.componentConfigurationId).quote(quote.id));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemText
        primary={`${numeral(quote.qty).format("0,000")} units @ ${numeral(quote.costPerUnit).format("0.00")} ${
          quote.currency
        } (${quote.terms})`}
        secondary={`Valid until: ${formatFullDate(quote.expiry)}`}
      />
    </ListItemButton>
  );
};

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("../fake-data").Quote[]} props.quotes
 * @param {import("react-router-dom").To} props.back
 * @param {Function} [props.refetch]
 * @param {boolean} [props.isLoading]
 * @param {boolean} [props.isRefetching]
 */
const ListPage = ({ gameId, quotes = [], back, refetch, isLoading, isRefetching }) => {
  return (
    <ListView
      title="Configuration Quotes"
      Filters={null}
      Action={null}
      back={back}
      EmptyList={() => (
        <NoItemsFound>
          <Alert severity="info">
            Each quote represents an estimate to manufacture a component configuration at a specific quantity. A
            quantity will also contain terms e.g. FOB or EXW as well as be limited in time e.g. a quote is only valid
            for 30 days.
          </Alert>
        </NoItemsFound>
      )}
      shown={quotes.length}
      refetch={refetch}
      isLoading={isLoading}
      isRefetching={isRefetching}
    >
      {quotes.map((quote) => (
        <ListEntry gameId={gameId} key={quote.id.toString()} quote={quote} />
      ))}
    </ListView>
  );
};

export const ListAndDetail = () => {
  const gameId = useGameIdFromRoute();
  const { configurationId } = useParams();
  const { data: quotes = [], refetch } = useComponentQuotes(configurationId);

  const SelectedSellsheet = () => <ComponentConfigurationQuoteItem />;

  const ListView = () => (
    <ListPage
      gameId={gameId}
      quotes={quotes}
      back={Path.game(gameId).component(configurationId).link()}
      refetch={refetch}
    />
  );

  return <ListDetail ListView={ListView} DetailView={SelectedSellsheet} />;
};
